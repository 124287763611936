export const EDoctorRequestPrivacy = Object.freeze({
  private: 'doctor_private_request',
  other: 'other_doctor_private_request',
  shared: 'shared_request_pool',
});

export const SYSTEM_STATUSES = Object.freeze({
  NONE: 0,
  COMPLETED_FOR_DIAGNOSE: 1,
  IN_PROCESS: 2,
  DIAGNOSED: 3,
  VIEWED_BY_PATIENT: 4,
  EDITING: 22,
  DIAGNOSED_NOTIFIED: 33,
  PENDING: 9,
  REQUEST_ADDITIONAL_DATA: 46,
  PHYSICIAN_ANSWER: 90,
  VISIT_CLINIC: 99,
});

export const getStatusByNumber = (num) => {
  return Object.keys(SYSTEM_STATUSES).find(key => SYSTEM_STATUSES[key] === num);
}

export const AVAILABLE_VIEW_MODE = Object.freeze({
  AWAITING: 'AWAITING',
  DIAGNOSED: 'DIAGNOSED',
});

export const ALL_SYSTEM_STATUSES_ARRAY = [
  SYSTEM_STATUSES.NONE,
  SYSTEM_STATUSES.COMPLETED_FOR_DIAGNOSE,
  SYSTEM_STATUSES.IN_PROCESS,
  SYSTEM_STATUSES.EDITING,
  SYSTEM_STATUSES.DIAGNOSED,
  SYSTEM_STATUSES.DIAGNOSED_NOTIFIED,
  SYSTEM_STATUSES.VIEWED_BY_PATIENT,
  SYSTEM_STATUSES.PENDING,
  SYSTEM_STATUSES.REQUEST_ADDITIONAL_DATA,
  SYSTEM_STATUSES.PHYSICIAN_ANSWER,
  SYSTEM_STATUSES.VISIT_CLINIC,
];

/**
 * This is only for logic of checkDoctorRequestPrivacy status 1 is just generated
 */
// const PROCESSING_STATUSES = { 1: true, 2: true };
/**
 * This is only for logic of checkDoctorRequestPrivacy status 4 is reviewed by customer
 */
// const DIAGNOSED_STATUSES = { 3: true, 4: true };
export const isMail = (val) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(val);
// export const isMail = (val) => /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(val);

export const checkDoctorRequestPrivacy = (status, vendorId, user, vendor, private_pool, isOnlyDiagnosed) => {
  if (vendor === 'maccabi') {
    const statusArray = isOnlyDiagnosed ? [SYSTEM_STATUSES.DIAGNOSED, SYSTEM_STATUSES.DIAGNOSED_NOTIFIED] : ALL_SYSTEM_STATUSES_ARRAY;
    try {
      const foundStatus =  statusArray.indexOf(status);
      const isInStatusRange = foundStatus !== -1;
      if (isInStatusRange) {
        if (private_pool === user || (isMail(vendorId) && vendorId === user)) {
          return EDoctorRequestPrivacy.private;
        } else if ((isMail(private_pool) && private_pool !== user) || (isMail(vendorId) && vendorId !== user)) {
          return EDoctorRequestPrivacy.other;
        } else if (status !== 0) {
          return EDoctorRequestPrivacy.shared;
        }
        throw new Error(`Helper checkDoctorRequestPrivacy received status = 0.`);
      } else {
        return EDoctorRequestPrivacy.other;
      }
    } catch (e) {
      console.error(e)
    }
  } else {
    return EDoctorRequestPrivacy.shared;
  }
}

export const VENDORS_TYPES = Object.freeze({ yeledoctor: 'yeledoctor', maccabi: 'maccabi' });
