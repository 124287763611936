import { NOTIFICATION_ACTIONS } from "../actions/notification.actions";
import { CASES_BATCH_ACTIONS_MAP } from '../actions/cases.batch.actions';


const initialNotificationState = {
    isOpen: false, // flag the dialog window is opened
    notifications: [],
    isHistoryBlocked: false,
    historyMessage: '',
    historyTitle: '',
    redirectTo: null,
    onClickBlockHistoryDialog: null,
    isToastOpen: false,
    toastStatus: 'error',
    toastMessage: '',
    isPatientNotification: false,
    isPhysicianNonActive: false,
    historySuccessCallback: null,
}

const notificationReducer = (state = initialNotificationState, action) => {
    switch(action.type) {
        case NOTIFICATION_ACTIONS.blockPhysicianByManagerInformed:
            return {
                ...state,
                isPhysicianNonActive: false,
            }
        case NOTIFICATION_ACTIONS.blockPhysicianByManager:
            return {
                ...state,
                isPhysicianNonActive: true,
            }
        case NOTIFICATION_ACTIONS.releaseTempBlockON:
            console.log('NOTIFICATION_ACTIONS.releaseTempBlockON')
            return {
                ...state,
                isHistoryBlocked: false,
            }
        case NOTIFICATION_ACTIONS.releaseTempBlockOFF:
            return {
                ...state,
                isHistoryBlocked: true,
            }
        case CASES_BATCH_ACTIONS_MAP.NOTIFY_PATIENT_ON_DIAGNOSED_CASES_START:
            return {
                ...state,
                isPatientNotification: true,
            }
        case CASES_BATCH_ACTIONS_MAP.NOTIFY_PATIENT_ON_DIAGNOSED_CASES_SUCCESS:
        case CASES_BATCH_ACTIONS_MAP.NOTIFY_PATIENT_ON_DIAGNOSED_CASES_FAIL:
            return {
                ...state,
                isPatientNotification: false,
            }
        case NOTIFICATION_ACTIONS.toastOpen:
            return {
                ...state,
                isToastOpen: true,
                toastStatus: action.payload.status,
                toastMessage: action.payload.message,
            }
        case NOTIFICATION_ACTIONS.toastClose:
            return {
                ...state,
                isToastOpen: initialNotificationState.isToastOpen,
                toastStatus: 'error',
                toastMessage: initialNotificationState.toastMessage,
            }
        case NOTIFICATION_ACTIONS.blockHistory:
            return {
                ...state,
                isHistoryBlocked: true,
                historyMessage: action.payload.message,
                historyTitle: action.payload.title,
                redirectTo: action.payload.redirectTo,
                historySuccessCallback: action.payload.historySuccessCallback,
                onClickBlockHistoryDialog: action.payload.onClickBlockHistoryDialog,
            } 
        case NOTIFICATION_ACTIONS.unblockHistory:
            return {
                ...state,
                isHistoryBlocked: false,
                historyMessage: (action.payload && action.payload.message) ? action.payload.message : '',
                historyTitle: (action.payload && action.payload.title) ? action.payload.title : '',
                redirectTo: null,
            }
        case NOTIFICATION_ACTIONS.setBlockHistoryCallback:
            return {
                ...state,
                historySuccessCallback: action.payload.historySuccessCallback,
            } 
        case NOTIFICATION_ACTIONS.initNotificationsSuccess:
            return {
                ...state,
                notifications: action.payload.notifications,
            }
        case NOTIFICATION_ACTIONS.triggerNotificationSuccess:
            return {
                ...state,
                isOpen: state.isOpen ? false : true,
            }
        case NOTIFICATION_ACTIONS.addNotificationSuccess:
            return {
                ...state,
                notifications: action.payload.notifications,
            }
        default: return state;
    }
}

export default notificationReducer;