import React, { memo, useRef } from 'react';
import { useStyles } from './styles';
import myDermaLogo from '../../../assets/svg/myDermaLogo.svg';
import myDermaLogoBig from '../../../assets/svg/myDermaLogoBig.svg';
import skinTalksLogo from '../../../assets/svg/skinTalksLogo.svg';
import { PROJECT_NAMES } from '../../../helpers/constants';


export const HeaderUserCommon = memo(() => {
  const s = useStyles();
  const {current: src} = useRef(process.env.REACT_APP_PROJECT_NAME === PROJECT_NAMES.myderma ? myDermaLogo : skinTalksLogo);

  const srcBig = process.env.REACT_APP_PROJECT_NAME === 'myderma' ? myDermaLogoBig : skinTalksLogo; // TODO

  return (
    <div className={s.headerUser}>
      <img className={s.logo} src={src} alt="logo" />
      {process.env.REACT_APP_PROJECT_NAME === 'myderma' && <img src={srcBig} alt="logoBig" />}
    </div>
  );
});