export const AUDIT_ANNOTATIONS_ACTION_MAP = Object.freeze({
    getAuditAnnotationsByUuidRequest: 'GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST',
    getAuditAnnotationsByUuidSuccess: 'GET_AUDIT_ANNOTATIONS_BY_UUID_SUCCESS',
    getAuditAnnotationsByUuidFail: 'GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST_FAIL',
    createAuditAnnotationRequest: 'CREATE_AUDIT_ANNOTATION_REQUEST',
    createAuditAnnotationSuccess: 'CREATE_AUDIT_ANNOTATION_SUCCESS',
    createAuditAnnotationFail: 'CREATE_AUDIT_ANNOTATION_FAIL',
});

export const getAuditAnnotationsByUuidRequest = (uuid) => {
    return {
        type: AUDIT_ANNOTATIONS_ACTION_MAP.getAuditAnnotationsByUuidRequest,
        payload: { uuid }
    };
}
export const getAuditAnnotationsByUuidSuccess = (annotations) => {
    return {
        type: AUDIT_ANNOTATIONS_ACTION_MAP.getAuditAnnotationsByUuidSuccess,
        payload: { annotations }
    };
}
export const getAuditAnnotationsByUuidFail = () => {
    return {
        type: AUDIT_ANNOTATIONS_ACTION_MAP.getAuditAnnotationsByUuidFail,
    };
}

export const createAuditAnnotationRequest = ({uuid, body}) => {
    return {
        type: AUDIT_ANNOTATIONS_ACTION_MAP.createAuditAnnotationRequest,
        payload: { uuid, body }
    };
}
export const createAuditAnnotationSuccess = (annotation) => {
    return {
        type: AUDIT_ANNOTATIONS_ACTION_MAP.createAuditAnnotationSuccess,
        payload: { annotation }
    };
}
export const createAuditAnnotationFail = () => {
    return {
        type: AUDIT_ANNOTATIONS_ACTION_MAP.createAuditAnnotationFail,
    };
}
