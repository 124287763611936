import { TENANTS } from "../helpers/auth.helper";

export const getRegistrationValid = (state) => state.auth ? state.auth.isRegistrationValid : false;
export const getRegistrationValidationLoading = (state) => state.auth ? state.auth.isRegistrationValidationLoading : false;
export const getRegistrationPasswordLoading = (state) => state.auth ? state.auth.isRegistrationPasswordLoading : false;
export const getErrorValidation = (state) => state.auth ? state.auth.errorValidation : null;
export const getErrorPasswordAssign = (state) => state.auth ? state.auth.errorPasswordAssign : null;
export const getErrorCreatePassword = (state) => state.auth ? state.auth.errorCreatePassword : null;
export const isPasswordInvalidity = (state) => state.auth ? state.auth.invalidPassword : false;
export const getResetPasswordLoading = (state) => state.auth ? state.auth.isResetPasswordLoading : false;
export const getResetPasswordAction = (state) => state.auth ? state.auth.resetPasswordAction : null;
export const getResetPasswordError = (state) => state.auth ? state.auth.resetPasswordError : null;
export const getIDPService = (state) => state.auth ? state.auth.IDPService : null;
export const getAIService = (state) => state.auth ? state.auth.AIService : false;
export const getAnalyticsUrl = (state) => state.auth ? state.auth.analyticsUrl : '';
export const getImagesService = (state) => state.auth ? state.auth.ImagesService : 'gcp';
export const getTenant = (state) => state.auth ? state.auth.tenant : TENANTS.MACCABI;
export const getCSRFToken = (state) => state.auth ? state.auth.CSRFToken : '';
export const getImaegServiceUrl = (state) => state.auth ? state.auth.IMAGE_SERVICE : '';
