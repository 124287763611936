import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, Prompt, withRouter } from 'react-router-dom';
import './App.css';
import { connect } from 'react-redux';
import * as serverAuth from './services/auth.service';
import { language } from './selectors';
import 'moment/locale/he';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/styles';
import Viewer from 'react-viewer';
import { apiFullURL } from '../src/core/axios';
import { initNotifications } from './actions/notification.actions';
import {
  CASE_EDIT_PAGE_BLOCK_STATUS,
  initCasesBatchAfterReload,
  updateImageRequirementBlock,
} from './actions/cases.batch.actions';
import { Button, Typography, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { changeLng, EDoctorPermissions } from './reducers/settingReducer';
import ToastNotification from './components/shared/ToastNotification';
import { createMuiTheme } from '@material-ui/core/styles';
import asyncComponent from './hoc/asyncComponent';
import WebSocketService from './services/WebSocketService';
import LoadingBlurredScreen from './components/shared/LoadingBlurredScreen';
import LoginPage from './components/Login/Login';
import { AUTHENTICATED_RESPONSE } from './components/Login/constants';
import { resetAdminSettings, setAdminSettings } from './actions/admin.actions';
import BlockedPhysicianDialog from './dialogs/BlockedPhysicianDialog';
import { DEFAULT_VACATION } from './reducers/adminReducer';
import { getCurrentPrivatePool } from './selectors/patient.details.selector';
import { getIDPService } from './selectors/auth.selector';
import { getDir, messageParam, ROUTES, titleParam } from './helpers/route.helper';
import { getUrlParams } from './selectors/index';
import { generateUrlParams } from './sagas/helper';
import { updateAnalyticData } from './sagas/helper';
import { setCSRFToken } from './actions/auth.actions';
import 'slick-carousel/slick/slick.css';

const AsyncPatientList = asyncComponent(() => {
  return import('./components/PatientList/PatientList');
});
const AsyncCaseEditPage = asyncComponent(() => {
  return import('./components/CaseEdit');
});
const AsyncAdminPage = asyncComponent(() => {
  return import('./components/Admin');
});
const AsyncSessionBlockPage = asyncComponent(() => {
  return import('./components/SessionBlocked');
});
const AsyncRegistrationPage = asyncComponent(() => {
  return import('./components/Registration');
});
const AsyncForgotPasswordPage = asyncComponent(() => {
  return import('./components/ForgotPassword');
});
const AsyncCreatePasswordPage = asyncComponent(() => {
  return import('./components/CreatePassword');
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0392B5',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Heebo', 'Arial', 'sans-serif'].join(','),
  },
  direction: 'rtl',
});

const pathArr = [
  '/',
  ROUTES.LOGIN,
  '/create-password',
  ROUTES.PATIENTS_LIST,
  '/user-management' /* , "/user-management-validate" */,
];

const getPathName = (pathName) => {
  let test = '/patients';
  if (pathName === '/user-management') test = '/user-management';
  return test;
};

const getComponent = (props) => {
  if (props.user) {
    if (props.isValidationCode) return <Redirect to={{ pathname: '/create-password' }} />;
    else if (props.isValidated) return <Redirect to={{ pathname: getPathName(window.location.pathname) }} />;
    else return <Redirect to={{ pathname: '/user-management-validate' }} />;
  } else return <Redirect to={{ pathname: '/login' }} />;
};

export const isSkintalks = process.env.REACT_APP_PROJECT_NAME === 'skintalks';
class App extends Component {
  timeOutInstanceHelper = null;
  timeOutInstanceHelper2 = null;
  clickEventList = null;
  clickEventList2 = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isHistoryBlock: props.isHistoryBlocked,
      onClickBlockHistoryDialog: props.onClickBlockHistoryDialog,
      historyCallback: null,
      isUseRedirectRoute: false,
    };

    if (!this.props.isScreenBlocked) {
      this.init();
    }
  }

  componentWillUnmount() {
    if (this.timeOutInstanceHelper) {
      clearTimeout(this.timeOutInstanceHelper);
    }
  }

  componentDidUpdate(prevProps) {
    if (isSkintalks && localStorage.getItem('lang') !== prevProps?.i18n?.language) {
      const iframe = document.getElementById('jsd-widget');
      if (iframe && (iframe.contentDocument || iframe.contentWindow.document)) {
        iframe.contentDocument.getElementsByTagName('p')[0].innerText = this.props.t('jira.contactUs');
      }
    }
  }

  jiraSupport() {
    const jiraHelpdesk = (callback) => {
      const jhdScript = document.createElement('script');
      jhdScript.type = 'text/javascript';
      jhdScript.setAttribute('data-jsd-embedded', null);
      jhdScript.setAttribute('data-key', 'f48cef0d-cb68-4657-a6ab-66de18d03fa8');
      jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
      jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
      if (jhdScript.readyState) {
        // old IE support
        jhdScript.onreadystatechange = function () {
          if (jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete') {
            jhdScript.onreadystatechange = null;
            callback();
          }
        };
      } else {
        //modern browsers
        jhdScript.onload = function () {
          callback();
        };
      }
      document.getElementsByTagName('head')[0].appendChild(jhdScript);
    };

    jiraHelpdesk(function () {
      const DOMContentLoaded_event = document.createEvent('Event');
      DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
      window.document.dispatchEvent(DOMContentLoaded_event);
    });
  }

  async connectSocket() {
    try {
      const { dispatch, history, user } = this.props;
      if (!WebSocketService.socket) {
        WebSocketService.socket = apiFullURL;
      }
      WebSocketService.dispatch = dispatch;
      WebSocketService.history = history;
      WebSocketService.fillPropertiesAfterConnection();
      WebSocketService.email = user;
      if (window.location.search === '?block=refresh') {
        await serverAuth.refreshActiveSocket();
      }
    } catch (e) {
      console.log('Error with socket refresh...');
    }
  }

  handleIframeHtml(doctor) {
    try {
      if (this.timeOutInstanceHelper) {
        clearTimeout(this.timeOutInstanceHelper);
        clearTimeout(this.timeOutInstanceHelper2);
      }
      const iframe = document.getElementById('jsd-widget');
      if (!iframe) {
        this.timeOutInstanceHelper = setTimeout(() => this.handleIframeHtml(doctor), 1000);
        return;
      }
      const iframeDoc = iframe && (iframe.contentDocument || iframe.contentWindow.document);
      iframe.style.bottom = '65px';
      const handleIFrame = () => {
        if (iframeDoc) {
          const helperButton = iframe.contentWindow.document.getElementById('help-button');
          const el = () => {
            try {
              this.timeOutInstanceHelper2 = setTimeout(() => {
                const iframe2 = document.getElementById('jsd-widget');
                const close = iframe2.contentWindow.document.querySelector('.header-close-icon-container'); //close-icon
                const unputEmail = iframe2.contentWindow.document.querySelector(
                  '#react-root div .form-container form.help-form div.ak-field-group #email'
                );
                if (unputEmail) {
                  unputEmail.value = doctor;
                }
                if (close) {
                  const closeEL = () => {
                    this.props.history.go(0);
                  };
                  close.removeEventListener('click', closeEL);
                  this.clickEventList2 = close.addEventListener('click', closeEL);
                }
              }, 10);
            } catch (e) {}
          };
          if (helperButton && helperButton.addEventListener) {
            helperButton.removeEventListener('click', el);
            this.clickEventList = helperButton.addEventListener('click', el);
          }
        }
      };
      if (iframeDoc) {
        handleIFrame(doctor);
      }
    } catch (e) {
      //error
    }
  }

  handleJiraAfterLogin(doctor) {
    this.jiraSupport();
    this.handleIframeHtml(doctor);
  }

  init = async () => {
    const { dispatch, t } = this.props;

    console.log('API: ', process.env.REACT_APP_API);

    this.props.dispatch(initNotifications());
    if (!this.props.isDiagnosedOnly) {
      this.props.dispatch(initCasesBatchAfterReload());
    }
    const elem = document.getElementById('lang_direction');
    const dir = elem.getAttribute('dir');
    // const languageBasedDir = this.props.language.lang === 'he' ? 'rtl' : 'ltr';
    const languageBasedDir = localStorage.getItem('lang') === 'he' ? 'rtl' : 'ltr';

    // if (this.props.user && languageBasedDir !== dir) {
    //     const bodyElem = document.getElementById('lang_direction');
    //     bodyElem.setAttribute('dir', languageBasedDir)
    // }

    if (languageBasedDir !== dir) {
      const bodyElem = document.getElementById('lang_direction');
      bodyElem.setAttribute('dir', languageBasedDir);
    }

    let userData;

    const csrfToken = await serverAuth.getCSRFToken();
    serverAuth.setAxiosCSRFToken(csrfToken?.csrfToken);
    dispatch(setCSRFToken(csrfToken?.csrfToken));

    const envSettings = await serverAuth.getEnvSettings();

    if (!this.props.user && !this.props.isScreenBlocked) {
      if (this.props.loginProcess) {
        return;
      }

      dispatch({ type: 'SET_ENV_SETTINGS', payload: envSettings });
      userData = await serverAuth.isAuth(this.props.user);

      if (userData?.status === AUTHENTICATED_RESPONSE) {
        if (userData.permission === EDoctorPermissions.superuser) {
          this.jiraSupport();
          this.handleIframeHtml(userData.doctor_email);
        }

        if (userData.admin_settings && !userData.admin_settings.physicianPrefix) {
          userData.admin_settings.physicianPrefix = t('managementPage.physicianPrefix1');
        }

        dispatch({
          type: 'SET_USER',
          payload: {
            user: userData.doctor_email,
            vendor: userData.vendor_id,
            permission: userData.permission,
            admin_settings: userData.admin_settings,
          },
        });
        let vacation = DEFAULT_VACATION;
        let isVacationSet = false;
        if (userData.admin_settings && userData.admin_settings.vacation) {
          const defDate = new Date().toISOString();
          const { start_vacation = defDate, end_vacation = defDate } = userData.admin_settings.vacation || {};
          isVacationSet = true;
          vacation = [
            {
              startDate: new Date(start_vacation),
              endtDate: new Date(end_vacation),
            },
          ];
        }
        const employees = userData.roleMap ? Object.keys(userData.roleMap) : [];
        dispatch(
          setAdminSettings({
            adminSettings: userData.admin_settings,
            admin: userData.doctor_email,
            user: userData.doctor_email,
            is_active: userData.is_active,
            employees: employees,
            permission: userData.permission,
            vacation,
            isVacationSet,
            roleMap: userData.roleMap,
          })
        );

        const language = userData.settings?.language ?? 'en';
        this.props.setUserSettings(userData.settings);
        await changeLng(language);
        const bodyElem = document.getElementById('lang_direction');
        bodyElem.setAttribute('dir', getDir(language));
        this.props.setUserRoles(employees);
        this.props.dispatch({ type: 'LOAD_TEMPLATES', payload: userData.doctor_email });
        // await this.connectSocket();
        this.props.setIsValidationCode(userData.is_validation_code);
        this.props.setIsValidated(userData.is_validated);
      } else {
        dispatch({ type: 'SET_USER', payload: { user: '', vendor: '', permission: '' } });
        dispatch(resetAdminSettings());
        this.props.setUserSettings(null);
        this.props.setUserRoles([]);
      }
      this.setState({ loading: false });
    } else {
      dispatch({ type: 'SET_USER', payload: { user: '', vendor: '', permission: '' } });
      dispatch(resetAdminSettings());
    }
  };

  fnSetState = () => this.setState({ isUseRedirectRoute: true });
  render() {
    const { language, lightboxShow, images, currentImageIndex, dispatch } = this.props;
    const urlParams = getUrlParams();
    if (this.state.loading) {
      return null;
    }
    const themeDir = { ...theme, direction: language.dir };
    let viewerSource = [];
    if (lightboxShow && images) {
      viewerSource = images.map((d) => ({ src: d.url, alt: d.title }));
    }

    return (
      <RTL>
        <ThemeProvider theme={themeDir}>
          <Router
            getUserConfirmation={async (message, callback) => {
              if (this.props.isHistoryBlocked && this.props.privatePool === this.props.user) {
                this.setState((prev) => ({
                  ...prev,
                  isHistoryBlock: true,
                  historyCallback: callback,
                }));
              } else {
                callback(false);
              }
            }}
          >
            <div className={`wrapper`}>
              {Array.isArray(viewerSource) && viewerSource.length ? (
                <div
                  style={{
                    textAlign: 'left',
                    direction: 'ltr',
                  }}
                >
                  <Viewer
                    visible={lightboxShow}
                    zoomSpeed="0.1"
                    minScale={1}
                    defaultScale={3}
                    rotatable={true}
                    showTotal={true}
                    onChange={(image, index) => {
                      if (index + 1 === images.length) {
                        dispatch(updateImageRequirementBlock(CASE_EDIT_PAGE_BLOCK_STATUS.RELEASE_IMAGE_CHECK_BLOCK));
                      }
                    }}
                    activeIndex={currentImageIndex}
                    onClose={() => dispatch({ type: 'CLOSE_LIGHTBOX' })}
                    images={viewerSource}
                    style={{
                      textAlign: 'left',
                      direction: 'ltr',
                    }}
                  />
                </div>
              ) : null}
              <div className="MainContent">
                <Switch>
                  {!this.state.isUseRedirectRoute && (
                    <RedirectRoute path={pathArr} props={{ ...this.props, setState: this.fnSetState }} exact />
                  )}
                  <Route path={ROUTES.LOGIN}>
                    {this.props.user ? (
                      getComponent(this.props)
                    ) : (
                      <LoginPage
                        handleJiraAfterLogin={(email) => {
                          const newFn = this.handleJiraAfterLogin.bind(this);
                          newFn(email);
                        }}
                      />
                    )}
                  </Route>
                  <Route path={'/blocked'} render={(props) => <AsyncSessionBlockPage {...props} />} />
                  <ProtectRoute
                    path={'/create-password'}
                    authUser={this.props.user}
                    component={AsyncCreatePasswordPage}
                    exact
                  />
                  <ProtectRoute
                    path={ROUTES.PATIENTS_LIST}
                    authUser={this.props.user}
                    component={AsyncPatientList}
                    exact
                  />
                  <Route path={'/register'} component={AsyncRegistrationPage} />
                  <Route path={'/forgot-password'} component={AsyncForgotPasswordPage} />
                  <ProtectRoute
                    path={ROUTES.PATIENTS_CASE_EDIT}
                    authUser={this.props.user}
                    component={AsyncCaseEditPage}
                    exact
                  />
                  <ProtectRoute
                    path="/user-management-validate/"
                    authUser={this.props.user}
                    component={AsyncAdminPage}
                    exact
                  />
                  <ProtectRoute path="/user-management/" authUser={this.props.user} component={AsyncAdminPage} exact />
                  <Route component={NoMatch} />
                </Switch>
              </div>
              <Dialog open={this.props.isPhysicianNonActive && !this.props.isHistoryBlocked}>
                <BlockedPhysicianDialog />
              </Dialog>

              <Dialog open={!!(this.state.isHistoryBlock || !!urlParams.message || !!urlParams.title)}>
                <DialogTitle>{this.props.historyTitle || decodeURIComponent(urlParams.title)}</DialogTitle>
                <DialogContent>
                  <Typography variant="h6">
                    {this.props.historyMessage || decodeURIComponent(urlParams.message)}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      if (
                        this.props.onClickBlockHistoryDialog &&
                        typeof this.props.onClickBlockHistoryDialog == 'function'
                      ) {
                        this.props.onClickBlockHistoryDialog();
                      } else {
                        if (this.state.historyCallback) {
                          this.state.historyCallback(false);
                        }
                      }
                      const urlParams = getUrlParams();
                      let newUrl = window.location.pathname;
                      if (urlParams) {
                        for (const keyParam of Object.keys(urlParams)) {
                          if (keyParam === messageParam || keyParam === titleParam) {
                            continue;
                          }
                          if (newUrl === window.location.pathname) {
                            newUrl += `?${keyParam}=${urlParams[keyParam]}`;
                          } else {
                            newUrl += `&${keyParam}=${urlParams[keyParam]}`;
                          }
                        }
                      }

                      this.props.history.push(generateUrlParams(newUrl));
                      this.setState({ isHistoryBlock: false });
                    }}
                  >
                    {this.props.redirectTo
                      ? this.props.t('modalAnnotation.btnCancel')
                      : this.props.t('modalAnnotation.btnOk')}
                  </Button>
                  {this.props.redirectTo ? (
                    <Button
                      onClick={() => {
                        if (typeof this.props.historySuccessCallback == 'function') {
                          this.props.historySuccessCallback();
                        }
                        if (this.state.historyCallback) {
                          this.state.historyCallback(true);
                        }
                        this.setState({ isHistoryBlock: false });
                      }}
                    >
                      {this.props.t('modalAnnotation.alertFooterSaveBtn')}
                    </Button>
                  ) : null}
                </DialogActions>
              </Dialog>

              <LoadingBlurredScreen />
              <ToastNotification />
              <Prompt
                when={this.props.isHistoryBlocked}
                message={this.props.router.location ? this.props.router.location.pathname : 'save'}
              />
            </div>
          </Router>
        </ThemeProvider>
      </RTL>
    );
  }
}

const NoMatch = ({ location }) => (
  <div className="notFound">
    <h3>
      No results found for
      <code> {location.pathname}</code>
    </h3>
  </div>
);

function RTL(props) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
}

const RedirectRoute = ({ props }) => {
  const { setState } = props;
  React.useEffect(() => {
    setState();
  });
  return getComponent(props);
};

const ProtectRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: generateUrlParams('/login'), state: { from: props.location } }} />
        )
      }
    />
  );
};

// const AdminRoute = ({component: Component, authUser, isAdmin, ...rest}) => (
//     <Route
//         {...rest}
//         render={props => (authUser && isAdmin
//             ? <Component {...props} />
//             : <Redirect to={{pathname: generateUrlParams('/')}}/>)
//         }
//     />
// );

const mapStateToProps = (state) => {
  if (state.admin.admin || state.settings.user) {
    updateAnalyticData({ physician: state.admin.admin || state.settings.user });
  }

  return {
    router: state.router,
    language: language(state),
    user: state.settings.user,
    isHistoryBlocked: state.notifications.isHistoryBlocked,
    historyMessage: state.notifications.historyMessage,
    redirectTo: state.notifications.redirectTo,
    onClickBlockHistoryDialog: state.notifications.onClickBlockHistoryDialog,
    isPhysicianNonActive: state.notifications.isPhysicianNonActive,
    historyTitle: state.notifications.historyTitle,
    historySuccessCallback: state.notifications.historySuccessCallback,
    userSettings: state.settings.userSettings,
    loginProcess: state.settings.loginProcess,
    lightboxShow: state.settings.lightboxShow,
    images: state.settings.images,
    currentImageIndex: state.settings.currentIndex,
    isDiagnosedOnly: state.patientDetails.caseAvailabilityProps.mode,
    isScreenBlocked: state.settings.isScreenBlocked,
    privatePool: getCurrentPrivatePool(state),
    IDPService: getIDPService(state),
    isValidated: state.settings.isValidated,
    isValidationCode: state.settings.isValidationCode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setUserSettings: (settings) => dispatch({ type: 'SET_USER_SETTINGS', payload: settings }),
  setUserRoles: (roles) => dispatch({ type: 'SET_USER_ROLES', payload: roles }),
  setIsValidationCode: (isValidationCode) => dispatch({ type: 'SET_ISVALIDATIONCODE', payload: isValidationCode }),
  setIsValidated: (isValidated) => dispatch({ type: 'SET_ISVALIDATED', payload: isValidated }),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(App)));
