import * as R from 'ramda'
import store from '../store'

export const GENDER = Object.freeze({
  MALE: 'male',
  FEMALE: 'female',
})

export const languagesObject = Object.freeze({
  EN: 'en',
  HE: 'he',
  RU: 'ru',
  FR: 'fr',
})

export const languages = [
  {lang: languagesObject.HE, dir: 'rtl'},
  {lang: languagesObject.EN, dir: 'ltr'},
  {lang: languagesObject.RU, dir: 'ltr'},
  {lang: languagesObject.FR, dir: 'ltr'}
];
export const language = (state = store.getState()) => {
  const lang = R.pathOr('he', ['userSettings', 'language'])(state.settings)
  return languages.find((item) => item.lang === lang)
};

export const getUrlParams = () => {
  return store.getState().router.location.query;
}

export const isRoleMaccabi = () => {
  const state = store.getState()
  const {vendor} = state.settings
  return vendor === 'maccabi' ? true : false
};

const prefixes = Object.freeze({
  en: { Dr: "Dr.", Prof: "Prof.", Mr: "Mr." },
  he: { Dr: "דר.", Prof: "פרופ.", Mr: "מר." },
  ru: { Dr: "Доктор", Prof: "Профессор", Mr: "Господин" },
  fr: { Dr: "Dr.", Prof: "Prof.", Mr: "Mr." }
});

export const getPrefixForLang = (pfx, lang) => {
  if (!pfx || !lang) return "";
  const translations = Object.values(prefixes);

  const key = translations.reduce((acc, translation) => {
    const pair = Object.entries(translation).find((pair) => {
      return pair.includes(pfx);
    });
    if (pair) {
      const [key] = pair;
      return key;
    }
    return acc;
  }, "");

  return prefixes[lang][key];
};