import socketIOClient from 'socket.io-client';
import {subscribeNACAnalyticSuccess} from '../actions/analytic.actions';
import {blockPhysicianDialogOpen} from '../actions/notification.actions';
import {socketConnectionSuccess, socketBlock} from '../actions/settings.actions';

export const WEB_SOCKET_MESSAGES = Object.freeze({
    messageToBackofficeServer: 'messageToBackofficeServer',
    messageToBackofficeClient: 'messageToBackofficeClient',
    messageToBackofficeServerBlockPhysicians: 'messageToBackofficeServerBlockPhysicians',
    updateSignedNacAnalytic: 'UPDATE_SIGNED_NAC_ANALYTIC_CLIENT',
    physicianBlockedByManager: 'physicianBlockedByManager',
    appConnect: 'connect',
})

class WebSocketService {

    _socket;
    _dispatch;
    _email;
    _history;

    set socket(url) {
        if (window.location.pathname !== '/blocked' || !window.CefActive) {
            try {
                if (!this._socket && !this._email) {
                    const prodSocketConfig = {
                        transports: ['websocket'],
                    };
                    this._socket = socketIOClient(url, prodSocketConfig);
                }
            } catch (e) {
                console.log(`cannot connect WebSocket to ${url}`);
            }
        }
    }

    set dispatch(d) {
        if (d) {
            this._dispatch = d;
        }
    }

    set history(h) {
        if (h && h.push && !this._history) {
            this._history = h;
        }
    }

    get history() {
        return this._history;
    }

    set email(e) {
        if (!this._email) {
            this._email = e;
        }
        if (this._socket) {
            this._socket.on(`${WEB_SOCKET_MESSAGES.messageToBackofficeServerBlockPhysicians}:${this._email}`,
                (listBlockedConnections) => {
                    this._dispatch(socketBlock(this._socket.id, listBlockedConnections, this._history));
                });
            this._socket.on(
                `${WEB_SOCKET_MESSAGES.physicianBlockedByManager}:${this._email}`,
                () => this._dispatch(blockPhysicianDialogOpen())
            )
            this._socket.on(
                `${WEB_SOCKET_MESSAGES.updateSignedNacAnalytic}:${this._email}`,
                (analytic) => {
                    this._dispatch(subscribeNACAnalyticSuccess(analytic));
                }
            );
        }
    }

    get socket() {
        return this._socket;
    }

    fillPropertiesAfterConnection() {
        if (!this._email && this._socket) {
            this._socket.emit(WEB_SOCKET_MESSAGES.messageToBackofficeServer);
            this._socket.on(WEB_SOCKET_MESSAGES.messageToBackofficeClient, (msg) => {
                if (this._dispatch && this._socket.id) {
                    this._dispatch(socketConnectionSuccess(this._socket.id));
                } else {
                }
            });
        }
    }

}

export default new WebSocketService();