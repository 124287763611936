import { CASE_EDIT_PAGE_BLOCK_STATUS } from "../actions/cases.batch.actions";
import { AI_MODELS } from "../helpers/ai-models";
import { PRIMARY_PROPERTY_NAME, EXCLUDED_PROPERTY_NAME } from '../sagas/helper';


export const getCreateTreatmentPlanLoading = state => state.patientDetails.createTreatmentPlanLoading;
export const getCreateTreatmentPlanSuccess = state => state.patientDetails.createTreatmentPlanSuccess;
export const getCreateTreatmentPlanError = state => state.patientDetails.createTreatmentPlanError;
export const getUserIdentifier = state => state.patientDetails ? state.patientDetails.user_identifier : '';
export const getIsAnnotationsFetchLoading = state => state.patientDetails ? state.patientDetails.isAnnotationsFetchLoading : false;
export const getAwaitingCasesList = state => state.patientDetails ? state.patientDetails.currentCasesArray : [];
export const getAwaitingNewCasesList = state => state.patientDetails ? state.patientDetails.awaitingNewCasesArray : [];
export const getCaseAvailabilityProps = state => state.patientDetails.caseAvailabilityProps;
export const getCurrentIndex = state => state.patientDetails.currentCaseIndex;
export const getCurrentStatus = state => state.patientDetails.status;
export const getCurrentVendorId = state => state.patientDetails.vendor_id;
export const getCurrentPrivatePool = state => state.patientDetails.private_pool;
export const getPatientAnnotations = (state) => state.patientDetails.annotations;
export const getPatientPhysicianCode = (state) => state.patientDetails.physician_code;
export const getPatientVectibix = (state) => state.patientDetails.vectibix;

export const getPatientUnresolvedCaseTreatmentEffect = (state) => {
  if (!state.patientDetails?.annotations?.length) return;

  const lastIndex = state.patientDetails?.annotations?.length - 1;

  let descriptionsFromAllTreatmentReports = [];
  const annotation = state.patientDetails?.annotations?.find((annotation, index) => {
    if (annotation?.treatment_effect?.description) {
      const { treatment_effect: { description } } = annotation;
      descriptionsFromAllTreatmentReports.push(description)
    };
    return (annotation.uuid === state.patientDetails?.case_uuid) &&
      !!annotation.treatment_effect &&
      (state.patientDetails.status === 2) &&
      lastIndex === index;
  });
  return (annotation?.uuid && {
    treatment_efficiency: {
      ...annotation.treatment_effect,
      description : descriptionsFromAllTreatmentReports.join(', ')
    },
    uuid: annotation?.uuid,
  });
};
export const isCanceledNotification = (state) => state.patientDetails.cancelationNotification;
export const getAnnotationState = (state) => state.patientDetails.annotationState;
export const getCaseUUIDSelector = (state) => state.patientDetails.case_uuid;
export const getCaseIdSelector = (state) => state.patientDetails.case_id;
export const getNextTabIndex = (state) => state.patientDetails ? state.patientDetails.currentTab : 'tab-0';
export const getPatientTranslated = (state) => state.patientDetails.patientDetailsTranslated;
export const getAllPatientDetails = (state) => state.patientDetails;
export const getPatientCreationDate = (state) => state.patientDetails.created_at;
export const getPatientLoadData = (state) => state.patientDetails ? state.patientDetails.load_data : false;
export const getPendingDescription = (state) => state.patientDetails ? state.patientDetails.descriptions : null;
export const getPendingLoading = (state) => state.patientDetails ? state.patientDetails.isPendingLoading : false;
export const getPendingUnblockLoading = (state) => state.patientDetails ? state.patientDetails.isUnblockPendingLoading : false;
export const getImageRequirementStatus = state => state.patientDetails ? state.patientDetails.imageRequirementStatus : CASE_EDIT_PAGE_BLOCK_STATUS.RELEASE_IMAGE_CHECK_BLOCK
export const getIsImagesLoading = (state) => state.patientDetails ? state.patientDetails.isImagesLoading : true;
export const getImagesScrollPosition = (state) => state.patientDetails ? state.patientDetails.imagesScrollPosition : 0;
export const getScrollContainerHeight = (state) => state.patientDetails ? state.patientDetails.scrollContainerHeight : 0;
export const getUserHash = (state) => state.patientDetails ? state.patientDetails.userHash : '';
export const getCaseComment = (state) => state.patientDetails ? state.patientDetails.caseComment : '';
export const getIsLoadingSaveCase = (state) => state.patientDetails ? state.patientDetails.isLoadingSaveCase : false;
export const getIsNotifying = (state) => state.patientDetails ? state.patientDetails.isNotifyingCase : false;
export const getPatientInfoForTreatmentPlan = (state) => ({
  id: state.patientDetails?.user_id || state.patientDetails?.user_identifier,
  name: state.patientDetails?.patient_name,
  age: state.patientDetails?.patientDetails?.age,
})
export const getPatientRequest = (state) => ({
  durationFromBirth: state.patientDetails?.patientDetails['duration.from_birth'],
  itch: state.patientDetails?.patientDetails?.itch?.itch_assessment,
  pain: state.patientDetails?.patientDetails?.pain?.pain_assessment,
  QAIsChangeDensityHairs: state.patientDetails?.patientDetails?.QA_Is_Change_Density_Hairs,
  QAIsChangeThicknessHairs: state.patientDetails?.patientDetails?.QA_Is_Change_Thickness_Hairs,
  QAIsDensity: state.patientDetails?.patientDetails?.QA_Is_Density || null,
  QAIsThickness: state.patientDetails?.patientDetails?.QA_Is_Thickness || null,
  // texture: state.patientDetails?.patientDetails?.texture?.length ? state.patientDetails?.patientDetails?.texture : null,
})
export const getCasesForStepper = state => {
  const currentCasesArray = state.patientDetails?.currentCasesArray;
  return currentCasesArray?.length ? currentCasesArray.map((item) => ({
    name: item.caseId,
    status: item.status,
  })) : [];
}
export const getConfidence = (state) => state.patientDetails?.mlDiagnosisProbabilities?.confidence || 0;

export const getPrimaryProbabilities = (state) => {
  if (state.patientDetails && state.patientDetails.mlDiagnosisProbabilities) {
    return state.patientDetails.mlDiagnosisProbabilities[PRIMARY_PROPERTY_NAME][state?.settings?.aiModel || AI_MODELS.DEFAULT] || []
  } else {
    return [];
  }
}
export const getExcludedProbabilities = (state) => {
  if (state.patientDetails && state.patientDetails.mlDiagnosisProbabilities) {
    return state.patientDetails.mlDiagnosisProbabilities[EXCLUDED_PROPERTY_NAME]
  } else {
    return [];
  }
}

export const getDoctorDataDiagnosedBy = (state) => {
  const lang = state.settings.userSettings.language;
  if (state.patientDetails?.doctorDataDiagnosedBy) {
    return { prefix: state.patientDetails?.doctorDataDiagnosedBy?.physicianPrefix, name: state.patientDetails?.doctorDataDiagnosedBy?.name[lang]?.name || '' }
  } else {
    return state.admin && state.admin[lang] ? { prefix: state.admin.physicianPrefix, name: state.admin[lang].name } : { prefix: '', name: '' };
  }
}
