import { AUTH_ACTIONS_MAP } from '../actions/auth.actions';
import { envSettingsTypes, TENANTS } from '../helpers/auth.helper';
import { ANALYTICS_DATE_LOCALSTORAGE_KEY, ENVIRONMENTS } from '../sagas/helper';

const initialAuthState = {
    isRegistrationValid: false,
    isRegistrationValidationLoading: false,
    isCreatePasswordLoading: false,
    errorCreatePassword: null,
    isRegistrationPasswordLoading: false,
    errorValidation: null,
    errorPasswordAssign: null,
    isResetPasswordLoading: false,
    resetPasswordAction: null,
    resetPasswordError: null,
    version: '',
    IDPService: null,
    AIService: null,
    ImagesService: null,
    analyticsUrl: null,
    tenant: TENANTS.MACCABI,
    nodeEnv: ENVIRONMENTS.PRODUCTION,
    CSRFToken: '',
    IMAGE_SERVICE: '',
}

const authReducer = (state = initialAuthState, action) => {
    switch(action.type) {
        case AUTH_ACTIONS_MAP.setCSRFToken:
            return {
                ...state,
                CSRFToken: action.payload.token
            }
        case 'SET_ENV_SETTINGS': {
            window.analyticsUrl = action.payload[envSettingsTypes.ANALYTICS_URL];
            window.analyticsKey = ANALYTICS_DATE_LOCALSTORAGE_KEY;
            window.NODE_ENV = ENVIRONMENTS[action.payload.NODE_ENV];
            window.VERSION = action.payload[envSettingsTypes.VERSION];
            const tenant = (action.payload.TENANT && TENANTS[action.payload.TENANT.toUpperCase()]) ?
                TENANTS[action.payload.TENANT.toUpperCase()].toUpperCase() : null;
            window.tenant = tenant;
            return {
              ...state,
              IDPService: action.payload[envSettingsTypes.IDP_SERVICE],
              version: action.payload[envSettingsTypes.VERSION],
              AIService: action.payload[envSettingsTypes.AI_SERVICES],
              ImagesService: action.payload[envSettingsTypes.IMAGES_SERVICE],
              analyticsUrl: action.payload[envSettingsTypes.ANALYTICS_URL],
              tenant: tenant ? tenant : TENANTS.MACCABI,
              nodeEnv: ENVIRONMENTS[action.payload.NODE_ENV],
              IMAGE_SERVICE: action.payload[action.payload.IMAGE_SERVICE]
            }
          }
        case AUTH_ACTIONS_MAP.resetPasswordSucces:
        case AUTH_ACTIONS_MAP.resetPasswordFail:
            return {
                ...state,
                isResetPasswordLoading: false,
                resetPasswordAction: action.payload.action,
                resetPasswordError: action.payload.error
            }
        case AUTH_ACTIONS_MAP.resetPasswordStart:
            return {
                ...state,
                isResetPasswordLoading: true,
            }
        case AUTH_ACTIONS_MAP.createNewPasswordStart:
            return {
                ...state,
                isCreatePasswordLoading: true,
            }
        case AUTH_ACTIONS_MAP.createNewPasswordSuccess:
            return {
                ...state,
                isCreatePasswordLoading: false,
                errorCreatePassword: null,
            }
        case AUTH_ACTIONS_MAP.createNewPasswordFail:
            return {
                ...state,
                isCreatePasswordLoading: false,
                errorCreatePassword: action.payload.error,
            }
        case AUTH_ACTIONS_MAP.resetCreateNewPasswordError:
            return {
                ...state,
                errorCreatePassword: null,
            }
        case AUTH_ACTIONS_MAP.savePhysicianPasswordStart:
            return {
                ...state,
                isRegistrationPasswordLoading: true,
            }
        case AUTH_ACTIONS_MAP.savePhysicianPasswordSuccess:
            return {
                ...state,
                isRegistrationPasswordLoading: false,
                invalidPassword: false,
                errorPasswordAssign: null,
            }
        case AUTH_ACTIONS_MAP.savePhysicianPasswordInvalid:
            return {
                ...state,
                invalidPassword: true,
                errorPasswordAssign: null,
            }
        case AUTH_ACTIONS_MAP.savePhysicianPasswordFail:
            return {
                ...state,
                isRegistrationPasswordLoading: false,
                invalidPassword: false,
                errorPasswordAssign: action.payload.error,
            }
        case AUTH_ACTIONS_MAP.isReceivedRegistrationParametersValidStart:
            return {
                ...state,
                isRegistrationValidationLoading: true,
            }
        case AUTH_ACTIONS_MAP.isReceivedRegistrationParametersValidSuccess:
            return {
                ...state,
                isRegistrationValidationLoading: false,
                isRegistrationValid: true,
                errorValidation: null,
            }
        case AUTH_ACTIONS_MAP.isReceivedRegistrationParametersValidFail:
            return {
                ...state,
                isRegistrationValidationLoading: false,
                isRegistrationValid: false,
                errorValidation: action.payload.error,
            }
        default: return state;
    }
}

export default authReducer;
