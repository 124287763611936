
export const CASES_BATCH_ACTIONS_MAP = Object.freeze({
    INIT_CASES_BATCH_AFTER_RELOAD: 'INIT_CASES_BATCH_AFTER_RELOAD',
    INIT_NAC_CASES_BATCH: 'INIT_NAC_CASES_BATCH',
    ADD_ANNOTATION: 'ADD_ANNOTATION',
    MOVE_TO_TAB: 'MOVE_TO_TAB',
    CASE_MERGE_CONFLICT_ON: 'CASE_MERGE_CONFLICT_ON',
    CASE_MERGE_CONFLICT_OFF: 'CASE_MERGE_CONFLICT_OFF',
    CASES_MERGE_REQUEST: 'CASES_MERGE_REQUEST',
    CASES_CONTINUE_SAVE_NOTIFY: 'CASES_CONTINUE_SAVE_NOTIFY',
    GET_DIAGNOSED_CASES_BATCH_REQUEST: 'GET_DIAGNOSED_CASES_BATCH_REQUEST',
    GET_PENDING_CASES_BATCH_REQUEST: 'GET_PENDING_CASES_BATCH_REQUEST',
    GET_DIAGNOSED_CASES_BATCH_REQUEST_START: 'GET_DIAGNOSED_CASES_BATCH_REQUEST_START',
    GET_DIAGNOSED_CASES_BATCH_REQUEST_SUCCESS: 'GET_DIAGNOSED_CASES_BATCH_REQUEST_SUCCESS',
    GET_DIAGNOSED_CASES_BATCH_REQUEST_FAIL: 'GET_DIAGNOSED_CASES_BATCH_REQUEST_FAIL',
    START_EDIT_PAGE_NAC_PROCESS: 'START_EDIT_PAGE_NAC_PROCESS',
    NOTIFY_PATIENT_ON_DIAGNOSED_CASES_REQUEST: 'NOTIFY_PATIENT_ON_DIAGNOSED_CASES_REQUEST',
    NOTIFY_PATIENT_ON_DIAGNOSED_CASES_START: 'NOTIFY_PATIENT_ON_DIAGNOSED_CASES_START',
    NOTIFY_PATIENT_ON_DIAGNOSED_CASES_SUCCESS: 'NOTIFY_PATIENT_ON_DIAGNOSED_CASES_SUCCESS',
    NOTIFY_PATIENT_ON_DIAGNOSED_CASES_FAIL: 'NOTIFY_PATIENT_ON_DIAGNOSED_CASES_FAIL',
    UPDATE_BLOCK_CASES_BATCH_REQUEST: 'UPDATE_BLOCK_CASES_BATCH_REQUEST',
    UPDATE_BLOCK_CASES_BATCH_START: 'UPDATE_BLOCK_CASES_BATCH_START',
    UPDATE_BLOCK_CASES_BATCH_SUCCESS: 'UPDATE_BLOCK_CASES_BATCH_SUCCESS',
    UPDATE_BLOCK_CASES_BATCH_FAIL: 'UPDATE_BLOCK_CASES_BATCH_FAIL',
    UPDATE_UNBLOCK_CASES_BATCH_START: 'UPDATE_UNBLOCK_CASES_BATCH_START',
    UPDATE_UNBLOCK_CASES_BATCH_SUCCESS: 'UPDATE_UNBLOCK_CASES_BATCH_SUCCESS',
    UPDATE_UNBLOCK_CASES_BATCH_FAIL: 'UPDATE_UNBLOCK_CASES_BATCH_FAIL',
    UPDATE_IMAGES_REQUIREMENTS_BLOCK: 'UPDATE_IMAGES_REQUIREMENTS_BLOCK',
    SET_ML_DIAGNOSIS_PROBABILITY: 'SET_ML_DIAGNOSIS_PROBABILITY',
    SET_IMAGES_LOADING: 'SET_IMAGES_LOADING',
    UPDATE_IMAGE_SCROLL_POSITION: 'UPDATE_IMAGE_SCROLL_POSITION',
    UPDATE_SCROLL_CONTAINER: 'UPDATE_SCROLL_CONTAINER',
    UPDATE_TREATMENT_PLAN: 'UPDATE_TREATMENT_PLAN',
    UPDATE_CASE_COMMENT: 'UPDATE_CASE_COMMENT',
    UPDATE_IS_LOADING_SAVE_CASE: ' UPDATE_IS_LOADING_SAVE_CASE',
    UPDATE_IS_NOTIFYING_CASE: 'UPDATE_IS_NOTIFYING_CASE',
    RESET_CASE_DATA: 'RESET_CASE_DATA',
});

export const CASE_EDIT_PAGE_BLOCK_STATUS = Object.freeze({
    SET_IMAGE_CHECK_BLOCK: 'SET_IMAGE_CHECK_BLOCK',
    RELEASE_IMAGE_CHECK_BLOCK: 'RELEASE_IMAGE_CHECK_BLOCK',
    SET_ML_DIAGNOSIS_PROBABILITY: 'SET_ML_DIAGNOSIS_PROBABILITY',
});

export const setMLDiagnosisProbability = (mlDiagnosisProbabilities) => {
    if (mlDiagnosisProbabilities) {
        return {
            type: CASES_BATCH_ACTIONS_MAP.SET_ML_DIAGNOSIS_PROBABILITY,
            payload: { mlDiagnosisProbabilities }
        }
    } else {
        return {
            type: CASES_BATCH_ACTIONS_MAP.SET_ML_DIAGNOSIS_PROBABILITY,
            payload: { mlDiagnosisProbabilities: null }
        }
    }
}

export const HOLD_BATCH_ACTIONS = Object.freeze({
    blockCases: 'BLOCK_CASES_ON _HOLD',
    releaseCases: 'RELEASE_CASES_FROM _HOLD',
});

export const BLOCK_CASES_RESPONSE = Object.freeze({
    casesAreBlocked: 'BLOCKED',
    casesAreUnblocked: 'UNBLOCKED',
    forbiddenToBlock: 'FORBIDDEN_TO_BLOCK',
});

export const resetCaseData = () => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.RESET_CASE_DATA,
        payload: {  }
    }
}


export const updateCaseComment = (comment) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.UPDATE_CASE_COMMENT,
        payload: { comment }
    }
}

export const updateNextTab = (nextTabIndex) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.MOVE_TO_TAB,
        payload: { nextTabIndex }
    }
}

export const updateIsNotifyingCase = (isNotify) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.UPDATE_IS_NOTIFYING_CASE,
        payload: { isNotify }
    }
}

export const updateIsLoadingSaveCase = (isLoad) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.UPDATE_IS_LOADING_SAVE_CASE,
        payload: { isLoad }
    }
}

export const updateDiagnosis = (diagnosis, name) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.UPDATE_TREATMENT_PLAN,
        payload: { diagnosis, name }
    }
}

export const updateImageRequirementBlock = (imageRequirementStatus) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.UPDATE_IMAGES_REQUIREMENTS_BLOCK,
        payload: { imageRequirementStatus }
    }
}

export const updateIsImagesLoading = (isLoad) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.SET_IMAGES_LOADING,
        payload: { isLoad }
    }
}


export const blockCasesBatchRequest = (action = HOLD_BATCH_ACTIONS.blockCases, description = '') =>
    ({ type: CASES_BATCH_ACTIONS_MAP.UPDATE_BLOCK_CASES_BATCH_REQUEST, payload: { action, description } });
export const blockCasesBatchStart = (unblock) => {
    if (unblock) {
        return { type: CASES_BATCH_ACTIONS_MAP.UPDATE_UNBLOCK_CASES_BATCH_START }
    }
    return { type: CASES_BATCH_ACTIONS_MAP.UPDATE_BLOCK_CASES_BATCH_START };
};
export const blockCasesBatchSuccess = (descriptions, unblock) => {
    if (unblock) {
        return { type: CASES_BATCH_ACTIONS_MAP.UPDATE_UNBLOCK_CASES_BATCH_SUCCESS };
    }
    return { type: CASES_BATCH_ACTIONS_MAP.UPDATE_BLOCK_CASES_BATCH_SUCCESS, payload: { descriptions } };
};
export const blockCasesBatchFail = (error, unblock) => {
    if (unblock) {
        return { type: CASES_BATCH_ACTIONS_MAP.UPDATE_UNBLOCK_CASES_BATCH_FAIL, payload: { error } }
    }
    return { type: CASES_BATCH_ACTIONS_MAP.UPDATE_BLOCK_CASES_BATCH_FAIL, payload: { error } };
};

export const notifyPatientBatch = (data = {}) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.NOTIFY_PATIENT_ON_DIAGNOSED_CASES_REQUEST,
        payload: data,
    }
}

export const getDiagnosedCasesBatchRequest = (data) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.GET_DIAGNOSED_CASES_BATCH_REQUEST,
        payload: data,
    }
}

export const getPendingCasesBatchRequest = (data) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.GET_PENDING_CASES_BATCH_REQUEST,
        payload: data,
    }
}

export const getDiagnosedCasesBatchRequestStart = (data) => {
    const { uuid } = data;
    return {
        type: CASES_BATCH_ACTIONS_MAP.GET_DIAGNOSED_CASES_BATCH_REQUEST_START,
        payload: { uuid },
    }
}

export const getDiagnosedCasesBatchRequestSuccess = (batch_uuids, curUuid) => {
    const currentCaseIndex = (Array.isArray(batch_uuids) && batch_uuids.length) ?
        batch_uuids.findIndex(c => c.uuid === curUuid) : 0
    return {
        type: CASES_BATCH_ACTIONS_MAP.GET_DIAGNOSED_CASES_BATCH_REQUEST_SUCCESS,
        payload: { batch_uuids, currentCaseIndex },
    }
}

export const getDiagnosedCasesBatchRequestFail = (error) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.GET_DIAGNOSED_CASES_BATCH_REQUEST_FAIL,
        payload: { error },
    }
}

export const initCasesBatchAfterReload = () => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.INIT_CASES_BATCH_AFTER_RELOAD,
    }
}

export const initCasesBatch = (uuids) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.INIT_NAC_CASES_BATCH,
        payload: { uuids },
    }
}
