import React, {memo} from 'react';
import { useStyles } from './styles';
import { HeaderUserCommon } from './HeaderUser/HeaderUserCommon';
import { HeaderCasesCommon } from './HeaderCases/HeaderCasesCommon';

export const HeaderCommon = memo((props) => {
  const s = useStyles();

  return (
    <>
      <header className={s.header}>
        <HeaderUserCommon />
        <HeaderCasesCommon />
      </header>
    </>
  );
});