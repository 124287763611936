const initialState = {
  templates: [],
  templateProcess: false
  // selectedTemplate: ''
};

export default function templatesReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_TEMPLATES': {
      return {
        ...state,
        templates: action.payload
      };
    }
    case 'START_TEMPLATE_PROCESS':
      return {...state, templateProcess: true }
    case 'END_TEMPLATE_PROCESS':
      return {...state, templateProcess: false}
    // case 'ADD_TEMPLATE': {
    //   return {
    //     ...state,
    //     templates: [...state.templates, action.template]
    //   };
    // }
    // case 'EDIT_TEMPLATE': {
    //   let templates = [...state.templates]
    //   const index = templates.findIndex(item => item.id === action.template.id)
    //   templates[index] = action.template
    //   return {
    //     ...state,
    //     templates: templates,
    //     selectedTemplate: action.template
    //   };
    // }
    // case 'DELETE_TEMPLATE': {
    //   let templates = [...state.templates]
    //   const index = templates.findIndex(item => item.id === action.id)
    //   templates.splice(index, 1)
    //   return {
    //     ...state,
    //     templates: templates
    //   };
    // }
    // case 'SELECT_TEMPLATE': {
    //   return {
    //     ...state,
    //     selectedTemplate: action.payload
    //   };
    // }
    // case 'CLEAN_TEMPLATES_STATE': {
    //   return {
    //     templates: [],
    //     selectedTemplate: null
    //   };
    // }
    default: {
      return state;
    }
  }
}