import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  authBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  authBarBox: {
    margin: '0 -12px'
  },
  littleText: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#858585',
    padding: '0 12px',
  }
}));