import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { LangButton } from '../LangButton/LangButton';
import { SettingsButton } from '../SettingsButton/SettingsButton';
import { getCurrentPhycisianEmail } from '../../../selectors/settings.selector';
import packageJson from '../../../../package.json';


export const AuthBar = memo(() => {
  const s = useStyles();
  const user = useSelector(getCurrentPhycisianEmail);
  const isAuthenticated = !!user;
  return (
    <div className={s.authBar}>
      <div className={s.authBarBox}>
        <LangButton />
      </div>
      {
        isAuthenticated && (
          <div className={s.authBarBox}>
            <SettingsButton />
          </div>
        )
      }
      <div className={s.authBarBox}>
        <div className={s.littleText}>{ packageJson.version || '1.0.0' }</div>
      </div>
      {/* {
        isAuthenticated && (
          <div className={s.authBarBox}>
            <LogoutButton />
          </div>
        )
      } */}
    </div>
  );
});