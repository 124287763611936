import { SYSTEM_STATUSES } from '../../helpers/groups';

export const countAwaitingCases = (currentCasesArray) => {
    if (!Array.isArray(currentCasesArray)) {
        return 0;
    } else {
        return currentCasesArray.reduce((acc, val) => {
            if (
                val.status !== SYSTEM_STATUSES.DIAGNOSED &&
                val.status !== SYSTEM_STATUSES.DIAGNOSED_NOTIFIED &&
                val.status !== SYSTEM_STATUSES.VIEWED_BY_PATIENT
            ) {
                acc += 1;
            }
            return acc;
        }, 0);
    }
}

export const isOnlyDiagnosedAndNotifiedCases = (currentCasesArray) => {
    if (Array.isArray(currentCasesArray) || currentCasesArray.length) {
        // const index = currentCasesArray.findIndex(c => [9, 33, 46, 99].includes(c.status));
        // return index > -1;
        return currentCasesArray.every(c => [9, 33, 46, 90, 99].includes(c.status));
    }
    return false;
}

export const scrollCounter = 30;

export const isOnlyDiagnosedCases = (currentCasesArray) => {
    if (Array.isArray(currentCasesArray) || currentCasesArray.length) {
        const index = currentCasesArray.findIndex(c => {
            switch(c.status) {
                case SYSTEM_STATUSES.NONE:
                case SYSTEM_STATUSES.COMPLETED_FOR_DIAGNOSE:
                case SYSTEM_STATUSES.EDITING:
                case SYSTEM_STATUSES.IN_PROCESS:
                    return true;
                default: return false;
            }
        });
        return index === -1;
    }
    return false;
}

export const countDiagnosedAwaitingCases = (currentCasesArray) => {
    if (!Array.isArray(currentCasesArray)) {
        return 0;
    } else {
        return currentCasesArray.reduce((acc, val) => {
            if (
                val.status === SYSTEM_STATUSES.DIAGNOSED ||
                val.status === SYSTEM_STATUSES.DIAGNOSED_NOTIFIED ||
                val.status === SYSTEM_STATUSES.VIEWED_BY_PATIENT
            ) {
                acc += 1;
            }
            return acc;
        }, 0);
    }
}

export const isCaseDiagnosed = (currentCasesArray, uuid) => {
    const caseObj = currentCasesArray.find(a => a.uuid === uuid);
    if (!!caseObj) {
        return caseObj.status === SYSTEM_STATUSES.DIAGNOSED_NOTIFIED ||
            caseObj.status === SYSTEM_STATUSES.VIEWED_BY_PATIENT;
    } else {
        return false;
    }
}

export const isLastCaseInAwaiting = (
    currentCasesArray,
    caseUUID,
    currentCaseIndex,
) => {
    if (!currentCasesArray || (currentCasesArray.length === 1)) {
        return true;
    } else if (currentCasesArray) {
        const diagnosedCasesCount = countDiagnosedAwaitingCases(currentCasesArray);
        if (diagnosedCasesCount === currentCasesArray.length) {
            return true
        } else {
            const awaitingCount = countAwaitingCases(currentCasesArray);
            const isCase = isCaseDiagnosed(currentCasesArray, caseUUID);
            if (isCase) {
                 return ((currentCasesArray.length - 1) === currentCaseIndex);
            } else {
                const awaitingIndex = currentCasesArray.findIndex(c => 
                    c.status === SYSTEM_STATUSES.IN_PROCESS ||
                    c.status === SYSTEM_STATUSES.COMPLETED_FOR_DIAGNOSE
                )
                return ((awaitingCount === 1) &&  awaitingIndex === currentCaseIndex);
            }
        }
    } else {
        return true;
    }
}

export const isDialogOpenByConditions = (annotationState) => {
   return annotationState.isAnnotationModal || false;
}

export const isNotificationError = (annotationState) => {
    return (annotationState.error && !annotationState.notification);
}

export const showNoCaseDialog = (currentCasesArray, isCurrentAvailableFaild, isDialogOpen, caseAvailabilityProps) => {
    return (!Array.isArray(currentCasesArray) || !currentCasesArray.length) && 
    isCurrentAvailableFaild && !isDialogOpen && !caseAvailabilityProps.isOnlyDiagnosed;
}

const KEY_CODE_CHAR_MAP = {
    48: '0',
    49: '1',
    50: '2',
    51: '3',
    52: '4',
    53: '5',
    54: '6',
    55: '7',
    56: '8',
    57: '9',
    65: 'a',
    66: 'b',
    67: 'c',
    68: 'd',
    69: 'e',
    70: 'f',
    71: 'g',
    72: 'h',
    73: 'i',
    74: 'j',
    75: 'k',
    76: 'l',
    77: 'm',
    78: 'n',
    79: 'o',
    80: 'p',
    81: 'q',
    82: 'r',
    83: 's',
    84: 't',
    85: 'u',
    86: 'v',
    87: 'w',
    88: 'x',
    89: 'y',
    90: 'z',
}

export const charByCode = (code) => {
    if(code && KEY_CODE_CHAR_MAP[code]) {
        return KEY_CODE_CHAR_MAP[code];
    }
    return '';
}
