import { ADMIN_ACTIONS_MAP } from '../actions/admin.actions';
import { languages, languagesObject } from '../selectors';
import { EDoctorPermissions } from './settingReducer';

export const DEFAULT_VACATION = [{ start_vacation: new Date(), end_vacation: new Date(), key: 'selection' }];

export const initialAdminState = {
  user: '',
  admin: '',
  phone: '',
  roleMap: {},
  permission: EDoctorPermissions.user,
  signature: '',
  stamp: '',
  stamp_blob_link: '',
  profile_image: '',
  profile_image_blob_link: '',
  isActive: true,
  employees: [],
  vacation: DEFAULT_VACATION,
  isVacationSet: false,
  // namePrefixObject: PHYSICIAN_DEFAULT_NAME_PREFIX,
  doctorManagement: {},
  isAdminChangesLoading: false,
  isInvitationLoading: false,
  isSaveDetailsLoading: false,
  isSaveDetailsSuccess: false,
  isStampLoading: false,
  isProfileImageLoading: false,
  invitationEmail: '',
  physicianPrefix: '',
  loadingActDeact: false,
  scrollValue: 0,
  ai_networks: null,
  degree: '',
  license:'',
  showImages: false,
  clinic_phone: '',
  address_clinic: '',
  clinic_address: '',
  expert_license: '',
  smsNotification: false,
  physician_details: '',
  clinic_address_second_part: '',
  clinic_city: '',
  clinic_region: '',
  clinic_country: '',
  clinic_zip: '',
  clinic_linkFaceBook: '',
  clinic_linkInstagram: '',
  clinic_linkLinkedin: '',
  clinic_linkUrl: '',
}

const defualtLangState = {
  name: ''
}

for(let i = 0; i < languages.length; i++) {
  const lang = languages[i].lang;
  initialAdminState[lang] = defualtLangState;
}

const adminReducer = (state = initialAdminState, action) => {
  let lang = languagesObject.HE;
  switch(action.type) {
    case ADMIN_ACTIONS_MAP.updatePhysicianNamePrefix:
      return {
        ...state,
        namePrefixObject: action.payload,
      }
    case ADMIN_ACTIONS_MAP.updateScrollvalue:
      return {
        ...state,
        scrollValue: action.payload.value
      }
    case ADMIN_ACTIONS_MAP.invitePhysycyanStart:
      return {
        ...state,
        isInvitationLoading: true
      }
    case ADMIN_ACTIONS_MAP.invitePhysicianSuccess:
      return {
        ...state,
        invitationEmail: '',
        isInvitationLoading: false
      }
    case ADMIN_ACTIONS_MAP.invitePhysicianFail:
      return {
        ...state,
        error: action.payload.error,
        isInvitationLoading: false
      }
    // save physician details
    case ADMIN_ACTIONS_MAP.savePhysicianDetails:
      return {
        ...state,
        isSaveDetailsLoading: true
      }
    case ADMIN_ACTIONS_MAP.savePhysicianDetailsSuccess:
      return {
        ...state,
        ...action.payload.admin_settings,
        isSaveDetailsLoading: false
      }
    case ADMIN_ACTIONS_MAP.setIsSavePhysicianDerailsSuccess:
      return{
        ...state,
        isSaveDetailsSuccess: action.payload,
      }
    case ADMIN_ACTIONS_MAP.savePhysicianDetailsFail:
      return {
        ...state,
        error: action.payload.error,
        isSaveDetailsLoading: false
      }
    //

    // save physician profile_image
    case ADMIN_ACTIONS_MAP.savePhysicianStampRequest:
      return {
        ...state,
        isStampLoading: true
      }
    case ADMIN_ACTIONS_MAP.savePhysicianStampSuccess:
      return {
        ...state,
        stamp: action.payload.stamp,
        isStampLoading: false
      }
    case ADMIN_ACTIONS_MAP.savePhysicianStampFail:
      return {
        ...state,
        error: action.error,
        isStampLoading: false
      }
    //

     // save physician profile_image
     case ADMIN_ACTIONS_MAP.savePhysicianProfileImageRequest:
      return {
        ...state,
        isProfileImageLoading: true
      }
    case ADMIN_ACTIONS_MAP.savePhysicianProfileImageSuccess:
      return {
        ...state,
        profile_image: action.payload.profile_image,
        isProfileImageLoading: false
      }
    case ADMIN_ACTIONS_MAP.savePhysicianProfileImageFail:
      return {
        ...state,
        error: action.payload.error,
        isProfileImageLoading: false
      }
    //

    // get physician stamp
    case ADMIN_ACTIONS_MAP.getPhysicianStampRequest:
      return {
        ...state,
        isStampLoading: true
      }
    case ADMIN_ACTIONS_MAP.getPhysicianStampSuccess:
      return {
        ...state,
        stamp_blob_link: action.payload.link,
        isStampLoading: false
      }
    case ADMIN_ACTIONS_MAP.getPhysicianStampFail:
      return {
        ...state,
        error: action.payload.error,
        isStampLoading: false
      }
    //

    // get physician profile_image
    case ADMIN_ACTIONS_MAP.getPhysicianProfileImageRequest:
      return {
        ...state,
        isProfileImageLoading: true
      }
    case ADMIN_ACTIONS_MAP.getPhysicianProfileImageSuccess:
      return {
        ...state,
        profile_image_blob_link: action.payload.link,
        isProfileImageLoading: false
      }
    case ADMIN_ACTIONS_MAP.getPhysicianProfileImageFail:
      return {
        ...state,
        error: action.payload.error,
        isProfileImageLoading: false
      }
    //

    case ADMIN_ACTIONS_MAP.updateInvitationalEmail:
      return {
        ...state,
        invitationEmail: action.payload.invitationEmail,
      }
    case ADMIN_ACTIONS_MAP.savePhysicianInformationSuccess:
    case ADMIN_ACTIONS_MAP.savePhysicianInformationFail:
      return {
        ...state,
        isAdminChangesLoading: false,
      }
    case ADMIN_ACTIONS_MAP.savePhysicianInformationStart:
      return {
        ...state,
        isAdminChangesLoading: true,
       }
    case ADMIN_ACTIONS_MAP.setIsVacationActivated:
      return {
        ...state,
        isVacationSet: action.payload.isVacationSet,
      }
    case ADMIN_ACTIONS_MAP.updateDoctorManagemtByPhysician:
      return {
        ...state,
        doctorManagement: action.payload.doctorManagement,
      }
    case ADMIN_ACTIONS_MAP.updateUserActivity:
      return {
        ...state,
        isActive: action.payload.isActive,
      }
    case ADMIN_ACTIONS_MAP.updateCurrentPhysicianVacationPeriod:
      return {
        ...state,
        vacation: action.payload.vacation,
      }
    case ADMIN_ACTIONS_MAP.updateCurrentPhysicianSignature:
      return {
        ...state,
        signature: action.payload.signature,
      }
    case ADMIN_ACTIONS_MAP.updatePhysicianPrefix:
      return {
        ...state,
        physicianPrefix: action.payload.physicianPrefix,
      }
    case ADMIN_ACTIONS_MAP.updateCurrentPhysicianAllNames:
      return {
        ...state,
        [languagesObject.HE]: action.payload.names[languagesObject.HE],
        [languagesObject.EN]: action.payload.names[languagesObject.EN],
        name: action.payload.names.name
      }
    case ADMIN_ACTIONS_MAP.updateCurrentPhysicianName:
      return {
        ...state,
        [action.payload.lang]: {...state[action.payload.lang], name: `${action.payload.name}`},
      }
    case ADMIN_ACTIONS_MAP.updateCurrentPhysicianPhone:
      return {
        ...state,
        phone: action.payload.phone,
      }
    case ADMIN_ACTIONS_MAP.updateCurrentPhysicianUser:
      return {
        ...state,
        user: action.payload.user,
      }
    case ADMIN_ACTIONS_MAP.updateAdminSettingsByPhysicianSuccess:
      return {
        ...state,
      }
    case ADMIN_ACTIONS_MAP.setRoleMap:
      return {
        ...state,
        ...{
          roleMap: action.payload.roleMap
        }
      }
    case ADMIN_ACTIONS_MAP.setLoadingActDeact:
      return {
        ...state,
        ...{
          loadingActDeact: action.payload.loadingActDeact
        }
      }
    case ADMIN_ACTIONS_MAP.setAdminSettings:
      let adminSettings = action.payload.adminSettings;

      if(adminSettings && adminSettings.name) {
        if(!adminSettings[languagesObject.HE] || (adminSettings[languagesObject.HE] && !adminSettings[languagesObject.HE].name)) {
          adminSettings[languagesObject.HE] = defualtLangState;
          adminSettings[languagesObject.HE].name = '';
        }

        if(!adminSettings[languagesObject.EN] || (adminSettings[languagesObject.EN] && !adminSettings[languagesObject.EN].name)) {
          adminSettings[languagesObject.EN] = defualtLangState;
          adminSettings[languagesObject.EN].name = '';
        }
      }

      return {
          ...state,
          ...{
            ...action.payload.adminSettings,
            [lang]: action.payload.adminSettings ? (action.payload.adminSettings[lang] ? action.payload.adminSettings[lang] : defualtLangState): null,
            user: action.payload.admin,
            admin: action.payload.admin,
            employees: action.payload.employees,
            permission: action.payload.permission,
            isActive: action.payload.is_active,
            vacation: action.payload.vacation,
            roleMap: action.payload.roleMap,
            isVacationSet: action.payload.isVacationSet,
            doctorManagement: {
              [action.payload.user]: {
                admin_settings: action.payload.adminSettings,
              }
            },
          }
      }
    case ADMIN_ACTIONS_MAP.resetAdminSettings:
      return {
          ...state,
          ...initialAdminState
      }
    default: return state;
  }
}

export default adminReducer;
