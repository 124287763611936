import React from 'react';
import { DialogTitle, DialogContent, DialogActions, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { blockPhysicianDialogClose } from '../actions/notification.actions';
import { generateUrlParams as generateUrlParamsLikeCef } from '../sagas/helper';

const pfx = 'blockedPhysicianDialog.'

const BlockedPhysicianDialog = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <>
            <DialogTitle>
                {t(`${pfx}title`)}
            </DialogTitle>
            <DialogContent>
                <Typography variant="h6" >
                    {t(`${pfx}body`)}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(blockPhysicianDialogClose())
                        history.push(generateUrlParamsLikeCef('/login'));
                    }}
                >
                    {t(`${pfx}contactManagerBtn`)}
                </Button>
            </DialogActions>
        </>
    )
}

export default BlockedPhysicianDialog;