import { useState, useEffect } from 'react';
import { green } from '@material-ui/core/colors';
import * as moment from 'moment';
import 'moment/min/locales';
import 'moment/locale/he';
import { GENDER, isRoleMaccabi } from '../../../selectors';

export const DEBOUNCE_DELAY = 600;
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}

export const findValueInArray = (array) => {
  return Boolean(array?.length && array?.find((item) => item?.value1?.value));
};

export const isNotEmptyDiagnosis = (diagnoses) =>
  Boolean(diagnoses?.length && diagnoses[0]?.value?.text);
const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const autoAlgo = (opts, state) => {
  const iv = state.inputValue;
  const inputArray = iv.split(/[ ,]+/).map((w) => `${w}`.toLowerCase());
  const tempMap = {};
  const filteredInputArray = inputArray.filter((d) => {
    if (!d || tempMap[d]) {
      return false;
    }
    tempMap[d] = true;
    return true;
  });

  if (!Array.isArray(filteredInputArray) || !filteredInputArray.length) {
    return opts;
  }

  const firstPattern = filteredInputArray[0].toLowerCase();
  const options = [...opts];
  if (filteredInputArray.length === 1) {
    const repeatedItems = {};
    const aPatternArray = options.filter((o, i) => {
      const l = `${state.getOptionLabel(o).toLowerCase()}`;
      const isStarts = l.startsWith(firstPattern);
      if (isStarts) {
        repeatedItems[i] = true;
      }
      return isStarts;
    });

    const bPatternArray = options.filter((o, i) => {
      if (repeatedItems[i]) {
        return false;
      }
      const l = `${state.getOptionLabel(o).toLowerCase()}`;
      return l.includes(firstPattern);
    });

    aPatternArray.sort();
    if (bPatternArray.length) {
      bPatternArray.sort();
    }
    return [...aPatternArray, ...bPatternArray];
  } else {
    const max = filteredInputArray.length - 1;
    let regexMatch = filteredInputArray.reduce((acc, val, index) => {
      acc = `${acc}(?=.*${escapeRegExp(val)})${index === max ? '.+' : ''}`;
      return acc;
    }, '');
    let cPatternArray = options.filter((o, i) => {
      const l = `${state.getOptionLabel(o).toLowerCase()}`;
      return l.match(`${regexMatch}`);
    });
    cPatternArray.sort();
    const arrayA = [];
    const mapFoundOptions = {};
    let stringMatch = '';
    inputArray.forEach((word) => {
      stringMatch += ` ${word}`;
    });
    cPatternArray.forEach((diag) => {
      const matchWith = diag.name.toLowerCase().match(`^${stringMatch.trim()}`);
      if (diag.name && matchWith) {
        arrayA.push(diag);
        mapFoundOptions[diag.id] = true;
      }
    });
    const arrayB = cPatternArray.filter((d) => !mapFoundOptions[d.id]);
    return [...arrayA, ...arrayB];
  }
};

export const filterDuplicatedFromArray = (arr, param) => {
  const idemsMap = {};
  const copyArr = [...arr];
  const resArr = copyArr.filter((o) => {
    const s = !!param ? o[param] : o;
    if (!idemsMap[s]) {
      idemsMap[s] = true;
      return true;
    } else {
      return false;
    }
  });
  return resArr;
};

export const hebrewLettersArray = [
  'ף',
  'ך',
  'ם',
  'א',
  'בּ',
  'ב',
  'ג',
  'ד',
  'ה',
  'ו',
  'ז',
  'ח',
  'ט',
  'י',
  'כ',
  'כּ',
  'ל',
  'מ',
  'נ',
  'ס',
  'ע',
  'פּ',
  'פ',
  'צ',
  'ק',
  'ר',
  'שׁ',
  'שׂ',
  'תּ',
  'ת',
  'ש',
  'ץ',
];

// export const getHebrewMap = () => {
//     return hebrewLettersArray.reduce((acc, val) => {
//         acc[`${val}`] = `${val}`;
//         return acc;
//     }, {});
// }

export const cleanSessionStorageClipboard = () => {
  [
    'case-background',
    'case-anamnesis',
    'case-condition',
    'patient_description',
    'skinType',
    'freeTextAnnotation',
  ].forEach((i) => sessionStorage.removeItem(i));
};

export const HEBREW_TO_ENGLISH_MAP = {
  ק: 'e',
  ר: 'r',
  א: 't',
  ט: 'y',
  ו: 'u',
  ן: 'i',
  ם: 'o',
  פ: 'p',
  ש: 'a',
  ד: 's',
  ג: 'd',
  כ: 'f',
  ע: 'g',
  ח: 'j',
  ל: 'k',
  ך: 'l',
  ף: ';',
  ז: 'z',
  ס: 'x',
  ב: 'c',
  ה: 'v',
  נ: 'b',
  מ: 'n',
  צ: 'm',
  ת: ',',
  ץ: ',',
  י: 'h',
};
export const scrollStyles = {
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#dce7ea',
    borderRadius: '100px',
    boxShadow: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#65bed1',
    border: '4px solid #dce7ea',
    borderRadius: '12px',
  },
};

export const restrictOnChangeOnlyEnglish = (e) => {
  try {
    const comment = `${e.target.value}`;
    let transformedComment = comment;
    transformedComment = '';
    for (let c = 0; c < comment.length; c++) {
      const curChar = comment.charAt(c);
      if (curChar && !!HEBREW_TO_ENGLISH_MAP[curChar]) {
        transformedComment += HEBREW_TO_ENGLISH_MAP[curChar];
      } else if (curChar && curChar === "'") {
        transformedComment += 'w';
      } else if (curChar && curChar === '/') {
        transformedComment += 'q';
      } else {
        transformedComment += curChar;
      }
    }
    return transformedComment;
  } catch (e) {
    const comment = `${e.target.value}`;
    console.log('check usage of => restrictOnChangeOnlyEnglish');
    return comment;
  }
};

export function calcAge(patientDetails, lng, t) {
  const _rq = patientDetails;
  if (patientDetails) {
    const age = _rq.age;
    const absuloteGender = _rq.gender;
    let result = `${
      absuloteGender === GENDER.MALE ? t('props.male') : t('props.female')
    } `;

    if (!age || age < 0.002) {
      return t('patientsList.specialAge');
    } else if (age > 0.002 && age <= 0.0822) {
      const days = Math.round(age * 365);
      const weeks = Math.floor(days / 7);

      if (weeks >= 1) {
        const restDays = days % 7;
        result += ` ${
          weeks === 1
            ? t('patientsList.unitWeek_list')
            : `${weeks} ${t('patientsList.unitWeeks')}`
        } `;
        if (restDays) {
          result += ` ${t('patientsList.and')}${
            restDays === 1
              ? t('patientsList.unitDay_list')
              : `${restDays} ${t('patientsList.unitDays')}`
          } `;
        }

        result += t('patientsList.old');

        return result;
      }
      result += `${days} ${
        days === 1 ? t('patientsList.unitDay_list') : t('patientsList.unitDays')
      } `;
      result += t('patientsList.old');

      return result;
    } else if (age >= 1 && age % 1 === 0) {
      const years = Math.floor(age);
      result += `${
        years === 1
          ? t('patientsList.unitYear_list')
          : `${years} ${t('patientsList.unitYears')}`
      } `;

      result += t('patientsList.old');

      return result;
    } else {
      const months = Math.round((age * 365) / 30);

      if (months >= 12) {
        const years = Math.floor(months / 12);
        const restMonths = months % 12;
        result += ` ${
          years === 1
            ? t('patientsList.unitYear_list')
            : `${years} ${t('patientsList.unitYears')}`
        } `;
        if (restMonths >= 1) {
          result += ` ${t('patientsList.and')}${
            restMonths === 1
              ? t('patientsList.unitMonth_list')
              : `${restMonths} ${t('patientsList.unitMonths')}`
          } `;
        }

        result += t('patientsList.old');

        return result;
      }

      result += ` ${
        months === 1
          ? t('patientsList.unitMonth_list')
          : `${months} ${t('patientsList.unitMonths')}`
      } `;

      result += t('patientsList.old');

      return result;
    }
  }
}

export const defaultDiagnoses = {
  code: '',
  text: '',
  characteristic1: '',
  characteristic2: '',
  comment: '',
  isSending: false,
  treatmentPlan: '',
};
const newDefaultDiagnoses = {
  code: '',
  text: '',
  comment: '',
  severityLevel: '',
  isSending: false, // ???
  treatmentPlan: '', // ???
};
export const configuration = {
  maccabi: {
    vendor: 'maccabi',
    placeholderComment: 'modalAnnotation.placeholderCommentMaccabi',
    placeholderDiagnosis: 'modalAnnotation.placeholderDiseaseMaccabi',
    placeholderDiagnosisComment:
      'modalAnnotation.placeholderDiseaseCommentMaccabi',
    placeholderDiagnosisCharacteristic: 'modalAnnotation.Characteristic',
    diagnosisData: newDefaultDiagnoses,
    diagnosisData1: defaultDiagnoses,
    diagnosisData2: defaultDiagnoses,
    diagnosisData3: defaultDiagnoses,
    commentData: { text: 'annotation.maccabiComment', isSending: true },
  },
  common: {
    vendor: 'common',
    placeholderComment: 'modalAnnotation.placeholderCommentGeneral',
    placeholderDiagnosis: 'modalAnnotation.placeholderDiseaseGeneral',
    placeholderDiagnosisComment:
      'modalAnnotation.placeholderDiseaseCommentGeneral',
    placeholderDiagnosisCharacteristic: 'modalAnnotation.Characteristic',
    labelDiagnosis1: 'modalAnnotation.labelDiagnosis1',
    labelDiagnosis2: 'modalAnnotation.labelDiagnosis2',
    labelDiagnosis3: 'modalAnnotation.labelDiagnosis3',
    diagnosisData: defaultDiagnoses,
    diagnosisData1: { ...defaultDiagnoses, isSending: true },
    diagnosisData2: defaultDiagnoses,
    diagnosisData3: defaultDiagnoses,
    commentData: { text: 'annotation.yeledoctorComment', isSending: true },
  },
};

export const conf = (key) => {
  if (isRoleMaccabi()) {
    return configuration.maccabi[key] || configuration.common[key];
  } else {
    return configuration.common[key];
  }
};

export const typesAnnotation = {
  differentialDiagnosis: 'Enter Differential Diagnosis',
  unclearImage: 'unclear image',
  callBack: 'Call back',
  clinicVisit: 'Clinic visit',
  notTelemedicine: 'Not suitable for Telemedicine',
};

export const buttonProgressStyle = {
  color: green[500],
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
};

export const calcAgeFromDate = (date, gender, t) => {
  if (!date) {
    return t('patientsList.specialAge');
  }
  var ageDate = new Date(date);
  var currentDate = new Date();
  var a = moment([
    ageDate.getUTCFullYear(),
    ageDate.getUTCMonth(),
    ageDate.getUTCDate(),
  ]);
  var b = moment([
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
  ]);
  const ageInDays = b.diff(a, 'days');

  let years = 0;
  let months = 0;
  let weeks = 0;
  let days = 0;
  let restDays = ageInDays;

  if (restDays > 365) {
    years = parseInt(restDays / 365);
    restDays = restDays % 365;
  }

  if (restDays > 30) {
    months = parseInt(restDays / 30);
    restDays = restDays % 30;
  }

  if (restDays > 7) {
    weeks = parseInt(restDays / 7);
    days = restDays % 7;
  }

  // const absuloteGender = gender;
  let result = '';
  // let result = `${absuloteGender === GENDER.MALE ? t('props.male') : t('props.female')}/`
  if (!months && !years) {
    if (weeks >= 1) {
      const restDays = days;
      result += `${weeks} ${t('props.timeInterval.w')}`;
      // result += ` ${(weeks === 1) ? t('patientsList.unitWeek_list') : `${weeks} ${t('patientsList.unitWeeks')}`} `;
      if (restDays) {
        result += ` ${restDays} ${t('props.timeInterval.d')}`;
        // result += ` ${t('patientsList.and')}${(restDays === 1) ? t('patientsList.unitDay_list') : `${restDays} ${t('patientsList.unitDays')}`} `;
      }

      // result += t('patientsList.old');
      return result;
    }
    result += `${days} ${t('props.timeInterval.d')}`;
    // result += `${days} ${(days === 1) ? t('patientsList.unitDay_list') : t('patientsList.unitDays')} `;
    // result += t('patientsList.old');
    return result;
  } else if (years && !months) {
    result += `${years} ${t('props.timeInterval.y')}`;
    // result += `${(years === 1) ? t('patientsList.unitYear_list') : `${years} ${t('patientsList.unitYears')}`} `;
    // result += t('patientsList.old');
    return result;
  } else if (months) {
    if (years) {
      const restMonths = months;
      result += `${years} ${t('props.timeInterval.y')}`;
      // result += ` ${(years === 1) ? t('patientsList.unitYear_list') : `${years} ${t('patientsList.unitYears')}`} `;
      if (restMonths >= 1) {
        result += ` ${restMonths} ${t('props.timeInterval.m')}`;
        // result += ` ${t('patientsList.and')}${(restMonths === 1) ? t('patientsList.unitMonth_list') : `${restMonths} ${t('patientsList.unitMonths')}`} `;
      }
      // result += t('patientsList.old');
      return result;
    }

    result += `${months} ${t('props.timeInterval.m')}`;
    // result += ` ${(months === 1) ? t('patientsList.unitMonth_list') : `${months} ${t('patientsList.unitMonths')}`} `;
    // result += t('patientsList.old');

    return result;
  }
};
export const createTreatmentPlanForm = async (data) => {
  // let diagnoses;
  // if (data?.isUnresolvedTreatmentEffect) {
  //   diagnoses = data?.patientAnnotations[0]?.diagnosis;
  // } else {
  //   diagnoses =
  //     data?.diagnoses?.length &&
  //     data.diagnoses?.reduce((acc, item) => {
  //       if (item.value.text) {
  //         acc.push({
  //           text: item.value.text,
  //           comment: item.value.comment,
  //           severityLevel: item.value.severityLevel,
  //         });
  //       }
  //       return acc;
  //     }, []);
  // }

  // const prescriptions =
  //   data?.prescriptions?.length &&
  //   data.prescriptions?.reduce((newArr, item) => {
  //     if (item.value1.value || item.value2.value || item.value3.value) {
  //       newArr.push({
  //         id: null,
  //         name: item.value1.value,
  //         dosage: item.value2.value,
  //         comment: item.value3.value,
  //       });
  //     }
  //     return newArr;
  //   }, []);

  // const referrals =
  //   data?.referrals?.length &&
  //   data.referrals.reduce((newArr, item) => {
  //     if (item.value1.value) {
  //       newArr.push({ comment: item.value1.value });
  //     }
  //     return newArr;
  //   }, []);

  // const treatmentPlan = {
  //   comment: data?.isUnresolvedTreatmentEffect
  //     ? data?.patientAnnotations[0]?.treatment_plan?.treatment_plan?.comment
  //     : data?.treatmentPlans[0]?.value1?.value,
  // };

  const skinType = data.skinType && { skinType: data.skinType };
  const freeTextAnnotation = data.freeTextAnnotation && { freeTextAnnotation: data.freeTextAnnotation };

  return {
    modified_by: data.user,
    // user_identifier: data.useHash,
    // ...(data.awaitingCases?.length && {
    //   uuids: data.awaitingCases.map((item) => item.uuid),
    // }),
    // dermatologist_annotation: {
    //   isSending: data.notify,
    //   text: '' /* , notify_date: data.notifyDate */,
    // },
    // dermatologist_diagnosis: diagnoses,
    // prescriptions: prescriptions?.length ? prescriptions : null,
    // referrals: referrals?.length ? referrals : null,
    // notify: data.notify,
    // treatment_plan: treatmentPlan,
    ...skinType,
    ...freeTextAnnotation,
    // treatment_effect_reminder: data.notifyDate,
  };
};
