import { fork, put, takeLatest, call, delay } from 'redux-saga/effects';
import {
    AUTH_ACTIONS_MAP,
    validateReceivedRegistrationParametersStart,
    validateReceivedRegistrationParametersSuccess,
    validateReceivedRegistrationParametersFail,
    savePhysicianPasswordStart,
    savePhysicianPasswordSuccess,
    savePhysicianPasswordFail,
    savePhysicianPasswordInvalid,
    RESET_PASSWORD_ACTIONS,
    setCSRFToken,
    createNewPasswordStart,
    createNewPasswordSuccess,
    createNewPasswordFail,
    resetPasswordFail,
    resetPasswordStart,
    resetPasswordSuccess,
} from '../../actions/auth.actions';
import { AUTHENTICATED_RESPONSE, INVALID_PASSWORD } from '../../components/Login/constants';
import { validateCode, savePhysicianPassword } from '../../services/auth.service';
import { createNewPhysicianPassword, resetPhysicianPassword } from '../../services/auth.service';
import { generateUrlParams } from '../../sagas/helper';
import * as authService from '../../services/auth.service';

export const SYSTEM_REGISTRATION_RESPONSE = Object.freeze({
    codeAuthorized: 'CODE_AUTHORIZED',
    codeNotAuthorized: 'CODE_NOT_AUTHORIZED',
});

function* registrationValidationParametersWORK(action) {
    try {
        const { email, code } = action.payload || {};
        yield put(validateReceivedRegistrationParametersStart())
        const authentication = yield call(validateCode, email, code);
        if (authentication === SYSTEM_REGISTRATION_RESPONSE.codeAuthorized) {
            yield put(validateReceivedRegistrationParametersSuccess())
        } else {
            yield put(validateReceivedRegistrationParametersFail(new Error('anuthorized')));
        }
    } catch(e) {
        yield put(validateReceivedRegistrationParametersFail(e));
    }
}

function* saveRegistrationAssignPasswordWORK(action) {
    const { email, password, history } = action.payload || {};
    try {
        yield put(savePhysicianPasswordStart());
        const authentication = yield call(savePhysicianPassword, email, password);
        if(INVALID_PASSWORD === authentication || INVALID_PASSWORD === authentication.status) {
            yield put(savePhysicianPasswordInvalid())
        } else if (authentication === AUTHENTICATED_RESPONSE || authentication.status === AUTHENTICATED_RESPONSE) {
            yield put(savePhysicianPasswordSuccess());
            history.push(generateUrlParams(`/login?email=${email}&password=${password}`));
        } else {
            yield put(savePhysicianPasswordFail(new Error(authentication)));
            history.push(generateUrlParams(`/login`));
        }
    } catch(e) {
        yield put(savePhysicianPasswordFail(e));
        history.push(generateUrlParams(`/login`));
    }
}

function* createNewPasswordProcessWORK(action) {
    try {
      const { validation_code, new_password, history } = action.payload;
      yield put(createNewPasswordStart());
      const response = yield call(createNewPhysicianPassword, { validation_code, new_password });
      if (response.success) {
          history.push(generateUrlParams('/user-management-validate'));
      }
      yield put(createNewPasswordSuccess(response));
    } catch(e) {
      yield put(createNewPasswordFail(e.response.data));
    }
}

function* resetPasswordProcessWORK(action) {
    try {
      const { email, history } = action.payload;
      yield put(resetPasswordStart());
      const actionResponse = yield call(resetPhysicianPassword, email);
      yield put(resetPasswordSuccess(actionResponse));
      if (actionResponse === RESET_PASSWORD_ACTIONS.SENT_CONFIRMED && history) {
          yield delay(10000);
          history.push(generateUrlParams('/login'))
      }
    } catch(e) {
      yield put(resetPasswordFail(e));
    }
}

function* updateCSRFToken(action) {
    const csrfToken = yield call(authService.getCSRFToken);
    authService.setAxiosCSRFToken(csrfToken.csrfToken);
    yield put(setCSRFToken(csrfToken.csrfToken))
}

function* removeCSRFToken(action) {
    authService.setAxiosCSRFToken('');
    yield put(setCSRFToken(''))
}

export function* watchUpdateCSRFToken() {
    yield takeLatest(AUTH_ACTIONS_MAP.updateCSRFToken, updateCSRFToken);
}

export function* watchRemoveCSRFToken() {
    yield takeLatest(AUTH_ACTIONS_MAP.removeCSRFToken, removeCSRFToken);
}

export function* watchSaveRegistrationAssignPassword() {
    yield takeLatest(AUTH_ACTIONS_MAP.savePhysicianPassword, saveRegistrationAssignPasswordWORK);
}

export function* watchRegistrationValidationParameters() {
    yield takeLatest(AUTH_ACTIONS_MAP.isReceivedRegistrationParametersValid, registrationValidationParametersWORK);
}

function* watchCreateNewPasswordProcess() {
    yield takeLatest(AUTH_ACTIONS_MAP.createNewPasswordRequest, createNewPasswordProcessWORK);
}

function* watchResetPasswordProcess() {
    yield takeLatest(AUTH_ACTIONS_MAP.resetPasswordRequest, resetPasswordProcessWORK);
}

// function*

// function* watchLoginProcess() {
//     yield takeLatest(AUTH_ACTIONS_MAP.doLoginProcess, loginProcessWORK);
// }

const authSagas = [
    // fork(watchLoginProcess),
    fork(watchCreateNewPasswordProcess),
    fork(watchResetPasswordProcess),
    fork(watchRegistrationValidationParameters),
    fork(watchSaveRegistrationAssignPassword),
    fork(watchUpdateCSRFToken),
    fork(watchRemoveCSRFToken),
];

export default authSagas;