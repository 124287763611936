import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { loadingGlobalProcess } from '../../selectors/settings.selector';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const LoadingBlurredScreen = () => {
    const classes = useStyles();
    const isGlobalLoading = useSelector(loadingGlobalProcess);
    return (
        <div>
            <Backdrop className={classes.backdrop} open={isGlobalLoading}>
                <CircularProgress size="15rem" color="primary" />
            </Backdrop>
        </div>
    );
}

export default LoadingBlurredScreen;