import { all, call, put, fork, select, takeLatest } from "@redux-saga/core/effects";
import {
  PATIENT_DETAILS_ACTIONS,
  createTreatmentPlanSuccess, createTreatmentPlanFail
} from "../../actions/patient.details.actions";
import { copyIdToClipboard } from "../../components/CaseEdit/helpers/generator.helper";
import { getUserIdentifier } from "../../selectors/patient.details.selector";
import { createTreatmentPlanV3, loadBlob } from '../../services/main.service';
import { initCasesBatch } from './../../actions/cases.batch.actions';

export function* copyPatientId() {
  let userIdentifier = yield select(getUserIdentifier)
  yield copyIdToClipboard(userIdentifier);
}
export const blobToBase64 = (blob) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = function () {
    var dataUrl = reader.result;
    var base64 = dataUrl.split(',')[1];
    resolve(base64);
  };
  reader.readAsDataURL(blob);
});

export function* createTreatmentPlanWORK({ payload }) {
  try {
    // const { prescriptionsBlob, referralsBlob, treatmentPlanBlob, caseDetailsDiagnosisBlob } = yield all({
    //   ...(payload.form.prescriptions_pdf && { prescriptionsBlob: call(loadBlob, payload.form.prescriptions_pdf), }),
    //   ...(payload.form.referrals_pdf && { referralsBlob: call(loadBlob, payload.form.referrals_pdf), }),
    //   ...(payload.form.treatment_plan_pdf && { treatmentPlanBlob: call(loadBlob, payload.form.treatment_plan_pdf), }),
    //   ...(payload.form.case_details_diagnosis_pdf && { caseDetailsDiagnosisBlob: call(loadBlob, payload.form.case_details_diagnosis_pdf), }),
    // });

    // const { prescriptions_pdf, referrals_pdf, treatment_plan_pdf, case_details_diagnosis_pdf } = yield all({
    //   ...(prescriptionsBlob && { prescriptions_pdf: blobToBase64(prescriptionsBlob) }),
    //   ...(referralsBlob && { referrals_pdf: blobToBase64(referralsBlob) }),
    //   ...(treatmentPlanBlob && { treatment_plan_pdf: blobToBase64(treatmentPlanBlob) }),
    //   ...(caseDetailsDiagnosisBlob && { case_details_diagnosis_pdf: blobToBase64(caseDetailsDiagnosisBlob) }),
    // });
    // payload.form.prescriptions_pdf = prescriptions_pdf;
    // payload.form.referrals_pdf = referrals_pdf;
    // payload.form.treatment_plan_pdf = treatment_plan_pdf;
    // payload.form.case_details_diagnosis_pdf = case_details_diagnosis_pdf;
    payload.status = payload?.status || 3;

    const response = yield call(createTreatmentPlanV3, payload);
    if (response?.data === 'success') {
      let refreshedCurrentCasesArray = payload.currentCasesArray.map((i) => {
         if (i.uuid === payload.caseId && [1, 2].includes(i.status)) i.status = payload.status;
         return i;
       });
      if (refreshedCurrentCasesArray.every((i) => [3, 9, 33, 46, 90, 99].includes(i.status))) {
        refreshedCurrentCasesArray = refreshedCurrentCasesArray.map((i) => ({ ...i, status: i.status === 3 ? 33 : i.status }));
        const newCurrentCasesArrayFiltering = refreshedCurrentCasesArray.filter(({ status }) => 33 === status);
        if (newCurrentCasesArrayFiltering?.length) yield all(newCurrentCasesArrayFiltering.map((i) => call(createTreatmentPlanV3, ({ caseId: i.uuid, form: { notify: true }, status: i.status }))));
        yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload: refreshedCurrentCasesArray });
      };
      yield put(initCasesBatch(refreshedCurrentCasesArray));
      payload.status = 33;
      yield put(createTreatmentPlanSuccess(response));
      yield put({type: 'GET_ANNOTATIONS', payload});
      console.log('GET_ANNOTATIONS success', payload)
    } else yield put(createTreatmentPlanFail(response?.err || 'error'));
  } catch (error) {
    console.log('createTreatmentPlanWORK error = ', error);
    yield put(createTreatmentPlanFail(error?.message));
  }
}

export function* watchCopyPatientId() {
  yield takeLatest(PATIENT_DETAILS_ACTIONS.copyPatientId, copyPatientId);
}

export function* watchCreateTreatmentPlan() {
  yield takeLatest(PATIENT_DETAILS_ACTIONS.CREATE_TREATMENT_PLAN_START, createTreatmentPlanWORK)
}

export const patientDetailsSagas = [
  fork(watchCopyPatientId),
  fork(watchCreateTreatmentPlan),
];
