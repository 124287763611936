import axios from 'axios';
import { axiosInstance } from '../core/axios';

//axios.defaults.baseURL = 'http://localhost:3434';

const CancelToken = axios.CancelToken;

export const ANNOTATION_RESPONSE_TYPES = Object.freeze({
  success: 'success',
  blocked_case: 'blocked_case',
  error: 'error',
  last_case: 'last_case',
});

export const ANNOTATION_NOTIFICATION_STATUSES = Object.freeze({
  FAILED_TO_SEND: 'FAILED_TO_SEND',
  SUCCEEDED_TO_SEND: 'SUCCEEDED_TO_SEND',
});

export async function switchSMSNotification(data) {
  if (!data || !data.physician || !data.adminSettings) {
    return false;
  }
  const res = await axiosInstance.patch(`/api/doctor/update/admin-settings`, data);
  const status = res.data;
  return status;
}

export async function notifyPatient (data) {
  if (!data || !data.uuid || !data.modified_by) {
    return ANNOTATION_NOTIFICATION_STATUSES.FAILED_TO_SEND;
  }
  const res = await axiosInstance.post(`/api/annotations/notify`, data);
  const status = res.data;
  return status;
}

export const getDoctorDetails = async (email) => {
  try {
    const doctorDetails = await axiosInstance.post(`api/doctor/details`, { email });
    return doctorDetails.data;
  } catch (e) {
    console.log(e);
  }
}

export const updatePhysicianData = async (data) => {
  try {
    const { email, adminSettings, isRemoveVacation } = data;
    const res = await axiosInstance.patch( `/api/doctor/update/admin-settings`,
      {
        physician: email,
        adminSettings,
        isRemoveVacation,
      }
    );
    return res.data;
  } catch (e) {
    console.log('Error update patients details: \n', e);
    return null;
  }
}

export const updatePhysicianDetails = async (data) => {
  try {
    const { adminSettings } = data;
    const res = await axiosInstance.patch( `/api/doctor/admin-settings-v2`, { adminSettings });
    return res.data;
  } catch (e) {
    console.log('Error update physician details: \n', e);
    return null;
  }
}

export const savePhysicianImage = async ({file, type}) => {
  try {
    const res = await axiosInstance.post( `/api/doctor/picture/${type}`, file );
    return res.data;
  } catch (e) {
    console.log('Error save physician stamp: \n', e);
    return null;
  }
}

export const getPhysicianImage = async (fileName) => {
  console.log(fileName);
  const res = await axiosInstance.get(`/api/annotations/document/${fileName}`, { timeout: 5000, responseType: 'blob' });
  const blobLink = URL.createObjectURL(res.data);
  return blobLink;
}

export const updatePhysicianActivity = async (data) => {
  try {
    const res = await axiosInstance.patch( `/api/doctor/update/activity`, data);
    return res.data;
  } catch (e) {
    console.log('Error update physician activity: \n', e);
    return null;
  }
}


export async function getAvailableCase(data) {
  try {
    const res = await axiosInstance.patch(`/api/request/availability`, data);
    const availableCase = res.data;
    return availableCase;
  } catch(err) {
    const {data = {}} = (err && err.response) || {};
    if (data.statusCode === 401) {
      return null;
    } else if (data.statusCode === 403) {
      return 'OTHER_PRIVATE_POOL';
    } else {

      console.log('Error fetch available cases: \n', err);
      return null;
    }
  }
}

export async function getPatientDetails(id) {
  try {
    const res = await axiosInstance.get(`/api/requests/${id}`, {
      timeout: 600000
    });
    const details = res.data;
    if (res.data?.status === 403) {
      return 'OTHER_PRIVATE_POOL';
    }
    return details;
  } catch(err) {
    const {data = {}} = (err && err.response) || {};
    if (data.statusCode === 401) {
      return null;
    } else if (data.statusCode === 403) {
      return 'OTHER_PRIVATE_POOL';
    } else {
      console.log('Error update patients details: \n', err);
      throw new Error('request failed');
    }
  }
}

async function getPatientDetailsTranslated(id, lang) {
  try {
    const res = await axiosInstance.get(`/api/requests/translated/${id}/${lang}`, {
      timeout: 600000
    });
    const details = res.data;
    if (res.data?.status === 403) {
      return 'OTHER_PRIVATE_POOL';
    }
    return details;
  } catch (err) {
    const {data = {}} = (err && err.response) || {};
    if (data.statusCode === 401) {
      return null;
    } else if (data.statusCode === 403) {
      return 'OTHER_PRIVATE_POOL';
    } else {
      console.log('Error update patients details: \n', err);
      throw new Error('request failed');
    }
  }
}


let cancelGetPatientsRequest;
export async function getPatients(params) {
  let res = {};
  console.log('Inside getPatients service', params)

  try {
    if(cancelGetPatientsRequest) {
      cancelGetPatientsRequest()
    }
    res = await axiosInstance.post(`/api/requests`, params, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetPatientsRequest = c;
      })
    });
    return res.data;
  } catch(err) {
    console.log('Error getting customers from API:', err.response.status, err);
    const {data = {}} = (err && err.response) || {};
    if (data.statusCode === 401) {
      return null;
    } else if (data.statusCode === 403) {
      return 'OTHER_PRIVATE_POOL';
    } else {

      return [];
    }
  }
}

export async function getHistory(user_hash) {
  try {
    const res = await axiosInstance.get(`/api/history/${user_hash}`);
    return res.data;
  } catch(err) {
    console.log('err: ===> ', err);
    const {data = {}} = (err && err.response) || {};
    if (data.statusCode === 401) {
      return null;
    }

    console.log('Error get history: \n', err);
    return [];
  }
}

export async function postAnnotationsByUUIDs(uuids) {
  try {
    const res = await axiosInstance.post(`/api/annotations`, { uuids });
    return res.data;
  } catch(err) {

    console.log(err);
    return null;
  }
}

export async function postDiagnosis(data) {
  try {
    const { modified_by, uuid, status, notify = false } = data;
    const res = await axiosInstance.post(`/api/requests/post-diagnosis/${uuid}`, { modified_by, notify, status });
    console.log('res.data', res.data)
    return res.data;
  } catch(err) {

    console.log(err);
    return null;
  }
}

export async function getAnnotation(id) {
  try {
    const res = await axiosInstance.get(`/api/annotations/${id}`);
    console.log('meir',res.data )
    return res.data;
  } catch(err) {

    console.log(err);
    return null;
  }
}

export async function updateAnnotation(id, data) {
  try {
    const res = await axiosInstance.put(`/api/annotations/${id}`, data);
    // console.log('Response:', res);
    return res
  } catch(err) {

    console.log('Error ', err);
  }
}

export async function addAnnotation(id, data) {
  try {
    const res = await axiosInstance.post(`/api/annotations/${id}`, data);
    // console.log('Response:', res);
    // return {err: null, data: res};
    return res.data;
  } catch (err) {

    console.log(err);
    return { data: null, err: err }
  }
}

export async function cancelAnnotation(id, uuid) {
  try {
    const res = await axiosInstance.put(`/api/annotations/${id}/${uuid}`);
    if (res) {
      return res.data;
    } else {
      return {err: null, data: res};
    }
  } catch (err) {

    console.log(err);
    return {err: err, data: null}
  }
}

export async function isServerHealth() {
  const res = await axios.get(`/healthy`);
  return res.status === 200 ? true : false;
}

export async function checkBatchNewCases(data) {
  const res = await axiosInstance.post(`/api/check-case-batch`, data);
  return res ? res.data : [];
}

export async function assignCasesBatch(case_uuids) {
  const res = await axiosInstance.patch(`/api/assign-requests-batch`, {case_uuids});
  return res ? res.data : [];
}

export async function getCasesBatchByUUID(uuid) {
  const res = await axiosInstance.get(`/api/cases-batch/${uuid}`);
  return res ? res.data : [];
}

export async function changeStatus(uuid, payload) {
  // console.log('change status: ', uuid, payload)
  try {
    await axiosInstance.post(`/api/requests/status/${uuid}`, payload)
    // console.log(res)
    return true
  } catch (err) {

    console.log('DD Error: \n', err)
    return false
  }
}

export async function changeLang(payload) {
  try {
    await axiosInstance.post('/api/set-language', payload )
  } catch (e) {

    console.log(e)
  }
}

export async function getDiseases(vendor) {
  try {
    const res = await axiosInstance.get(`/api/diseases/${vendor}`);
    return res.data;
  } catch(err) {
    if (err.response.status === 401) {
      return null;
    }

    console.log('Error update patients details: \n', err);
    return [];
  }
}

export async function getTemplatesByDiseasesId(disease_id) {
  try {
    const res = await axiosInstance.get(`/api/templates/${disease_id}`);
    return res.data || [];
  } catch(err) {
    if (err.response.status === 401) {
      return [];
    }

    console.log('Error:  get templates: \n', err);
    return [];
  }
}

async function getTemplates(user) {
  const url = user ? `/api/templates/${user}` : `/api/templates`
  try {
    const res = await axiosInstance.get(url);
    return res.data || []
  } catch(e) {
    console.log(e)
    return []
  }
}

export async function addTemplate(data) {
  try {
    const res = await axiosInstance.post(`/api/templates`, data);
    return res.data.data[0];
  } catch (err) {

    console.log(err);
    return {err: err, data: null}
  }
}

export async function editTemplate(payload) {

  try {
    const res = await axiosInstance.patch(`/api/templates/${payload.id}`, payload);
    return res.data.data[0];
  } catch (err) {

    console.log(err);
    return {err: err, data: null}
  }
}

export async function deleteTemplate(id) {
  try {
    const res = await axiosInstance.delete(`/api/templates/${id}`);
    return res.data[0];
  } catch (err) {

    console.log(err);
    return {err: err, data: null}
  }
}

export async function getGroups() {
  try {
    const res = await axiosInstance.get(`/api/groups`);
    return res.data;
  } catch(err) {
    if (err.response.status === 401) {
      return null;
    }

    console.log('Error get groups: \n', err);
    return [];
  }
}

export async function addGroup(data) {
  try {
    const res = await axiosInstance.post(`/api/groups`, data);
    return res.data.data[0];
  } catch (err) {

    console.log(err);
    return {err: err, data: null}
  }
}

export async function editGroup(id, data) {
  try {
    const res = await axiosInstance.patch(`/api/groups/${id}`, data);
    return res.data.data[0];
  } catch (err) {

    console.log(err);
    return {err: err, data: null}
  }
}

export async function deleteGroup(id) {
  try {
    const res = await axiosInstance.delete(`/api/groups/${id}`);
    return res.data[0];
  } catch (err) {

    console.log(err);
    return {err: err, data: null}
  }
}

export async function nextAvailableCase(physician) {

  try {
    const res = await axiosInstance.post(`/api/request/next-case/${physician}`, {}, {
      timeout: 600000
    });
    return res.data;
  } catch(e) {
    console.log(e);
    return '';
  }

}

export async function changePendingStatus ({ uuid, action, description, physician }) {
    const res = await axiosInstance.post(`/api/changes-pending-status/${uuid}`, { action, description, physician });
    return res.data;
}

export async function getPendingDescriptions ({ uuid }) {
    const res = await axiosInstance.get(`/api/pending-descriptions/${uuid}`);
    return res.data;
}

export async function notifyPatientBatch(data) {
  const res = await axiosInstance.patch(`/api/notify-patient`, data);
  return res;
}

export async function subscriptionNACAnalytic(data) {
  const res = await axiosInstance.post(`/api/subscribe-nac-analytic`, data);
  return res ? res.data : null;
}

export async function treatmentPlan(diseaseType) {
  try {
    const res = await axiosInstance.get(`/api/case-metadata/treatment-plan?diagnosis_type=${diseaseType}`);
    return res ? res.data : null;
  } catch (e) {
    console.log(e);
  }
}


export async function loadBlob(blob) {
  try {
    const resp = await fetch(blob);
    const res = await resp.blob();
    return res;
  } catch (error) {
    console.log(error);
  }

}
export async function createTreatmentPlan(payload) {
  try {
    const res = await axiosInstance.post(`/api/annotations/v2/${payload.caseId}`, payload.form);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function createTreatmentPlanV3(payload) {
  //meir
  console.log(payload)
  console.log(payload.form)
  try {
    const res = await axiosInstance.post(`/api/annotations/v3/${payload.status}/${payload.caseId}`, payload.form);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
//meir
export async function submitAdditionalAnotations(fetchedCaseId , data) {
  console.log('inside submitAdditionalAnotations post: ')
  console.log(fetchedCaseId, data)
  try {
    const res = await axiosInstance.post(`/api/annotations/additionalAnnotations/${fetchedCaseId}`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getOncologistDetails (physician_code) {
  try {
    const res = await axiosInstance.get(`api/doctor/details/${physician_code}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export const getPdfPreviewUrl = async (uuid, body) => {
  const res = await axiosInstance.post(`api/requests/pdf_preview/${uuid}`, body, {responseType: 'blob'})
  const url = URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}))
  return url
}


export default {
  ANNOTATION_RESPONSE_TYPES,
  ANNOTATION_NOTIFICATION_STATUSES,
  switchSMSNotification,
  changeStatus,
  getHistory,
  getAvailableCase,
  getPatientDetails,
  getPatientDetailsTranslated,
  getDiseases,
  getTemplatesByDiseasesId,
  addTemplate,
  editTemplate,
  deleteTemplate,
  getGroups,
  editGroup,
  treatmentPlan,
  deleteGroup,
  addGroup,
  getAnnotation,
  postAnnotationsByUUIDs,
  postDiagnosis,
  addAnnotation,
  cancelAnnotation,
  changeLang,
  getTemplates,
  nextAvailableCase,
  notifyPatient,
  getDoctorDetails,
  updatePhysicianData,
  updatePhysicianDetails,
  savePhysicianImage,
  getPhysicianImage,
  checkBatchNewCases,
  assignCasesBatch,
  getCasesBatchByUUID,
  notifyPatientBatch,
  subscriptionNACAnalytic,
  updatePhysicianActivity,
  changePendingStatus,
  getPendingDescriptions,
  createTreatmentPlanV3,
  getOncologistDetails,
}
