import { fork, put, takeLatest, select, call} from 'redux-saga/effects';
import { CASES_BATCH_ACTIONS_MAP } from '../../actions/analytic.actions';
import { getCurrentPhycisianEmail } from '../../selectors/settings.selector';
import rest from '../../services/main.service'

function* doSubscribeNACAnalyticWORK(action) {
    try {
        yield put({ type: CASES_BATCH_ACTIONS_MAP.subscribeForNACAnalyticStart });
        const physician = yield select(getCurrentPhycisianEmail);
        const analytic = yield call(rest.subscriptionNACAnalytic, {
            ...action.payload,
            email: physician,
        });
        if (analytic) {
            yield put({
                type: CASES_BATCH_ACTIONS_MAP.subscribeForNACAnalyticSuccess,
                payload: analytic, 
            });
        }
    } catch (e) {
        console.log('doSubscribeNACAnalyticWORK -> failed.');
        yield put({ type: CASES_BATCH_ACTIONS_MAP.subscribeForNACAnalyticFail });
    }
}

function* dounsubscribeNACAnalyticWORK(action) {
    try {
        yield put({ type: CASES_BATCH_ACTIONS_MAP.unsubscribeForNACAnalyticStart });
        const physician = yield select(getCurrentPhycisianEmail);
        yield call(rest.subscriptionNACAnalytic, {
            ...action.payload,
            email: physician,
        });
        yield put({ type: CASES_BATCH_ACTIONS_MAP.unsubscribeForNACAnalyticSuccess });
    } catch (e) {
        console.log('dounsubscribeNACAnalyticWORK -> failed.');
        yield put({ type: CASES_BATCH_ACTIONS_MAP.unsubscribeForNACAnalyticFail });
    }
}

function* watchSubscriptionNACAnalytic() {
  yield takeLatest(CASES_BATCH_ACTIONS_MAP.subscribeForNACAnalyticRequest, doSubscribeNACAnalyticWORK);
}

function* watchUnsubscriptionNACAnalytic() {
    yield takeLatest(CASES_BATCH_ACTIONS_MAP.unsubscribeForNACAnalyticRequest, dounsubscribeNACAnalyticWORK);
}

const analyticSagas = [
    fork(watchSubscriptionNACAnalytic),
    fork(watchUnsubscriptionNACAnalytic),
];

export default analyticSagas;