import { EDoctorPermissions } from '../reducers/settingReducer';
import packageJson from '../../package.json';

export const AllowedStatusChangePermissions = Object.freeze({
  user: EDoctorPermissions.user,
  superuser: EDoctorPermissions.superuser
});

export const ENVIRONMENTS = Object.freeze({
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
  LOCAL: 'local',
});

export const isStatusChangeAllowed = (permission) => {
  if (!permission) {
    return false;
  }
  return !!AllowedStatusChangePermissions[permission];
}

const DESCRIPTION_DELIMETER = '&&';
const UPDATE_BY_INDEX = 0;
const CREATED_AT_INDEX = 1;
const TEXT_INDEX = 2;
export const ANALYTICS_DATE_LOCALSTORAGE_KEY = 'analyticsData';
export const transformPendingDescription = (descArray) => {
  if (!descArray || !Array.isArray(descArray) || !descArray.length) {
    return null;
  }
  return descArray.reduce((acc, dStr) => {
    const splitedArray = !!dStr ? `${dStr}`.split(DESCRIPTION_DELIMETER) : null;
    if (splitedArray) {
      acc = acc.concat({
        physician: splitedArray[UPDATE_BY_INDEX],
        date: splitedArray[CREATED_AT_INDEX],
        description: splitedArray[TEXT_INDEX],
      });
    }
    return acc;
  }, []);
}

export const casesArrayToString = (currentCasesArray) => {
  return currentCasesArray.map(function(elem){
    return elem.caseId;
  }).join(", ")
}

export const PRIMARY_INDEX_ARRAY = [0,1,2,3,4,5];
// export const EXCLUDED_INDEX_ARRAY = [3,4,5];
export const PRIMARY_PROPERTY_NAME = 'primary';
export const EXCLUDED_PROPERTY_NAME = 'excluded';

export const transformMLDiagnosisProbability = (probabilityArray) => {
  if (!probabilityArray || (Array.isArray(probabilityArray) && !probabilityArray.length )) {
    return null;
  }
  const diagnosisProbabilityObject = {
    [PRIMARY_PROPERTY_NAME]: [],
    [EXCLUDED_PROPERTY_NAME]: [],
  };
  diagnosisProbabilityObject[PRIMARY_PROPERTY_NAME] = probabilityArray;

  // PRIMARY_INDEX_ARRAY.forEach(i => {
  //   if (probabilityArray[i]) {
  //     diagnosisProbabilityObject[PRIMARY_PROPERTY_NAME].push(probabilityArray[i])
  //   }
  // });

  // EXCLUDED_INDEX_ARRAY.forEach(i => {
  //   if (probabilityArray[i]) {
  //     diagnosisProbabilityObject[EXCLUDED_PROPERTY_NAME].push(probabilityArray[i])
  //   }
  // });

  return diagnosisProbabilityObject;
}

export const updateAnalyticData = (data) => {
  let analyticsData;
  try {
    analyticsData = JSON.parse(localStorage.getItem(ANALYTICS_DATE_LOCALSTORAGE_KEY));
  } catch(e) {
    console.error('Failed update analytic data');
    return;
  }

  if(!analyticsData || typeof analyticsData != 'object') {
    analyticsData = {
      stacktrace: '',
      version: packageJson.version,
      physician: '',
      status: '',
    };
  }


  if(data.physician) {
    analyticsData.physician = data.physician
  }
  if(data.status) {
    analyticsData.status = data.status
  }

  localStorage.setItem(ANALYTICS_DATE_LOCALSTORAGE_KEY, JSON.stringify(analyticsData));
}

export function generateUrlParams(url, message = '') {
  let newUrl = url;

  if(message) {
      if(url.indexOf('?') !== -1) {
        newUrl = `${url}&message=${message}`;
      } else {
        newUrl = `${url}?message=${message}`;
      }
  }

  return newUrl
}