import React, { memo } from 'react';
import { useStyles } from './styles';
import { AuthBar } from '../AuthBar/AuthBar';

export const HeaderCasesCommon = memo(() => {
  const s = useStyles();

  return (
    <div className={s.headerCases}>
      <div className={s.casesEnd}>
        <AuthBar />
      </div>
    </div>
  );
});