
export const SISEASES_ACTIONS_MAP = Object.freeze({
    LOAD_DISEASES_REQUEST: 'LOAD_DISEASES',
    LOAD_DISEASES_REQUEST_SUCESS: 'LOAD_DISEASES_SUCCESS',
    LOAD_DISEASES_REQUEST_START: 'LOAD_DISEASES_START',
    LOAD_DISEASES_REQUEST_FAIL: 'LOAD_DISEASES_FAIL',
    SET_CURRENT_DISEASE_FOCUSED: 'SET_CURRENT_DISEASE_FOCUSED',
    CLEAR_DISEASE: 'CLEAR_DISEASE',
    SET_CURRENT_DISEASE: 'SET_CURRENT_DISEASE',
    CLEAR_DISEASE_REQUEST: 'CLEAR_DISEASE_REQUEST',
    RESET_DISEASES: 'RESET_DISEASES',
    INIT_DIAGNOSIS: 'INIT_DIAGNOSIS',
    SET_RUNTIME_VALUE_DIAGNOSE: 'SET_RUNTIME_VALUE_DIAGNOSE',
    SET_LIST_MODE_BY_NAME: 'SET_LIST_MODE_BY_NAME',
    SET_INNER_COMMENT: 'SET_INNER_COMMENT',
    SET_INNER_CHAR1: 'SET_INNER_CHAR1',
    SET_INNER_CHAR2: 'SET_INNER_CHAR2',
    SET_GLOBAL_COMMENT: 'SET_GLOBAL_COMMENT',
});

export const DIAGNOSE_BLOCK_NAMES = Object.freeze({
    FIRST_DESEASE: 'FIRST_DESEASE',
    SECOND_DESEASE: 'SECOND_DESEASE',
    THIRD_DESEASE: 'THIRD_DESEASE',
});

export const DISEASES_SELECTOR_MODE = Object.freeze({
    FULL_LIST: 'FULL_LIST',
    SHORT_LIST: 'SHORT_LIST',
});

export const setGlobalComment = (porps) => {
    const { text, isSending = true } = porps || {};
    return {
        type: SISEASES_ACTIONS_MAP.SET_GLOBAL_COMMENT,
        payload: { text, isSending },
    }
}

export const setListModeByName = (props) => {
    const { host = `${window.location.pathname}`, name, mode } = props || {};
    if (!mode) {
        return {
            type: SISEASES_ACTIONS_MAP.SET_LIST_MODE_BY_NAME,
            payload: { host, name, value: DISEASES_SELECTOR_MODE.FULL_LIST }
        }
    }
    const nValue = (mode === DISEASES_SELECTOR_MODE.FULL_LIST) ? DISEASES_SELECTOR_MODE.SHORT_LIST : DISEASES_SELECTOR_MODE.FULL_LIST;
    return {
        type: SISEASES_ACTIONS_MAP.SET_LIST_MODE_BY_NAME,
        payload: { host, name, value: nValue }
    }
}

export const setRuntimeDiagnoseValue = (props) => {
    const { host = `${window.location.pathname}`, name, value } = props || {};
    return {
        type: SISEASES_ACTIONS_MAP.SET_RUNTIME_VALUE_DIAGNOSE,
        payload: { host, name, value }
    }
}

export const clearDiagnoseSelected = (props) => {
    const { host = `${window.location.pathname}`, name } = props || {};
    return {
        type: SISEASES_ACTIONS_MAP.CLEAR_DISEASE_REQUEST,
        payload: { host, name },
    }
}

export const setDiagnoseSelected = (props) => {
    const { host = `${window.location.pathname}`, name, diagnose } = props || {};
    return {
        type: SISEASES_ACTIONS_MAP.SET_CURRENT_DISEASE,
        payload: { host, name, diagnose },
    }
}

export const setFucusedDiagnoseSelector = (props) => {
    const { host = `${window.location.pathname}`, name } = props || {};
    return {
        type: SISEASES_ACTIONS_MAP.SET_CURRENT_DISEASE_FOCUSED,
        payload: { host, name },
    }
}

export const getLoadDiseasesRequest = (payload) => {
    return {
        type: SISEASES_ACTIONS_MAP.LOAD_DISEASES_REQUEST,
        payload: payload
    }
}

export const getLoadDiseasesSuccess = (diseases) => {
    return {
        type: SISEASES_ACTIONS_MAP.LOAD_DISEASES_REQUEST_SUCESS,
        payload: { diseases },
    }
}

export const getLoadDiseasesStarted = () => {
    return {
        type: SISEASES_ACTIONS_MAP.LOAD_DISEASES_REQUEST_START,
    }
}

export const getLoadDiseasesFailed = (error) => {
    return {
        type: SISEASES_ACTIONS_MAP.LOAD_DISEASES_REQUEST_FAIL,
        payload: { error }
    }
}


