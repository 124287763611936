import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIDPService } from '../../selectors/auth.selector';
import { Container, Typography, CssBaseline, makeStyles, Box } from '@material-ui/core';
import classnames from 'classnames';
import CustomInput from '../shared/CustomInput';
import LoginHeader from '../shared/LoginHeader';
import * as R from 'ramda';
import * as serverAuth from '../../services/auth.service';
import WebSocketService from '../../services/WebSocketService';
import { apiFullURL } from '../../core/axios';
import { useDispatch } from 'react-redux';
import { changeLng } from '../../reducers/settingReducer';
import { SYSTEM_ALLOWED_NOTIFICATIONS, addNotificationAction } from '../../actions/notification.actions';
import { setAdminSettings } from '../../actions/admin.actions';
import { DEFAULT_VACATION } from '../../reducers/adminReducer';
import Copyright from '../shared/CopyRight';
import { useLocation } from 'react-router-dom';
import { generateUrlParams } from '../../sagas/helper';
import { updateCSRFTokenAction } from '../../actions/auth.actions';
import CustomButton from './../shared/CustomButton/index';
import {
  AUTHENTICATED_RESPONSE,
  NOT_AUTHENTICATED_RESPONSE,
  BLOCKED_NOT_ACTIVE,
  BLOCKED_ON_VACATION,
  TOO_MANY_REQUESTS_RESPONSE,
} from './constants';
import { HeaderCommon } from '../Header/HeaderCommon';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '550px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    maxWidth: '362px', // Fix IE 11 issue.
    '& fieldset': {
      border: 'none',
    },
    '& p.MuiFormHelperText-root': {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  input: {
    marginTop: '40px',
    '& input': {
      height: '24px',
      paddingBottom: '8px',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#272733',
    },
    '& input:-webkit-autofill, & input:-webkit-autofill:focus, & input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 30px white inset',
    },
    '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
      borderBottom: '2px solid #B0EEEF !important',
    },

    '& input::placeholder': {
      color: '#858585',
    },
    '& p.MuiFormHelperText-root': {
      marginTop: '8px',
      fontSize: '12px',
      lineHeight: '17.63px',
    },
  },
  submit: {
    marginTop: theme.spacing(5),
    width: '81px',
    textTransform: 'none',

    '& > span.MuiButton-label': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#fff',
    },
  },
  invalidMessage: {
    color: 'red',
  },
  copyright: {
    position: 'absolute',
    bottom: '76px',
    left: 'calc(849px - 0.5*(1920px - 100vw))',

    '& > p': {
      fontSize: '12px',
      lineHeight: '17.63px',
      fontWeight: '400',
    },
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

let isIDPServiceUpdated = false;

const validateInput = (type, value) => {
  switch (type) {
    case 'email':
      return EMAIL_REGEX.test(value);
    case 'password':
      return !/\s/.test(value) && value.length >= 3;
    default: {
      return false;
    }
  }
};

const fnOnBlur = (isDirty, setIsDirty, type) => {
  !isDirty && setIsDirty((prevState) => ({ ...prevState, [type]: !isDirty }));
};

const LoginPage = (props) => {
  const validate = (err) => {
    if (!err) {
      return;
    }

    let messageLocation = 'loginModal.error';
    switch (err) {
      case BLOCKED_NOT_ACTIVE:
        messageLocation = 'loginModal.physicianBlocked';
        break;
      case BLOCKED_ON_VACATION:
        messageLocation = 'loginModal.physicianVacation';
        break;
      case TOO_MANY_REQUESTS_RESPONSE:
        messageLocation = 'loginModal.errorTooManyRequest';
        break;
      default:
        messageLocation = 'loginModal.error';
    }

    setInvalid(true);
    setPasswordInvalid(true);
    setInvalidMessage(t(messageLocation));
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const query = useQuery();

  const [user, setUser] = React.useState(query.get('email') || '');
  const [password, setPassword] = React.useState(query.get('password') || '');
  const [passwordInvalid, setPasswordInvalid] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [invalid, setInvalid] = React.useState(false);
  const [invalidMessage, setInvalidMessage] = React.useState(false);
  const [validInputs, setValidInputs] = React.useState({ email: false, password: false, all: false });
  const [isDirty, setIsDirty] = React.useState({ email: false, password: false });
  const IDPService = useSelector(getIDPService);

  React.useEffect(() => {
    dispatch(updateCSRFTokenAction());
  }, [passwordInvalid, error, invalid, invalidMessage, IDPService, dispatch]);

  React.useEffect(() => {
    if (email) props.handleJiraAfterLogin(email);
    // eslint-disable-next-line
  }, [email]);

  React.useEffect(() => {
    document.body.style.backgroundColor = '#fff';
  }, []);

  React.useEffect(() => {
    validateInput();
  }, [password, user]);

  React.useEffect(() => {
    if (isIDPServiceUpdated) {
      return;
    }
    // dispatch({ type: SETTINGS_ACTION_MAP.loadingGlobalOff });
    serverAuth.getEnvSettings().then(async (envSettings) => {
      dispatch({ type: 'SET_ENV_SETTINGS', payload: envSettings });
      isIDPServiceUpdated = true;
    });
  }, [passwordInvalid, error, invalid, invalidMessage, IDPService, dispatch]);

  const isIdpServiceLocal = IDPService && IDPService === serverAuth.IDPServiceTypes.LOCAL;

  // const redirectAfterLogin = ({is_validation_code, is_validated, history}) => {
  //   dispatch({type: 'SET_ISVALIDATIONCODE', payload: is_validation_code})
  //   dispatch({type: 'SET_ISVALIDATED', payload: is_validated})
  //   console.log(' Login redirectAfterLogin is_validation_code', is_validation_code);
  //   console.log(' Login redirectAfterLogin is_validated', is_validated);
  //   if (is_validation_code) {
  //     history.push(generateUrlParams('/create-password'))
  //   } else {
  //     is_validated ? history.push(generateUrlParams('/patients')) : history.push(generateUrlParams('/user-management'));
  //   };
  // };

  const onLoginHandler = async () => {
    dispatch({ type: 'SET_START_LOGIN_PROCESS' });
    try {
      const userData = await serverAuth.login({ email: user, password: password });

      if (!isIdpServiceLocal) {
        window.location.href = generateUrlParams(userData.loginUrl);
        return;
      }

      if (userData && userData.status === AUTHENTICATED_RESPONSE) {
        if (!WebSocketService.socket) {
          WebSocketService.socket = apiFullURL;
        }

        if (userData?.doctor_email && userData?.permission === 'superuser') {
          setEmail(userData.doctor_email);
        }

        dispatch({ type: 'SET_ISVALIDATIONCODE', payload: userData.is_validation_code });
        dispatch({ type: 'SET_ISVALIDATED', payload: userData.is_validated });
        WebSocketService.dispatch = dispatch;
        WebSocketService.history = props.history;
        WebSocketService.fillPropertiesAfterConnection();
        WebSocketService.email = user;
        const language = userData.settings ? userData.settings.language : 'he';
        const newDirection = language === 'he' ? 'rtl' : 'ltr';
        const bodyElem = document.getElementById('lang_direction');
        bodyElem.setAttribute('dir', newDirection);
        localStorage.setItem('access_token', userData.token);

        dispatch({
          type: 'SET_USER',
          payload: {
            ...userData,
            user: userData.doctor_email,
            vendor: userData.vendor_id,
            permission: userData.permission,
            token: null,
          },
        });
        let isVacationSet = false;
        let vacation = DEFAULT_VACATION;
        if (userData.admin_settings && userData.admin_settings.vacation) {
          const defDate = new Date().toISOString();
          const { start_vacation = defDate, end_vacation = defDate } = userData.admin_settings.vacation || {};
          isVacationSet = true;
          vacation = [
            {
              startDate: new Date(start_vacation),
              endtDate: new Date(end_vacation),
            },
          ];
        }

        const employees = userData.roleMap ? Object.keys(userData.roleMap) : [];
        dispatch(
          setAdminSettings({
            adminSettings: userData.admin_settings,
            admin: userData.doctor_email,
            user: userData.doctor_email,
            is_active: userData.is_active,
            is_validated: userData.is_validated,
            employees: employees,
            permission: userData.permission,
            vacation,
            isVacationSet,
            roleMap: userData.roleMap,
          })
        );

        const payload = {
          action: SYSTEM_ALLOWED_NOTIFICATIONS.LOGIN_NOTIFICATION,
          props: {
            permission: userData.permission,
            user: userData.doctor_email,
            date: new Date(),
          },
        };
        dispatch(addNotificationAction(payload));
        dispatch({ type: 'SET_USER_SETTINGS', payload: userData.settings });
        const lng = R.propOr('he', 'language')(userData.settings);
        changeLng(lng);
        dispatch({ type: 'SET_USER_ROLES', payload: employees });
        dispatch({ type: 'LOAD_DISEASES', payload: userData.vendor_id });
        dispatch({ type: 'LOAD_TEMPLATES', payload: userData.doctor_email });
        // redirectAfterLogin({
        //   is_validation_code: userData.is_validation_code,
        //   is_validated: userData.is_validated,
        //   history: props.history
        // });
      } else {
        switch (userData && userData.status ? userData.status : userData) {
          case BLOCKED_NOT_ACTIVE:
            validate(BLOCKED_NOT_ACTIVE);
            break;
          case BLOCKED_ON_VACATION:
            validate(BLOCKED_ON_VACATION);
            break;
          case NOT_AUTHENTICATED_RESPONSE:
            validate({});
            break;
          default:
            validate(TOO_MANY_REQUESTS_RESPONSE);
            break;
        }
        setError('Authenticate error');
      }
    } catch (err) {
      validate(err);
      dispatch({ type: 'SET_END_LOGIN_PROCESS' });
    }
  };

  const onDirty = () => {
    setInvalid(false);
    setInvalidMessage('');
    setPasswordInvalid(false);
  };

  const onChangePassword = (event) => {
    const value = event.target.value;
    onDirty();
    setPassword(value);
    const isvalidPassword = validateInput('password', value);
    setValidInputs(({ email }) => ({ email, password: isvalidPassword, all: isvalidPassword && email }));
  };

  const onChangeUser = (event) => {
    const value = event.target.value;
    onDirty();
    setUser(value);
    const isvalidEmail = validateInput('email', value);
    setValidInputs(({ password }) => ({ password, email: isvalidEmail, all: isvalidEmail && password }));
  };

  const inputs = [
    {
      name: 'user',
      label: 'loginModal.user',
      type: 'email',
      onChangeHandler: onChangeUser,
      value: user,
      helperText: '*wrong e-mail address',
    },
    {
      name: 'password',
      label: 'loginModal.password',
      type: 'password',
      onChangeHandler: onChangePassword,
      value: password,
      helperText: '*wrong passwrod',
    },
  ];

  const titleKey = process.env.REACT_APP_PROJECT_NAME === 'myderma' ? 'loginModal.title' : 'loginModal.titleSkinTalks';

  return (
    <>
      <HeaderCommon />
      <Container component="main" maxWidth="xs" style={{ minWidth: '550px', paddingLeft: '0px', paddingRight: '0px' }}>
        <CssBaseline />
        <Box className={classes.paper}>
          <LoginHeader title={titleKey} /* subtitle={'loginModal.activeDirectoryDescription'} */ />

          <Box className={classes.form}>
            {isIdpServiceLocal &&
              inputs.map(({ name, label, type, onChangeHandler, value, helperText }) => (
                <CustomInput
                  autoFocus={name === 'user'}
                  key={name}
                  name={name}
                  label={label}
                  value={value}
                  type={type}
                  onChangeHandler={onChangeHandler}
                  onKeyPressHandler={validInputs.all ? onLoginHandler : () => {}}
                  helperText={isDirty[type] && !validInputs[type] && helperText}
                  className={classnames([classes.input])}
                  variant="standard"
                  error={isDirty[type] && !validInputs[type]}
                  onBlur={() => fnOnBlur(isDirty[type], setIsDirty, type)}
                  isNoLabel={true}
                />
              ))}

            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              // color="primary"
              disabled={!validInputs.all}
              className={classes.submit}
              onClick={onLoginHandler}
            >
              {t('loginModal.btnLogin')}
            </CustomButton>

            {invalid && (
              <Box mt={1}>
                <Typography variant="caption" className={classes.invalidMessage}>
                  {invalidMessage}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classnames(classes.copyright)}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default LoginPage;
