import { fork, put, takeLatest, select, call, delay } from 'redux-saga/effects';
import {
    ADMIN_ACTIONS_MAP, updateCurrentPhysician, updateCurrentPhysicianName,
    updateCurrentPhysicianPhone, updateCurrentPhysicianSignature, updateDoctorManagemtByPhysician ,
    savePhysicianInformationRequestStart, savePhysicianInformationSuccess, savePhysicianInformationFail,
    updateUserActivity, updateCurrentPhysicianVocation, setActivateVacation, saveInvitationEmailStart,
    saveInvitationEmailFail, saveInvitationEmailSuccess, setLoadingActDeact, resetAdminSettings, updateCurrentPhysicianAllNames, savePhysicianDetailsFail, savePhysicianDetailSuccess, savePhysicianStampSuccess, savePhysicianStampFail, getPhysicianStampSuccess, savePhysicianProfileImageSuccess, savePhysicianProfileImageFail, getPhysicianProfileImageSuccess, setIsSavePhysicianDerailsSuccess
} from '../../actions/admin.actions';
import { NOTIFICATION_ACTIONS, SYSTEM_ALLOWED_NOTIFICATIONS } from '../../actions/notification.actions';
import {
  getCurrentAdmin, getCurrentSignature, getCurrentUserPhone, getIsCurrentUserActive,
  getDoctorManagement, getCurrentUserPermission, getCurrentUser, getCurrentVacation, getInvitationEmail, getPhysicianPrefix, getUserNameInAllLanguages, getRoleMap, getCurrentStamp, getCurrentProfileImage
} from '../../selectors/admin.selector';
import { sendInvitation } from '../../services/auth.service';
import rest from '../../services/main.service'
import i18n from '../../i18n';
import { VENDORS_TYPES } from '../../components/helpers/groups';
import { languagesObject } from '../../selectors';
import { getCurrentPhycisianEmail, getCurrentPhycisianPermission, getLanguage } from '../../selectors/settings.selector';
import { generateUrlParams } from '../../sagas/helper';
import * as authService from '../../services/auth.service';
import { removeCSRFTokenAction } from '../../actions/auth.actions';

export const VACATION_REMOVE_TYPE = Object.freeze({
  removeVacation: 'REMOVE_VACATION',
  persistVacation: 'PERSIST_VACATION',
});

const l = (key, params) => i18n.t(key, params);

function* getDoctorDetailsWORK(action) {
    let { email } = action.payload || {};
    try {
      const docDetails = yield call(rest.getDoctorDetails, email);
      if (!docDetails ||
        (docDetails.vendor_id !== VENDORS_TYPES.maccabi)) {
          return;
      }
      const doctorManagement = yield select(getDoctorManagement);
      doctorManagement[email] = docDetails.admin_settings;
      const lang = yield select(getLanguage);

      if (docDetails.admin_settings) {
        const rec = ' ';
        const { phone = rec, signature, vacation = {} } = docDetails.admin_settings;

        if(!docDetails.admin_settings.name) {
          docDetails.admin_settings.name = '';
        }

        yield put(updateCurrentPhysicianAllNames(docDetails.admin_settings));

        yield put(updateCurrentPhysicianPhone(phone));
        yield put(updateCurrentPhysicianSignature(signature));
        const isVacation = vacation && vacation.start_vacation && vacation.end_vacation;
        const defDate = new Date().toISOString();
        if (isVacation) {
          yield put(setActivateVacation(true));
        } else {
          yield put(setActivateVacation(false));
        }
        yield put(updateCurrentPhysicianVocation([{
          startDate: isVacation ? new Date(vacation.start_vacation) : new Date(defDate),
          endDate: isVacation ? new Date(vacation.end_vacation) : new Date(defDate),
          key: 'selection',
        }]));

        yield put(savePhysicianInformationSuccess());
      } else {
        yield put(updateCurrentPhysicianName({name: '', lang: lang}));
        yield put(updateCurrentPhysicianPhone(' '));
        yield put(updateCurrentPhysicianSignature(''));

        yield put(savePhysicianInformationSuccess());
      }
      yield put(updateUserActivity(docDetails.is_active));
      yield put(updateCurrentPhysician(docDetails.doctor_email));
      yield put(updateDoctorManagemtByPhysician({...doctorManagement}));
      yield put(setLoadingActDeact(false));
    } catch (e) {
      console.log(e);
    }
}



function* savePhysicianInformationWORK(action) {
  try {
    yield put(setLoadingActDeact(true));
    yield put(savePhysicianInformationRequestStart());
    const { isVacation, isRemoveVacation = VACATION_REMOVE_TYPE.persistVacation } = action.payload;
    const admin = yield select(getCurrentAdmin);
    const user = yield select(getCurrentUser);
    const namesInAllLanguages = yield select(getUserNameInAllLanguages);
    const phone = yield select(getCurrentUserPhone);
    const signature = yield select(getCurrentSignature);
    const isActive = yield select(getIsCurrentUserActive);
    const permission = yield select(getCurrentUserPermission);
    const vacationArr = yield select(getCurrentVacation);
    const physicianPrefix = yield select(getPhysicianPrefix);
    const lang = yield select(getLanguage);
    let roleMap = yield select(getRoleMap);

    if(user === admin) {
      roleMap[user].name = namesInAllLanguages[lang];
    }

    if(!roleMap[user][lang]) {
      roleMap[user][lang] = {
        [lang]: {
          name: ''
        }
      }
    }

    roleMap[user][lang].name = namesInAllLanguages[lang];


    if (isVacation) {
      yield call(rest.updatePhysicianData, {
        email: user,
        adminSettings: {
          name: namesInAllLanguages.name,
          phone, signature, physicianPrefix,
          [languagesObject.EN]: {
            name: namesInAllLanguages[languagesObject.EN]
          },
          [languagesObject.HE]: {
            name: namesInAllLanguages[languagesObject.HE]
          },
          vacation: {
            start_vacation: vacationArr[0].startDate || new Date().toISOString(),
            end_vacation: vacationArr[0].endDate,
          },
        },
        isRemoveVacation: isRemoveVacation,
      });
    } else {
      yield call(rest.updatePhysicianData, {
        email: user,
        adminSettings: {
           phone, signature, physicianPrefix,
           name: namesInAllLanguages.name,
           [languagesObject.EN]: {
            name: namesInAllLanguages[languagesObject.EN]
          },
          [languagesObject.HE]: {
            name: namesInAllLanguages[languagesObject.HE]
          },
        },
      });
    }

    if (permission === 'superuser' && !isVacation) {
      yield call(rest.updatePhysicianActivity, {
        physicianSuperuser: admin,
        physician: user,
        is_active: isActive,
      });
    }
    yield delay(5000);
    yield put({
      type: ADMIN_ACTIONS_MAP.updateAdminSettingsByPhysician,
      payload: { email: user }
    });
    if (isVacation && isRemoveVacation === VACATION_REMOVE_TYPE.persistVacation) {
      yield put(setActivateVacation(true));
    } else if (isVacation) {
      yield put(setActivateVacation(false));
    }
  } catch (e) {
    console.log(`save physician information failed.`);
    console.log(e);
    yield put(setActivateVacation(false));
    yield put(savePhysicianInformationFail(e));
  }
}

function* savePhysicianDetailsWORK(action) { /////
  try {
    const { physicianData } = action.payload;
    const {
      name,
      surname,
      degree,
      license,
      expert_license,
      clinic_phone,
      clinic_address,
      physician_details,
      physician_phone: phone,
      physician_prefix: physicianPrefix,
      clinic_address_second_part,
      clinic_city,
      clinic_region,
      clinic_country,
      clinic_zip,
      clinic_linkFaceBook,
      clinic_linkInstagram,
      clinic_linkLinkedin,
      clinic_linkUrl,
    } = physicianData;

    const fullName = `${name.trim()} ${surname.trim()}`;
    const signature = yield select(getCurrentSignature);
    const stamp = yield select(getCurrentStamp);
    const profile_image = yield select(getCurrentProfileImage);
    const lang = yield select(getLanguage);

    const dataToUpdate = {
      adminSettings: {
        phone, signature, stamp,
        physicianPrefix,
        clinic_phone, clinic_address,
        degree, license, expert_license,
        physician_details,
        [lang]: { name: fullName },
        clinic_address_second_part,
        clinic_city,
        clinic_region,
        clinic_country,
        clinic_zip,
        profile_image,
        clinic_linkFaceBook,
        clinic_linkInstagram,
        clinic_linkLinkedin,
        clinic_linkUrl,
      },
    };

    const result = yield call(rest.updatePhysicianDetails, dataToUpdate);
    yield put(savePhysicianDetailSuccess(result))
    yield put(setIsSavePhysicianDerailsSuccess(true))
    yield call(() => new Promise(resolve => {
      setTimeout(resolve, 1500)
    }))
    yield put(setIsSavePhysicianDerailsSuccess(false))


  } catch (e) {
    console.log(`save physician details failed.`);
    console.log(e);
    yield put(savePhysicianDetailsFail(e));
  }
}

function* savePhysicianStampWORK(action) {
  try {
    const { file, type } = action.payload;

    const result = yield call(rest.savePhysicianImage, ({ file, type }));
    if (result?.success) yield put(savePhysicianStampSuccess(result))

  } catch (e) {
    console.log(`save physician stamp failed.`, e);
    yield put(savePhysicianStampFail(e));
  }
}

function* getPhysicianStampWORK(action) {
  try {
    const { fileName } = action.payload;
    console.log(fileName)
    const result = yield call(rest.getPhysicianImage, fileName);
    console.log(result)
    yield put(getPhysicianStampSuccess(result))

  } catch (e) {
    console.log(`get physician stamp failed.`, e);
    yield put(savePhysicianStampFail(e));
  }
}
function* savePhysicianProfileImageWORK(action) {
  try {
    const { file, type } = action.payload;

    const result = yield call(rest.savePhysicianImage, ({ file, type }));
    if (result?.success) yield put(savePhysicianProfileImageSuccess(result))

  } catch (e) {
    console.log(`save physician profile image failed.`, e);
    yield put(savePhysicianProfileImageFail(e));
  }
}

function* getPhysicianProfileImageWORK(action) {
  try {
    const { fileName } = action.payload;

    const result = yield call(rest.getPhysicianImage, fileName);
    yield put(getPhysicianProfileImageSuccess(result))

  } catch (e) {
    console.log(`get physician profile image failed.`, e);
    yield put(savePhysicianProfileImageFail(e));
  }
}

function* sendInvitationEmailWORK() {
  try {
    const email = yield select(getInvitationEmail);
    const admin = yield select(getCurrentAdmin);
    yield put(saveInvitationEmailStart());
    yield call(sendInvitation, email, admin);
    yield put(saveInvitationEmailSuccess());
    yield put({
      type: NOTIFICATION_ACTIONS.toastOpen,
      payload: {
        message: l('managementPage.invitationSentMessage', { email }),
        status: 'info',
      }
    });
  } catch(e) {
    if(`${e}`.indexOf('Request failed with status code 409') > -1) {
      yield put({
        type: NOTIFICATION_ACTIONS.toastOpen,
        payload: {
          message: l('managementPage.userAlreadyExists')
        }
      });
    } else {
      const email = yield select(getInvitationEmail);
      yield put({
        type: NOTIFICATION_ACTIONS.toastOpen,
        payload: {
          message: l('managementPage.invitationFailedMessage', { email })
        }
      });
    }

    yield put(saveInvitationEmailFail(e));
  }
}

function* watchSendInvitationEmail() {
  yield takeLatest(ADMIN_ACTIONS_MAP.invitePhysicianRequest, sendInvitationEmailWORK)
}

function* watchSavePhysicianDetails() {
  yield takeLatest(ADMIN_ACTIONS_MAP.savePhysicianDetails, savePhysicianDetailsWORK)
}

function* watchSavePhysicianStamp() {
  yield takeLatest(ADMIN_ACTIONS_MAP.savePhysicianStampRequest, savePhysicianStampWORK)
}

function* watchSavePhysicianProfileImage() {
  yield takeLatest(ADMIN_ACTIONS_MAP.savePhysicianProfileImageRequest, savePhysicianProfileImageWORK)
}

function* watchGetPhysicianStamp() {
  yield takeLatest(ADMIN_ACTIONS_MAP.getPhysicianStampRequest, getPhysicianStampWORK)
}

function* watchGetPhysicianProfileImage() {
  yield takeLatest(ADMIN_ACTIONS_MAP.getPhysicianProfileImageRequest, getPhysicianProfileImageWORK)
}

function* watchSavePhysicianInformation() {
  yield takeLatest(ADMIN_ACTIONS_MAP.savePhysicianInformation, savePhysicianInformationWORK)
}

function* watchGetDoctorDetails() {
    yield takeLatest(ADMIN_ACTIONS_MAP.updateAdminSettingsByPhysician, getDoctorDetailsWORK);
}

function* watchBlockCurrentUser() {
  yield takeLatest(ADMIN_ACTIONS_MAP.blockCurrentUser, blockCurrentUser);
}

function* watchLogout() {
  yield takeLatest(ADMIN_ACTIONS_MAP.logout, logout);
}

export function* blockCurrentUser() {
  const admin = yield select(getCurrentAdmin);
  const user = yield select(getCurrentUser);
  yield call(rest.updatePhysicianActivity, {
      physicianSuperuser: admin,
      physician: user,
      is_active: false,
  });
}

export function* logout(action) {
  const history = action.payload.history;
  const permission = select(getCurrentPhycisianPermission);
  const userEmail = select(getCurrentPhycisianEmail);

  yield authService.logout();

  yield put({
    type: NOTIFICATION_ACTIONS.addNotification,
    payload: { action: SYSTEM_ALLOWED_NOTIFICATIONS.LOGOUT_NOTIFICATION, props: {
      permission: permission,
      user: userEmail,
      date: new Date(),
    }}
  });

  yield put({
    type: 'SET_USER',
    payload: {
      user: '', vendor: '', permission: '',
    }
  });

  yield put(resetAdminSettings());
  yield put(removeCSRFTokenAction());

  if(history) {
    console.log('logout history')
    history.push(generateUrlParams('/login'));
  }
}


const adminSagas = [
    fork(watchGetDoctorDetails),
    fork(watchSendInvitationEmail),
    fork(watchSavePhysicianInformation),
    fork(watchSavePhysicianDetails),
    fork(watchSavePhysicianStamp),
    fork(watchSavePhysicianProfileImage),
    fork(watchGetPhysicianStamp),
    fork(watchGetPhysicianProfileImage),
    fork(watchBlockCurrentUser),
    fork(watchLogout),
];

export default adminSagas;