import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  headerCases: {
    flex: 1,
    display: 'flex',
    justifyContent: 'stretch',
  },
  cases: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  casesEnd: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  casesInfoWrapper: {
    margin: '0 28px',
  }
}));