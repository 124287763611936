import { DEFAUL_LIMIT } from '../reducers/patientListReducer';

export const PATIENT_LIST_ACTIONS_MAP = Object.freeze({
    initFilterSettings: 'INIT_FILTER_SETTINGS',
    initFilterSettingsStart: 'INIT_FILTER_SETTINGS_START',
    getPatientsStart: 'PATIENT_LIST_GET_PATIENTS_START',
    getPatientsSuccess: 'PATIENT_LIST_GET_PATIENTS_SUCCESS',
    getPatientsFail: 'PATIENT_LIST_GET_PATIENTS_FAIL',
    getPatients: 'PATIENT_LIST_GET_PATIENTS',
    unsubscrbeGetPatients: 'UNSUBSCRIBE_PATIENT_LIST_GET_PATIENTS',
    subscrbeGetPatients: 'SUBSCRIBE_PATIENT_LIST_GET_PATIENTS',
    updateGlobalSearch: 'PATIENT_LIST_UPDATE_GLOBAL_SEARCH_REQUEST',
    updateGlobalSearchStart: 'PATIENT_LIST_UPDATE_GLOBAL_SEARCH_REQUEST_START',
    updateGlobalSearchSuccess: 'PATIENT_LIST_UPDATE_GLOBAL_SEARCH_REQUEST_SUCCESS',
    updateGlobalSearchFail: 'PATIENT_LIST_UPDATE_GLOBAL_SEARCH_REQUEST_FAIL',
    updatePageLimit: 'PATIENT_LIST_UPDATE_PAGE_LIMIT_REQUEST',
    updatePageLimitStart: 'PATIENT_LIST_UPDATE_PAGE_LIMIT_REQUEST_START',
    updatePageLimitFail: 'PATIENT_LIST_UPDATE_PAGE_LIMIT_REQUEST_FAIL',
    updatePageOffset: 'PATIENT_LIST_UPDATE_PAGE_OFFSET_REQUEST',
    updatePageOffsetStart: 'PATIENT_LIST_UPDATE_PAGE_OFFSET_REQUEST_START',
    updatePageOffsetFail: 'PATIENT_LIST_UPDATE_PAGE_OFFSET_REQUEST_FAIL',
    updatePatientsCaseId: 'PATIENT_LIST_UPDATE_CASE_ID_REQUEST',
    updatePatientsCaseIdStart: 'PATIENT_LIST_UPDATE_CASE_ID_REQUEST_START',
    updatePatientsCaseIdFail: 'PATIENT_LIST_UPDATE_CASE_ID_REQUEST_FAIL',
    updatePatientsId: 'PATIENT_LIST_UPDATE_ID_REQUEST',
    updatePatientsIdStart: 'PATIENT_LIST_UPDATE_ID_REQUEST_START',
    updatePatientsIdFail: 'PATIENT_LIST_UPDATE_ID_REQUEST_FAIL',
    updateCreatedAtValue: 'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_REQUEST',
    updateCreatedAtValueStart: 'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_REQUEST_START',
    updateCreatedAtValueFail: 'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_REQUEST_FAIL',
    updateCreatedAtDateRangeValue: 'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_DATE_RANGE_REQUEST',
    updateCreatedAtValueDateRangeStart: 'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_DATE_RANGE_REQUEST_START',
    updateCreatedAtValueDateRangeFail: 'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_DATE_RANGE_REQUEST_FAIL',
    updateAgeValue: 'PATIENT_LIST_UPDATE_AGE_VALUE_REQUEST',
    updateAgeValueStart: 'PATIENT_LIST_UPDATE_AGE_VALUE_REQUEST_START',
    updateAgeValueFail: 'PATIENT_LIST_UPDATE_AGE_VALUE_REQUEST_FAIL',
    updateGenderValue: 'PATIENT_LIST_UPDATE_GENDER_VALUE_REQUEST',
    updateGenderValueStart: 'PATIENT_LIST_UPDATE_GENDER_VALUE_REQUEST_START',
    updateGenderValueFail: 'PATIENT_LIST_UPDATE_GENDER_VALUE_REQUEST_FAIL',
    updateEfficiencyReportValue: 'PATIENT_LIST_UPDATE_EFFICIENCY_REPORT_VALUE_REQUEST',
    updateEfficiencyReportValueStart: 'PATIENT_LIST_UPDATE_EFFICIENCY_REPORT_VALUE_REQUEST_START',
    updateEfficiencyReportValueFail: 'PATIENT_LIST_UPDATE_EFFICIENCY_REPORT_VALUE_REQUEST_FAIL',
    updateStatusValue: 'PATIENT_LIST_UPDATE_STATUS_VALUE_REQUEST',
    updateStatusValueStart: 'PATIENT_LIST_UPDATE_STATUS_VALUE_REQUEST_START',
    updateStatusValueFail: 'PATIENT_LIST_UPDATE_STATUS_VALUE_REQUEST_FAIL',
    updateDiagnosedByValue: 'PATIENT_LIST_UPDATE_DIAGNOSED_BY_VALUE_REQUEST',
    updateDiagnosedByValueStart: 'PATIENT_LIST_UPDATE_DIAGNOSED_BY_VALUE_REQUEST_START',
    updateDiagnosedByValueFail: 'PATIENT_LIST_UPDATE_DIAGNOSED_BY_VALUE_REQUEST_FAIL',
    updateDiagnosedAtValue: 'PATIENT_LIST_UPDATE_DIAGNOSED_AT_VALUE_REQUEST',
    updateDiagnosedAtValueStart: 'PATIENT_LIST_UPDATE_DIAGNOSED_AT_VALUE_REQUEST_START',
    updateDiagnosedAtValueFail: 'PATIENT_LIST_UPDATE_DIAGNOSED_AT_VALUE_REQUEST_FAIL',
    resetAllFilters: 'PATIENT_LIST_RESET_ALL_FILTERS',
    resetAllFiltersStart: 'PATIENT_LIST_RESET_ALL_FILTERS_START',
    resetAllFiltersFail: 'PATIENT_LIST_RESET_ALL_FILTERS_FAIL',
    updateCreatedAtOrder: 'PATIENT_LIST_UPDATE_CREATED_AT_ORDER',
    updateCreatedAtOrderStart: 'PATIENT_LIST_UPDATE_CREATED_AT_ORDER_START',
    updateCreatedAtOrderFail: 'PATIENT_LIST_UPDATE_CREATED_AT_ORDER_FAIL',
    updatePageNumber: 'PATIENT_LIST_UPDATE_PAGE_NUMBER',
    updatePageNumberStart: 'PATIENT_LIST_UPDATE_PAGE_NUMBER_START',
    updatePageNumberFail: 'PATIENT_LIST_UPDATE_PAGE_NUMBER_FAIL',
    updateListOrderBy: 'updateListOrderBy',
    setPatientsListACtiveFilter: 'setPatientsListACtiveFilter',
});

// initial interval process started
export const initFilterSettings = () => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.initFilterSettings,
    }
}

export const initFilterSettingsStart = (filters) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.initFilterSettingsStart,
        payload: { filters },
    }
}

export const getPatientsRequest = (load = false) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.getPatients,
        payload: {load}
    }
}

export const unsubscribeGetPatientsRequest = () => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.unsubscrbeGetPatients,
    }
}

export const getPatients = () => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.getPatientsStart,
    }
}

export const getPatientsSuccess = (response) => {
    const { patients, total } = response;
    return {
        type: PATIENT_LIST_ACTIONS_MAP.getPatientsSuccess,
        payload: { patients, total },
    }
}

export const getPatientsFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.getPatientsFail,
        payload: { error },
    }
}

// update global search started
export const updateGlobalSearchRequest = (globalFilterString) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateGlobalSearch,
        payload: { globalFilterString }
    }
}

export const updateGlobalSearchStart = (globalFilterString) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateGlobalSearchStart,
        payload: { globalFilterString }
    }
}

export const updateGlobalSearchSuccess = (response) => {
    const { patients, total } = response;
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateGlobalSearchSuccess,
        payload: { patients, total },
    }
}

export const updateGlobalSearchFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateGlobalSearchFail,
        payload: { error },
    }
}

// update page limit started
export const updatePageLimitRequest = (limitStr) => {
    try {
        const limit = parseInt(limitStr);
        return {
            type: PATIENT_LIST_ACTIONS_MAP.updatePageLimit,
            payload: { limit }
        }
    } catch(e) {
        return {
            type: PATIENT_LIST_ACTIONS_MAP.updatePageLimit,
            payload: { limit: DEFAUL_LIMIT }
        }
    }
}

export const updatePageLimitStart = (limit) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePageLimitStart,
        payload: { limit }
    }
}

export const updatePageLimitFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePageLimitFail,
        payload: { error },
    }
}

// update page number started
export const updatePageOffsetRequest = (offset) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePageOffset,
        payload: { offset }
    }
}

export const updatePageOffsetStart = (offset) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePageOffsetStart,
        payload: { offset }
    }
}

export const updatePageOffsetFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePageOffsetFail,
        payload: { error },
    }
}

// update patients case id started
export const updatePatienstsCaseIdRequest = (valueCaseId) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePatientsCaseId,
        payload: { valueCaseId }
    }
}

export const updatePatienstsCaseIdStart = (valueCaseId) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePatientsCaseIdStart,
        payload: { valueCaseId }
    }
}

export const updatePatienstsCaseIdFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePatientsCaseIdFail,
        payload: { error },
    }
}

// update patients id started
export const updatePatienstsIdRequest = (valuePatientId) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePatientsId,
        payload: { valuePatientId }
    }
}

export const updatePatienstsIdStart = (valuePatientId) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePatientsIdStart,
        payload: { valuePatientId }
    }
}

export const updatePatienstsIdFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePatientsIdFail,
        payload: { error },
    }
}

// update created_at value started
export const updateCreatedAtRequest = (valueCreatedTime) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateCreatedAtValue,
        payload: { valueCreatedTime }
    }
}

export const updateCreatedAtStart = (valueCreatedTime) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateCreatedAtValueStart,
        payload: { valueCreatedTime }
    }
}

export const updateCreatedAtFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateCreatedAtValueFail,
        payload: { error },
    }
}

// update created_at in date range started
export const updateCreatedAtDateRangeRequest = (dateRange) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateCreatedAtDateRangeValue,
        payload: { dateRange }
    }
}

export const updateCreatedAtDateRangeStart = (dateRange) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateCreatedAtValueDateRangeStart,
        payload: { dateRange }
    }
}

export const updateCreatedAtDateRangeFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateCreatedAtValueDateRangeFail,
        payload: { error },
    }
}

// update age  started
export const updateAgeRequest = (age) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateAgeValue,
        payload: { age }
    }
}

export const updateAgeStart = (age) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateAgeValueStart,
        payload: { age }
    }
}

export const updateAgeFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateAgeValueFail,
        payload: { error },
    }
}

// update gender started
export const updateGenderRequest = (gender) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateGenderValue,
        payload: { gender }
    }
}

export const updateGenderStart = (gender) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateGenderValueStart,
        payload: { gender }
    }
}

export const updateGenderFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateGenderValueFail,
        payload: { error },
    }
}

// update status started
export const updateStatusRequest = (statuses) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateStatusValue,
        payload: { statuses }
    }
}

export const updateStatusStart = (statuses) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateStatusValueStart,
        payload: { statuses }
    }
}

export const updateStatusFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateStatusValueFail,
        payload: { error },
    }
}

// update efficiencyReport started
export const updateEfficiencyReportRequest = (efficiencyReport) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateEfficiencyReportValue,
        payload: { efficiencyReport }
    }
}

export const updateEfficiencyReportStart = (efficiencyReport) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateEfficiencyReportValueStart,
        payload: { efficiencyReport }
    }
}

export const updateEfficiencyReportFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateEfficiencyReportValueFail,
        payload: { error },
    }
}

// update diagnosed by  started
export const updateDiagnosedByRequest = (valueDiagnosedBy) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateDiagnosedByValue,
        payload: { valueDiagnosedBy }
    }
}

export const updateDiagnosedByStart = (valueDiagnosedBy) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateDiagnosedByValueStart,
        payload: { valueDiagnosedBy }
    }
}

export const updateDiagnosedByFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateDiagnosedByValueFail,
        payload: { error },
    }
}

// update diagnosed at  started
export const updateDiagnosedAtRequest = (valueDiagnosedAt) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateDiagnosedAtValue,
        payload: { valueDiagnosedAt }
    }
}

export const updateDiagnosedAtStart = (valueDiagnosedAt) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateDiagnosedAtValueStart,
        payload: { valueDiagnosedAt }
    }
}

export const updateDiagnosedAtFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateDiagnosedAtValueFail,
        payload: { error },
    }
}

// reset all filters started
export const resetAllFilters = () => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.resetAllFilters,
    }
}

export const resetAllFiltersStart = () => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.resetAllFiltersStart,
    }
}

export const resetAllFiltersFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.resetAllFiltersFail,
        payload: { error },
    }
}

// reset created at order started
export const updateCreatedAtOrder = (orderCreatedAt) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateCreatedAtOrder,
        payload: { orderCreatedAt }
    }
}

export const updateCreatedAtOrderStart = (orderCreatedAt) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateCreatedAtOrderStart,
        payload: { orderCreatedAt }
    }
}

export const updateCreatedAtOrderFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updateCreatedAtOrderFail,
        payload: { error },
    }
}

// update page number started
export const updatePageNumberRequest = (page) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePageNumber,
        payload: { page }
    }
}

export const updatePageNumberRequestStart = (page) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePageNumberStart,
        payload: { page }
    }
}

export const updatePageNumberRequestFail = (error) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.updatePageNumberFail,
        payload: { error },
    }
}

export const setPatientsListACtiveFilter = (patientsListACtiveFilter) => {
    return {
        type: PATIENT_LIST_ACTIONS_MAP.setPatientsListACtiveFilter,
        payload: patientsListACtiveFilter,
    }
}
