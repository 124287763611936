import { SMS_NOTIFICATION_ACTIONS } from "../actions/sms.notifications.actions";
import {
  CASES_BATCH_ACTIONS_MAP, CASE_EDIT_PAGE_BLOCK_STATUS
} from "../actions/cases.batch.actions";
import { DIAGNOSE_BLOCK_NAMES } from "../actions/disease.actions";
import { defaultDiagnoses } from "../components/CaseEdit/helpers/helper.functions";
import {PATIENT_DETAILS_ACTIONS} from '../actions/patient.details.actions';

const initialState = {
  createTreatmentPlanLoading: false,
  createTreatmentPlanSuccess: null,
  createTreatmentPlanError: null,
  patientDetails: {
    currentTab: 'tab-0',
  },
  caseAvailabilityProps: {
    loading: false,
    success: true,
    isNextDisabled: false,
    isPrevDisabled: false,
    fail: false,
    mode: localStorage.getItem('AVAILABLE_VIEW_MODE'),
    isOnlyDiagnosed: sessionStorage.getItem('AVAILABLE_VIEW_MODE_DIAGNOSED_ONLY') === 'true',
  },
  currentTab: 'tab-0',
  isDiagnoseLoaded: true,
  patientDetailsTranslated: null,
  currentCasesArray: [],
  awaitingNewCasesArray: [],
  isCasesMergeConflict: false,
  isPatientSMSNotification: false,
  currentCaseIndex: 0,
  isAnnotationsFetchLoading: true,
  descriptions: null,
  annotations: [],
  isNotifyLoading: {},
  isNotifyFailed: {},
  response: {},
  status: 1,
  created_at: '',
  modified_by: '',
  case_id: '',
  case_uuid:'',
  private_pool: '',
  user_identifier: '',
  vendor_id: '',
  diagnosed_by: '',
  doctorDataDiagnosedBy: null,
  load_data: true,
  error: null,
  cancelationNotification: false,
  annotationState: {
    modal: false,
    sending: false,
    error: null,
    notification: null,
  },
  isPendingLoading: false,
  isUnblockPendingLoading: false,
  pendingError: null,
  imageRequirementStatus: CASE_EDIT_PAGE_BLOCK_STATUS.RELEASE_IMAGE_CHECK_BLOCK,
  mlDiagnosisProbabilities: null,
  isImagesLoading: true,
  imagesScrollPosition: 0,
  scrollContainerHeight: 0,
  userHash: null,
  [DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE]: defaultDiagnoses,
  [DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE]: defaultDiagnoses,
  [DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE]: defaultDiagnoses,
  caseComment: '',
  isLoadingSaveCase: false,
  isNotifyingCase: false,
  patient_id: '',
  patient_email: '',
  physician_code: null,
  vectibix: null,
};

export default function patientDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case PATIENT_DETAILS_ACTIONS.CREATE_TREATMENT_PLAN_START:
      return {
        ...state,
        createTreatmentPlanLoading: true,
        createTreatmentPlanSuccess: false,
        createTreatmentPlanError: initialState.createTreatmentPlanError,
      };
    case PATIENT_DETAILS_ACTIONS.CREATE_TREATMENT_PLAN_RESET:
      return {
        ...state,
        createTreatmentPlanLoading: false,
        createTreatmentPlanSuccess: false,
        createTreatmentPlanError: initialState.createTreatmentPlanError,
      };
    case PATIENT_DETAILS_ACTIONS.CREATE_TREATMENT_PLAN_SUCCESS:
      return {
        ...state,
        createTreatmentPlanLoading: false,
        createTreatmentPlanSuccess: true,
      };
    case PATIENT_DETAILS_ACTIONS.CREATE_TREATMENT_PLAN_FAIL:
      return {
        ...state,
        createTreatmentPlanLoading: false,
        createTreatmentPlanError: action.payload,
      };
    case CASES_BATCH_ACTIONS_MAP.SET_IMAGES_LOADING:
      return {
        ...state,
        isImagesLoading: action.payload.isLoad,
      };

    case CASES_BATCH_ACTIONS_MAP.UPDATE_CASE_COMMENT:
      return {
        ...state,
        caseComment: action.payload.comment,
      };
  case CASES_BATCH_ACTIONS_MAP.UPDATE_IS_NOTIFYING_CASE:
      return {
        ...state,
        isNotifyingCase: action.payload.isNotify,
      };
    case CASES_BATCH_ACTIONS_MAP.UPDATE_IS_LOADING_SAVE_CASE:
      console.log('isLoadingSaveCase:', action.payload.isLoad)
      return {
        ...state,
        isLoadingSaveCase: action.payload.isLoad,
      };
    case CASES_BATCH_ACTIONS_MAP.UPDATE_IMAGE_SCROLL_POSITION:
      return {
        ...state,
        imagesScrollPosition: action.payload.imagesScrollPosition,
    };case CASES_BATCH_ACTIONS_MAP.UPDATE_SCROLL_CONTAINER:
      return {
        ...state,
        scrollContainerHeight: action.payload.scrollContainerHeight,
      };
    case CASES_BATCH_ACTIONS_MAP.UPDATE_IMAGES_REQUIREMENTS_BLOCK:
      return {
        ...state,
        imageRequirementStatus: action.payload.imageRequirementStatus,
      };
    case CASES_BATCH_ACTIONS_MAP.SET_ML_DIAGNOSIS_PROBABILITY:
      return {
        ...state,
        mlDiagnosisProbabilities: action.payload ? action.payload.mlDiagnosisProbabilities : null,
      }
    case CASES_BATCH_ACTIONS_MAP.UPDATE_UNBLOCK_CASES_BATCH_START:
      return {
        ...state,
        isUnblockPendingLoading: true,
      }
    case CASES_BATCH_ACTIONS_MAP.UPDATE_TREATMENT_PLAN:
      return {
        ...state,
        [action.payload.name]: action.payload.diagnosis,
      }
    case CASES_BATCH_ACTIONS_MAP.UPDATE_UNBLOCK_CASES_BATCH_SUCCESS:
    case CASES_BATCH_ACTIONS_MAP.UPDATE_UNBLOCK_CASES_BATCH_FAIL:
      return {
        ...state,
        isUnblockPendingLoading: false,
        descriptions: null,
      }
    case CASES_BATCH_ACTIONS_MAP.UPDATE_BLOCK_CASES_BATCH_START:
      return {
        ...state,
        isPendingLoading: true,
      }
    case CASES_BATCH_ACTIONS_MAP.UPDATE_BLOCK_CASES_BATCH_SUCCESS:
      return {
        ...state,
        isPendingLoading: false,
        isUnblockPendingLoading: false,
        descriptions: action.payload.descriptions,
      }
    case CASES_BATCH_ACTIONS_MAP.UPDATE_BLOCK_CASES_BATCH_FAIL:
      return {
        ...state,
        isPendingLoading: false,
        isUnblockPendingLoading: false,
        pendingError: action.payload.error,
      }
    case CASES_BATCH_ACTIONS_MAP.MOVE_TO_TAB:
      return {
        ...state,
        currentTab: action.payload.tabIndex,
      };
    case CASES_BATCH_ACTIONS_MAP.CASE_MERGE_CONFLICT_ON:
      return {
          ...state,
          awaitingNewCasesArray: action.payload.awaitingNewCasesArray,
        };
    case CASES_BATCH_ACTIONS_MAP.GET_DIAGNOSED_CASES_BATCH_REQUEST_SUCCESS:
      return {
        ...state,
        currentCasesArray: action.payload.batch_uuids,
        currentCaseIndex: action.payload.currentCaseIndex || initialState.currentCaseIndex,
      }
    case 'INIT_PATIENT_DETAILS': {
      return initialState
    }
    case 'TRIGGER_CANCEL_NOTIFICATION_DIALOG':
      return {
        ...state,
        cancelationNotification: action.payload.cancelationNotification,
      }
    case SMS_NOTIFICATION_ACTIONS.smsNotificationFaild:
      return {
        ...state,
      }
    case SMS_NOTIFICATION_ACTIONS.smsNotificationSuccess:
      return {
        ...state,
        isPatientSMSNotification: action.payload,
      }
    case 'UPDATE_CURRENT_CASE_INDEX':
      return {
        ...state,
        currentCaseIndex: action.payload,
      }
    case 'UPDATE_AWAITING_CASES_ARRAY':
      return {
        ...state,
        currentCasesArray: action.payload.array,
        currentCaseIndex: action.payload.index,
        case_id: '',
      }
    case 'FILL_AWAITING_CASES_ARRAY':
      return {
        ...state,
        currentCasesArray: action.payload,
        awaitingNewCasesArray: [],
      }
    case 'NOTIFICATION_STARTED':
      return {
        ...state,
        isNotifyLoading: {
          [action.payload.uuid]: true,
        },
        status: 33,
      }
    case 'NOTIFICATION_FINISHED':
      return {
        ...state,
        isNotifyLoading: {
          [action.payload.uuid]: false,
        }
      }
    case 'NOTIFICATION_FAILED':
      return {
        ...state,
        isNotifyLoading: {
          [action.payload.uuid]: false,
        },
        isNotifyFailed: {
          [action.payload.uuid]: true,
        }
      }
    case 'INIT_AVAILABLECASE_MODE': {
      localStorage.setItem('AVAILABLE_VIEW_MODE', action.payload.mode);
      sessionStorage.setItem('AVAILABLE_VIEW_MODE_DIAGNOSED_ONLY', action.payload.isOnlyDiagnosed) ;
      return {
        ...state,
        caseAvailabilityProps: {
          ...state.caseAvailabilityProps,
          mode: action.payload.mode,
          isOnlyDiagnosed: action.payload.isOnlyDiagnosed,
        },
      }
    }
    case 'GET_CHECKED_AVAILABLE_CASE_STARTED':
      return {
        ...state,
        caseAvailabilityProps: {
          ...state.caseAvailabilityProps,
          loading: true,
        },
      }
    case 'GET_CHECKED_AVAILABLE_CASE_SUCCESS':
      return {
        ...state,
        ...{ caseAvailabilityProps: initialState.caseAvailabilityProps },
      }
    case 'GET_CHECKED_AVAILABLE_CASE_FAILED':
      return {
        ...state,
        caseAvailabilityProps: {
          ...state.caseAvailabilityProps,
          loading: false,
          fail: true,
          isNextDisabled: action.payload.isNextAvailable,
          isPrevDisabled: !action.payload.isNextAvailable,
        },
      }
    case 'CHANGE_STATUS_STATE': {
      return {
        ...state,
        status: action.payload.status
      };
    }
    case 'SET_PATIENT_DETAILS': {
      const data = action.payload
      return {
        ...state,
        patientDetails: data.request,
        patientDetailsTranslated: data.requestTranslated,
        annotations: [],
        response: data.response,
        status: data.status,
        created_at: data.created_at,
        modified_by: data.modified_by,
        diagnosed_by: data.diagnosed_by,
        doctorDataDiagnosedBy: data.doctorDataDiagnosedBy,
        private_pool: data.private_pool,
        case_id: data.case_id,
        case_uuid: data.case_uuid,
        user_identifier: data.user_identifier,
        vendor_id: data.vendor_id,
        descriptions:  data.description,
        userHash: data.user_hash ? data.user_hash : null,
        patient_id: data.patient_id,
        patient_name: data.patient_name,
        patient_email: data.patient_email,
        physician_code: data.physician_code,
        vectibix: data.vectibix,
      }
    }
    case 'PATIENT_DETAILS_LOAD_START': {
      return {
        ...initialState,
        caseAvailabilityProps: {
          ...initialState.caseAvailabilityProps,
          mode: localStorage.getItem('AVAILABLE_VIEW_MODE'),
          isOnlyDiagnosed: sessionStorage.getItem('AVAILABLE_VIEW_MODE_DIAGNOSED_ONLY') === 'true' ? true : false,
        },
        case_id: null,
        status: 2,
        imageRequirementStatus: CASE_EDIT_PAGE_BLOCK_STATUS.SET_IMAGE_CHECK_BLOCK,
        currentCasesArray: state.currentCasesArray,
        currentCaseIndex: state.currentCaseIndex,
      }
    }
    case 'PATIENT_DETAILS_LOAD_END_ERROR': {
      return {
        ...state,
        error: action.payload,
        load_data: true
      }
    }
    case 'PATIENT_DETAILS_LOAD_END': {
      return {
        ...state,
        load_data: false
      }
    }
    case 'FETCH_ANNOTATIONS_START':
      return {
        ...state,
        error: null,
        isAnnotationsFetchLoading: true,
      }
    case 'SET_ANNOTATIONS': {
      return {
        ...state,
        isAnnotationsFetchLoading: false,
        annotations: action.payload
      }
    }
    case 'CANCEL_ANNOTATION_BY_ID': {
      return {
        ...state,
        isAnnotationsFetchLoading: false,
        annotations: action.payload
      }
    }
    case 'REMOVE_ANNOTATION_BY_ID': {
      return {
        ...state,
        isAnnotationsFetchLoading: false,
        annotations: state.patientDetails?.annotations?.filter((annotation) =>
          annotation?.uuid !== state?.patientDetails?.case_uuid
        ) || []
      }
    }
    case 'OPEN_MODAL__ADD_ANNOTATION': {
      return {
        ...state,
        annotationState: {...state.annotationState, modal: true}
      }
    }
    case 'CLOSE_MODAL__ADD_ANNOTATION': {
      return {
        ...state,
        annotationState: {...state.annotationState, modal: false, error: ''}
      }
    }
    case 'START_ADD_ANNOTATION': {
      return {
        ...state,
        annotationState: {...state.annotationState, sending: true},
        isDiagnoseLoaded: false,
      }
    }
    case 'END_ADD_ANNOTATION': {
      return {
        ...state,
        annotationState: {...state.annotationState, modal: false, sending: false},
        isDiagnoseLoaded: true,
      }
    }
    case 'ANNOTATION_ALREADY_TAKEN_DIALOG_TRIGGER':
      return {
        ...state,
        annotationState: {
          ...state.annotationState,
          isAnnotationModal: false,
          error: null,
          notification: null,
        }
      }
    case 'ADD_ANNOTATION_LATEST_WITHOUT_NOTIFICATION':
      return {
        ...state,
        annotationState: {
          ...state.annotationState,
          isAnnotationModal: true,
          sending: false,
          notification: action.payload.notification,
          error: null,
        }
      }
    case 'ERROR_ADD_ANNOTATION_ALREADY_TAKEN':
      return {
        ...state,
        annotationState: {
          ...state.annotationState,
          isAnnotationModal: true,
          sending: false,
          notification: null,
          error: action.payload.error
        }
      }
    case 'ERROR_ADD_ANNOTATION': {
      return {
        ...state,
        annotationState: {
          ...state.annotationState,
          modal: true,
          sending: false,
          error: action.payload.error
        }
      }
    }
    default: {
      return state;
    }
  }
}
