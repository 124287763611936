import { call, put, takeLatest, select, fork } from 'redux-saga/effects'
import rest from '../services/main.service'
import i18n from '../i18n';
import * as moment from 'moment';
import { isStatusChangeAllowed, transformMLDiagnosisProbability, transformPendingDescription } from './helper';
import { SYSTEM_STATUSES } from '../components/helpers/groups';
// import { EDoctorPermissions } from '../reducers/settingReducer';
import {
  SYSTEM_ALLOWED_NOTIFICATIONS, addNotificationAction, NOTIFICATION_ACTIONS,
} from '../actions/notification.actions';
import { successSMSNotifications, failedSMSNotifications, SMS_NOTIFICATION_ACTIONS } from '../actions/sms.notifications.actions';
import { getDiagnosedCasesBatchRequest, getPendingCasesBatchRequest, setMLDiagnosisProbability } from '../actions/cases.batch.actions';
import { getAwaitingCasesList, getCaseUUIDSelector, getUserHash } from '../selectors/patient.details.selector';
import { updateCurrentAwaitingListAfterReload } from './cases-batch-process';
// import { generateUrlParams } from '../sagas/helper';
import { getTenant } from '../selectors/auth.selector';
import { TENANTS } from '../helpers/auth.helper';
import { getAiModel } from '../selectors/settings.selector';
import { updatePhysicianPrefix } from '../actions/admin.actions';
import { getPhysicianPrefix } from '../selectors/admin.selector';
import { getPrefixForLang } from '../selectors';
import { axiosInstance } from '../core/axios';
import {PATIENT_HISTORY_ACTION_MAP} from '../reducers/patientHistoryReducer';
import { getAuditAnnotationsByUuidRequest } from '../actions/audit.annotations.actions';

const l = (key, params) => i18n.t(key, params);

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
/**
 *
 * @param {uuid, modified_by} action
 *
 */
export function* doGetNotifyPatient(action) {
  try {
    const notificationPayload = {
      action: SYSTEM_ALLOWED_NOTIFICATIONS.NOTIFICATION_NOTIFY_PATIENT,
      props: {
        caseId: action.payload.uuid,
        user_identifier: action.payload.user_identifier,
        physician: action.payload.modified_by,
        date: new Date(),
      },
    };
    yield put(addNotificationAction(notificationPayload));
    yield put({ type: 'NOTIFICATION_STARTED', payload: action.payload });
    const notificationStatus = yield call(rest.notifyPatient, action.payload);
    if (notificationStatus === rest.ANNOTATION_NOTIFICATION_STATUSES.FAILED_TO_SEND) {
      yield put({ type: 'NOTIFICATION_FAILED', payload: action.payload });
    } else {
      yield put({ type: 'NOTIFICATION_FINISHED', payload: action.payload });
    }
  } catch (e) {
    yield put({ type: 'NOTIFICATION_FAILED', payload: action.payload });
  }
}

export function* switchSMSNotificationWORK (action) {
  try {
    yield call(rest.switchSMSNotification, action.payload);
    // will see what to do with results
    yield put(successSMSNotifications());
  } catch (e) {
    yield put(failedSMSNotifications());
  }
}


function* addTemplate(action) {
  yield put({type: 'START_TEMPLATE_PROCESS'})
  yield call(rest.addTemplate, action.payload)
  yield put({type: 'LOAD_TEMPLATES'})
}

function* deleteTemplate(action) {
  yield put({type: 'START_TEMPLATE_PROCESS'})
  const {id} = action.payload
  yield call(rest.deleteTemplate, id)
  yield put({type: 'LOAD_TEMPLATES'})
}

function* saveTemplate(action) {
  yield put({type: 'START_TEMPLATE_PROCESS'})
  yield call(rest.editTemplate, action.payload)
  yield put({type: 'LOAD_TEMPLATES'})
}

function* loadTemplates() {
  const templates = yield call(rest.getTemplates, null)
  yield put({type: 'GET_TEMPLATES', payload: templates})
  yield put({type: 'END_TEMPLATE_PROCESS'})
}

function* cancel_anatation(action) {
  const { id, uuid, caseId } = action.payload;
  const availableCount = yield call(rest.cancelAnnotation, id, uuid);
  yield put({
    type: NOTIFICATION_ACTIONS.toastOpen,
    payload: {
      message: l('patientsDetails.canceledCaseMessage', { id:caseId })
    }
  });
  if (availableCount) {
    const { patientDetails: { annotations } } = yield select();
    const index = annotations.findIndex(a => a.uuid === uuid && a.id === id);
    const notificationPayload = {
      action: SYSTEM_ALLOWED_NOTIFICATIONS.CANCEL_DIAGNOSE_NOTIFICATION,
      props: {
        caseId: caseId,
        diagnose: annotations[index] ? annotations[index].diagnosis : [],
        comment: annotations[index] ? annotations[index].comment : '',
        date: new Date(),
      },
    };
    yield put(addNotificationAction(notificationPayload));
    if (annotations[index]) {
      annotations[index].is_canceled = true;
    }
    yield put({ type: 'CANCEL_ANNOTATION_BY_ID', payload: [...annotations] })
  } else {
    yield put({  type: 'TRIGGER_CANCEL_NOTIFICATION_DIALOG', payload: {
      cancelationNotification: true,
    } })
    yield put({ type: 'GET_ANNOTATIONS', payload: {caseId: uuid} });
  }
}


function* load_history(action) {
  try {
    let userHash;
    const tenant = yield select(getTenant);
    const {user_identifier} = action.payload

    switch(tenant) {
      case TENANTS.MYSKIN:
        userHash = yield select(getUserHash);
      break;

      default:
        userHash = user_identifier;
    }
    yield put({ type: PATIENT_HISTORY_ACTION_MAP.GET_PATIENT_HISTORY_DISEASES_START });
    const { data } = yield call(() => axiosInstance.get(`/api/history/${userHash}`, { timeout: 60000 }));
    const diseases = Array.isArray(data) ? data
      .filter(item => (item.status > SYSTEM_STATUSES.IN_PROCESS && item.status !== SYSTEM_STATUSES.EDITING))
      .map(d => ({ ...d, isShown: false, })) : [];
    yield put({type: PATIENT_HISTORY_ACTION_MAP.GET_PATIENT_HISTORY_DISEASES_SUCCESS, diseases });
  } catch (error) {
    if (error instanceof Error) {
      yield put({type: PATIENT_HISTORY_ACTION_MAP.GET_PATIENT_HISTORY_DISEASES_FAIL, error});
    }
  }
}

function* load_annotations(action) {
  const { caseId } = action.payload;
  yield put({type: 'FETCH_ANNOTATIONS_START'});
  const data = yield call(rest.getAnnotation, caseId)
  yield put({type: 'SET_ANNOTATIONS', payload: data });
}

function* dispatchUpdateStatusOnLoadOrAvailability(status, permission, payload, isOnlyDiagnosed) {
  if (isOnlyDiagnosed && status !== SYSTEM_STATUSES.DIAGNOSED) {
    return;
  } else if(status === SYSTEM_STATUSES.COMPLETED_FOR_DIAGNOSE && isStatusChangeAllowed(permission)) {
    yield put({ type: 'UPDATE_STATUS', payload: payload })
  }
}

// function* checkPermissionOfFetchedCase(modified_by, physician, permission, data, status, history) {
//   if (
//     (
//       (
//         status === SYSTEM_STATUSES.IN_PROCESS ||
//         status === SYSTEM_STATUSES.PENDING ||
//         status === SYSTEM_STATUSES.DIAGNOSED
//       )
//       && modified_by !== physician &&
//       permission === EDoctorPermissions.user
//     ) ||
//     (data === 'OTHER_PRIVATE_POOL' && permission === EDoctorPermissions.user)
//     )
//   {
//     yield put({
//       type: 'OPEN_SNACKBAR',
//       message: l('snackbar.caseAlreadyHandledError', { modified_by: modified_by }),
//       variant: 'info'
//     })
//     if (history) {
//       history.push(generateUrlParams(`/patients`));
//     }
//     return false;
//   } else {
//     return true
//   }
// }

function* load_patiens_details(action) {
  const {permission, caseId, user, history, isOnlyDiagnosed, lang } = action.payload;
  yield put({type: 'PATIENT_DETAILS_LOAD_START'})
  try {
    yield select(getAiModel);
    const data = yield call(rest.getPatientDetailsTranslated, caseId, lang);
    let doctorDataDiagnosedBy;
    if (data?.diagnosed_by && user !== data?.diagnosed_by) {
      const { admin_settings } = yield call(rest.getDoctorDetails, data?.diagnosed_by);
      doctorDataDiagnosedBy = {
        name: { en: admin_settings?.en, he: admin_settings?.he, ru: admin_settings?.ru, fr: admin_settings?.fr },
        physicianPrefix: admin_settings.physicianPrefix,
      };
    }
    // if (yield checkPermissionOfFetchedCase(data.modified_by, user, permission, data, data.status, history)) {
    data.case_uuid = caseId;

    const description = transformPendingDescription(data.pending_description);
    const mlDiagnosisProbability = transformMLDiagnosisProbability(data.ml_diagnosis_probability);

    yield put(setMLDiagnosisProbability(mlDiagnosisProbability));
    yield put(getAuditAnnotationsByUuidRequest(caseId));

    yield put({type: 'SET_PATIENT_DETAILS', payload: {...data, doctorDataDiagnosedBy, description}}) // set data in
    yield put({type: 'GET_ANNOTATIONS', payload: {caseId}});
    yield put({type: 'GET_HISTORY', payload: {user_identifier: data.user_identifier, case_id: data.case_id}});
    yield dispatchUpdateStatusOnLoadOrAvailability(
      data.status,
      permission,
      { caseId: caseId, updated_by: user, status: SYSTEM_STATUSES.IN_PROCESS },
      isOnlyDiagnosed
    );
    const currentAwaitingList = yield select(getAwaitingCasesList);

    if (isOnlyDiagnosed && (!Array.isArray(currentAwaitingList) || !currentAwaitingList.length)) {
      yield put(getDiagnosedCasesBatchRequest({uuid: caseId, history}));
    } else if (!isOnlyDiagnosed && data.status === SYSTEM_STATUSES.PENDING) {
      yield put(getPendingCasesBatchRequest({ uuid: caseId, history}));
    } else if (!isOnlyDiagnosed) {
      yield updateCurrentAwaitingListAfterReload(caseId)
    }
    // }

  } catch (e) {
    yield put({ type: 'PATIENT_DETAILS_LOAD_END_ERROR', payload: e });
  } finally {
    yield put({type: 'PATIENT_DETAILS_LOAD_END'})
  }
}

function* update_status(action) {
  const {status, caseId, updated_by} = action.payload
  try {
    const res = yield call(rest.changeStatus, caseId, {updated_by, status})
    if (res) {
      yield put({type: 'CHANGE_STATUS_STATE', payload: {status}} )
    }
  } catch (e) {
    console.log(`update_status failed, check it.`)
  }

}

// function* load_diseases(action) {
//   try {
//     const loaded_diseases = yield select(state => state.diseases && state.diseases.loaded_diseases);
//     if (!Array.isArray(loaded_diseases) || !loaded_diseases.length) {
//       const diseases = yield call(rest.getDiseases, action.payload)
//       const payload  = diseases.map(d => ({...d, name: `${d.name}`.toUpperCase() }));
//       yield put({type:'GET_DISEASES', payload })
//     }
//   } catch (e) {
//     console.log(`load_diseases -> failed`);
//   }
// }

function* setUserLng(action) {
  const {language} = action.payload

  yield call(rest.changeLang, action.payload)
  const currentPrefix = yield select(getPhysicianPrefix);
  yield put(updatePhysicianPrefix(getPrefixForLang(currentPrefix, language)));
  yield i18n.changeLanguage(language)
  const caseId = yield select(getCaseUUIDSelector);
  if (caseId) {
    yield select(getAiModel);
    const data = yield call(rest.getPatientDetailsTranslated, caseId, language);
    transformPendingDescription(data.pending_description);
    // yield put({type: 'SET_PATIENT_DETAILS', payload: {...data, description}});
  }
  moment.updateLocale(language)
  yield put({type: 'SET_USER_LANGUAGE', payload: language})
}

export function* watchSwitchSMSNotification() {
  yield takeLatest(SMS_NOTIFICATION_ACTIONS.smsNotificationRequest, switchSMSNotificationWORK)
}

export function* watchGetNotyfyPatient() {
  yield takeLatest('NOTIFY_PATIENT_BY_UUID', doGetNotifyPatient)
}

export function* watchLoadPatientDetails() {
  yield takeLatest('GET_PATIENT_DETAILS', load_patiens_details)
}
export function* watchLoadAnnotations() {
  yield takeLatest('GET_ANNOTATIONS', load_annotations)
}
export function* watchLoadHistory() {
  yield takeLatest('GET_HISTORY', load_history)
}
export function* watchUpdateStatus() {
  yield takeLatest('UPDATE_STATUS', update_status)
}

export function* watchCancelAnnotation() {
  yield takeLatest('CANCEL_ANNOTATION', cancel_anatation);
}

export function* watchloadDiseases() {
  yield takeLatest('LOAD_TEMPLATES', loadTemplates)
  yield takeLatest('SAVE_TEMPLATE', saveTemplate)
  yield takeLatest('DELETE_TEMPLATE', deleteTemplate)
  yield takeLatest('ADD_TEMPLATE', addTemplate)
}
export function* watchSetUserLang() {
  yield takeLatest('SET_USER_LANG', setUserLng)
}

export const generalSagas = [
  fork(watchSwitchSMSNotification),
  fork(watchGetNotyfyPatient),
  fork(watchLoadPatientDetails),
  fork(watchLoadAnnotations),
  fork(watchLoadHistory),
  fork(watchUpdateStatus),
  fork(watchCancelAnnotation),
  fork(watchloadDiseases),
  fork(watchSetUserLang),
];
