import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  headerUser: {
    flex: 1,
    padding: '19px 0 7px',
    // padding: '0 20px',

    display: 'flex',
    position: 'relative',
  },
  headerCases: {
    flex: 1
  },
  userInfoContainer: {
    flex: 1,
    padding: '0 0 0 54px',
    // overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    '&:after':  {
      content: (props) => props.dir === 'ltr' ? '""' : '',
      display: 'inline-block',
      width: '67px',
      height: '100%',
      background: 'linear-gradient(270deg, #FFFFFF 2.24%, rgba(255, 255, 255, 0) 170.15%)',
      position: 'absolute',
      right: 0,
    }
  },
  userInfoContainerMini: {
    padding: '0 54px',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  userInfo: {
    display: 'flex',
  },
  logo: {
    width: '67px',
    height: '65px',
  },
  caseText: {
    flex: 'none',
    fontSize: '16px',
    lineHeight: '24px',
    // letterSpacing: '0px',
    fontWeight: 400,
    color: '#858585',
    height: '35px',
    marginRight: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  caseText2: {
    whiteSpace: 'normal',
  },
  text: {
    fontSize: '16px',
    // letterSpacing: '1.25px',
    lineHeight: '24px',
    fontWeight: 700,
    color: '#4E4E53',
    marginRight: '20px',
    padding: '3px 0px'
  },
  userInfoBottom: {
    padding: '0 54px',
  },
  textCase: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#858585',
  }
}));