import { AUDIT_ANNOTATIONS_ACTION_MAP } from "../actions/audit.annotations.actions";

const initialState = {
    isLoadingAuditAnnotations: false,
    aAnnotations: [],
    createdAAnnotation: {}
}

export default function auditAnnotationsReducer(state = initialState, action) {
    switch (action.type) {
        case AUDIT_ANNOTATIONS_ACTION_MAP.getAuditAnnotationsByUuidRequest:
            return {
                ...state,
                isLoadingAuditAnnotations: true,
            };
        case AUDIT_ANNOTATIONS_ACTION_MAP.getAuditAnnotationsByUuidSuccess:
            return {
                ...state,
                isLoadingAuditAnnotations: false,
                aAnnotations: action.payload.annotations,
            };
        case AUDIT_ANNOTATIONS_ACTION_MAP.getAuditAnnotationsByUuidFail:
            return {
                ...state,
                isLoadingAuditAnnotations: false,
            };
        case AUDIT_ANNOTATIONS_ACTION_MAP.createAuditAnnotationRequest:
            return {
                ...state,
                isLoadingAuditAnnotations: true,
            };
        case AUDIT_ANNOTATIONS_ACTION_MAP.createAuditAnnotationSuccess:
            return {
                ...state,
                isLoadingAuditAnnotations: false,
                createdAAnnotation: action.payload.annotation,
            };
        case AUDIT_ANNOTATIONS_ACTION_MAP.createAuditAnnotationFail:
            return {
                ...state,
                isLoadingAuditAnnotations: false,
            };
        default: return state;
    }
}