import {
  axiosInstance
} from '../core/axios';

export async function getAuditAnnotationsByUuid(uuid) {
  try {
    const res = await axiosInstance.get(`/api/audit-annotation/${uuid}`);
    return res.data;
  } catch (err) {

    console.log(err);
    return null;
  }
};

export async function createAuditAnnotation(payload) {
  try {
    const res = await axiosInstance.post(`/api/audit-annotation/${payload.uuid}`, payload.body);
    return res.data;
  } catch (error) {
    console.log('Error creation of audit annotation: \n', error);
    return false;
  }
}



export default {
  getAuditAnnotationsByUuid,
  createAuditAnnotation
};
