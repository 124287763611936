import { fork, put, takeLatest, call, select } from 'redux-saga/effects';
import {
    AUDIT_ANNOTATIONS_ACTION_MAP,
    getAuditAnnotationsByUuidSuccess,
    getAuditAnnotationsByUuidFail,
    createAuditAnnotationSuccess,
    createAuditAnnotationFail,
} from '../../actions/audit.annotations.actions';
import { getAuditAnnotationsByUuid, createAuditAnnotation } from '../../services/audit.annotations.service';
import { createTreatmentPlanSuccess } from '../../actions/patient.details.actions';
import { initCasesBatch } from '../../actions/cases.batch.actions';
import { getAwaitingCasesList, getCurrentIndex } from '../../selectors/patient.details.selector';
import { postDiagnosis } from '../../services/main.service';
import { isLastCaseInAwaiting } from '../../components/CaseEdit/helpers/case.edit.conditions';



function* getAuditAnnotationsWork(action) {
    try {
        const res = yield call(getAuditAnnotationsByUuid, action.payload.uuid)
        yield put(getAuditAnnotationsByUuidSuccess(res));
      } catch (e) {
        yield put(getAuditAnnotationsByUuidFail())
        console.log(`getAuditAnnotationsWork -> failed`);
      }
}

function* createAuditAnnotationWork(action) {
    try {
        const {uuid, body: { status, updated_by }} = action.payload;
        const currentCasesArray = yield select(getAwaitingCasesList);
        const currentCaseIndex = yield select(getCurrentIndex);

        const isLastCase = isLastCaseInAwaiting(currentCasesArray, action.payload.uuid, currentCaseIndex);

        const res = yield call(createAuditAnnotation, action.payload);
        yield put(createAuditAnnotationSuccess(res));
        if (res) {
            let newCurrentCasesArray = currentCasesArray.map((currentCase) => {
              if (currentCase.uuid === res.uuid) return { ...currentCase, status: res.status };
              return currentCase;
            });
            if (newCurrentCasesArray.every((i) => [3, 9, 33, 46, 90, 99].includes(i.status))) {
              newCurrentCasesArray = newCurrentCasesArray.map((i) => ({
                ...i,
                status: i.status === 3 ? 33 : i.status,
              }));
            }

            if(isLastCase) yield call(postDiagnosis, { uuid, status, modified_by: updated_by, notify: true });
            yield put(initCasesBatch(newCurrentCasesArray));
            yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload: newCurrentCasesArray });
            yield put(createTreatmentPlanSuccess(newCurrentCasesArray));
        }

      } catch (e) {
        yield put(createAuditAnnotationFail())
        console.log(`getAuditAnnotationsWork -> failed`);
      }
}

function* watchGettingAuditAnnotations() {
    yield takeLatest( AUDIT_ANNOTATIONS_ACTION_MAP.getAuditAnnotationsByUuidRequest, getAuditAnnotationsWork);
}
function* watchCreationAuditAnnotation() {
    yield takeLatest( AUDIT_ANNOTATIONS_ACTION_MAP.createAuditAnnotationRequest, createAuditAnnotationWork);
}

const auditAnnotationsSagas = [
    fork(watchGettingAuditAnnotations),
    fork(watchCreationAuditAnnotation),
];

export default auditAnnotationsSagas;