import { fork, put, takeLatest, select, call } from 'redux-saga/effects';
import rest from '../../services/main.service'
import {
    DIAGNOSE_BLOCK_NAMES,
    getLoadDiseasesSuccess, setListModeByName, SISEASES_ACTIONS_MAP
} from '../../actions/disease.actions';
import { getCurrentActiveSelector, getCurrentSelectorListMode, getSelectorsDetails } from '../../selectors/disease.selector';
import { DIAGNOSIS_DETAILS_PARAMETERS } from '../../reducers/diseasesReducer';
import {
    rebuildClearedFirstDiagnoseByPath, rebuildClearedSecondDiagnoseByPath, rebuildClearedThirdDiagnosisByPath
} from './helper';

function* watchLoadDiagnosesWORK(action) {
    try {
        const loaded_diseases = yield select(state => state.diseases && state.diseases.loaded_diseases);
        if (!Array.isArray(loaded_diseases) || !loaded_diseases.length) {
            const diseases = yield call(rest.getDiseases, action.payload)
            const payload  = diseases.map(d => ({...d, name: `${d.name}`.toUpperCase() }));
            yield put(getLoadDiseasesSuccess(payload));
        }
        const name = yield select(getCurrentActiveSelector);
        const mode = yield select(s => getCurrentSelectorListMode(s, name));
        yield put(setListModeByName({ name, mode }));
      } catch (e) {
        console.log(`watchLoadDiagnosesWORK -> failed`);
      }
}

function* clearDiagnoseWORK(action) {
    try {
        const { host, name } = action.payload || {};
        const detailsObject = yield select(getSelectorsDetails);
        if (!detailsObject || !detailsObject[`${host}`] || 
            !detailsObject[`${host}`][`${name}`] || 
            !detailsObject[`${host}`][`${name}`][DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE]
        ) {
            return;
        }
        let data = detailsObject[host];
        switch(name) {
            case DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE:
                data = rebuildClearedFirstDiagnoseByPath(detailsObject[host]);
                break;
            case DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE:
                data = rebuildClearedSecondDiagnoseByPath(detailsObject[host]);
                break;
            case DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE:
                data = rebuildClearedThirdDiagnosisByPath(detailsObject[host]);
                break;
            default: data = detailsObject[host];
        }
        yield put({   type: SISEASES_ACTIONS_MAP.RESET_DISEASES, payload: { host, data} });
    } catch(e) {
        console.log(`clearDiagnoseWORK => failed.`);
    }
}

function* watchLoadDiagnosesRequest() {
    yield takeLatest(SISEASES_ACTIONS_MAP.LOAD_DISEASES_REQUEST, watchLoadDiagnosesWORK);
}

function* watchClearDiagnoseRequest() {
    yield takeLatest(SISEASES_ACTIONS_MAP.CLEAR_DISEASE_REQUEST, clearDiagnoseWORK);
}
 
export const diseaseSagas = [
    fork(watchLoadDiagnosesRequest),
    fork(watchClearDiagnoseRequest),
];