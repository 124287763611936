
export const NOTIFICATION_ACTIONS = Object.freeze({
    triggerNotification: 'NOTIFICATION_TRIGGER_DIALOG_WINDOW',
    triggerNotificationSuccess: 'NOTIFICATION_TRIGGER_DIALOG_WINDOW_SUCCESS',
    addNotification: 'NOTIFICATION_ADD',
    addNotificationSuccess: 'NOTIFICATION_ADD_SUCCESS',
    initNotifications: 'INIT_NOTIFICATIONS',
    initNotificationsSuccess: 'INIT_NOTIFICATIONS_SUCCESS',
    blockHistory: 'BLOCK_HISTORY_TO_PUSH',
    unblockHistory: 'UNBLOCK_HISTORY_TO_PUSH',
    setBlockHistoryCallback: 'SET_BLOCK_HISTORY_CALLBACK',
    toastOpen: 'TOAST_OPEN',
    toastClose: 'TOAST_CLOSE',
    releaseTempBlockRequest: 'RELEASE_TEMPORARELY_MONITOR_BLOCK_REQUEST',
    releaseTempBlockON: 'RELEASE_TEMPORARELY_MONITOR_BLOCK_ON',
    releaseTempBlockOFF: 'RELEASE_TEMPORARELY_MONITOR_BLOCK_OFF',
    blockPhysicianByManager: 'SOCKET_ACTION_PHYSICIAN_BLOCKED_BY_MANAGER',
    blockPhysicianByManagerInformed: 'SOCKET_ACTION_PHYSICIAN_BLOCKED_BY_MANAGER_INFORMED',
});

export const SYSTEM_ALLOWED_NOTIFICATIONS = Object.freeze({
    LOGIN_NOTIFICATION: 'LOGIN_NOTIFICATION',
    LOGOUT_NOTIFICATION: 'LOGOUT_NOTIFICATION',
    ADD_DIAGNOSE_NOTIFICATION: 'ADD_DIAGNOSE_NOTIFICATION',
    ADD_DIAGNOSE_NOTIFY_NOTIFICATION: 'ADD_DIAGNOSE_NOTIFY_NOTIFICATION',
    CANCEL_DIAGNOSE_NOTIFICATION: 'CANCEL_DIAGNOSE_NOTIFICATION',
    NOTIFICATION_NOTIFY_PATIENT: 'NOTIFICATION_NOTIFY_PATIENT',
    CLEAR_HISTORY: 'CLEAR_HISTORY', 
    SET_HISTORY_CALLBACK: 'SET_HISTORY_CALLBACK_ACTION', 
});

export const blockPhysicianDialogOpen = () => {
    return {
        type: NOTIFICATION_ACTIONS.blockPhysicianByManager,
    }
}

export const blockPhysicianDialogClose = () => {
    return {
        type: NOTIFICATION_ACTIONS.blockPhysicianByManagerInformed,
    }
}

export const initNotifications = () => {
    return {
        type: NOTIFICATION_ACTIONS.initNotifications,
    }
} 

export const triggerNotification = () => {
    return {
        type: NOTIFICATION_ACTIONS.triggerNotification,
    }    
}

export const addNotificationAction = (payload) => {
    return {
        type: NOTIFICATION_ACTIONS.addNotification,
        payload: payload,
    }
}

export const popPopupMessage = ({message, title, redirectTo = null, historySuccessCallback = null, onClickBlockHistoryDialog = null}) => {
    return {
        type: NOTIFICATION_ACTIONS.blockHistory,
        payload: {
            message: message,
            title: title,
            redirectTo: redirectTo,
            historySuccessCallback: historySuccessCallback,
            onClickBlockHistoryDialog: onClickBlockHistoryDialog,
        }
    };
}

