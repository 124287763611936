import React, { memo } from 'react';
import LangMenuItem from './LangMenuItem';
import { useDispatch, useSelector } from 'react-redux';
import imgEnglish from '../../../components/sidebar/usaFlag.png';
import imgHebrew from '../../../components/sidebar/hebFlag.png';
import imgFrench from '../../../components/sidebar/fraFlag.png';
import imgRussian from '../../../components/sidebar/rusFlag.png';
import { IconButton } from '@material-ui/core';
import { getCurrentPhycisianEmail } from '../../../selectors/settings.selector';
import { getLanguage } from '../../../selectors/settings.selector';
import { useStyles } from './styles';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';


const StyledMenu = withStyles({
  paper: {
    width: '216px',
    height: 'auto',
    position: 'absolute',
    marginTop: '40px',
  },

})(Menu);


const langs = Object.freeze({
  en: {
    lng: 'en',
    dir: 'ltr',
    src: imgEnglish,
    alt: '_ENGLISH_',
    label: 'ENG'
  },
  he: {
    lng: 'he',
    dir: 'rtl',
    src: imgHebrew,
    alt: '_HEBREW_',
    label: 'HEB'
  },
  ru: {
    lng: 'ru',
    dir: 'ltr',
    src: imgRussian,
    alt: '_RUSSIAN_',
    label: 'RUS'
  },
  fr: {
    lng: 'fr',
    dir: 'ltr',
    src: imgFrench,
    alt: '_FRENCH_',
    label: 'FRA'
  }
});

export const LangButton = memo((props) => {
  const user = useSelector(getCurrentPhycisianEmail);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const s = useStyles();

  let l;
  if (!user){
    l = localStorage.getItem('lang');
  } else {
    l = useSelector(getLanguage);
    localStorage.setItem('lang', l);
  }


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setLang = (lng, dir) => {
    const bodyElem = document.getElementById('lang_direction');
    bodyElem.setAttribute('dir', dir);
    dispatch({
      type: 'SET_USER_LANG',
      payload: { user, language: lng }
    });
    localStorage.setItem('lang', lng);
  }

  const lang = langs[l];

  return lang && (
    <div className={s.menuDivContainer}>
      <IconButton
        color="inherit"
        // style={{padding: '5px'}}
        className={s.iconButton}
        onClick={
          handleMenu
        }
      >
        <img className="langFlag" src={lang.src} alt={lang.alt} width={30} />
      </IconButton>
      <StyledMenu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          onClose={handleClose}
          className={s.menuAbsolute}
        >
          {Object.values(langs).map((item, index) => {
            return (
              <div key={item.alt}>
                <LangMenuItem
                  classNameMenuItem={s.menuItemPadding}
                  // onClick={() => setLang(anotherLng, anotherDir)}
                  onClick={() => { setLang(item.lng, item.dir); handleClose()}}
                  imgSource={item.src}
                  alternative={item.alt}
                  currentLang={item.label}
                  classNameSpan={s.spanLangLabel}
                />
              </div>
            )
          })}
        </StyledMenu>
    </div>
  );
});