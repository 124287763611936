
export const SETTINGS_ACTION_MAP = Object.freeze({
    loadingGlobalOn: 'LOADING_GLOBAL_ON',
    loadingGlobalOff: 'LOADING_GLOBAL_OFF',
    socketConnectionRequest: 'SOCKET_CONNECTION',
    socketConnectionRequestSuccess: 'SOCKET_CONNECTION_SUCCESS',
    socketConnectionRequestFail: 'SOCKET_CONNECTION_FAIL',
    socketBlockedRequest: 'SOCKET_CONNECTION_BLOCKED',
    socketBlockedRequestSuccess: 'SOCKET_CONNECTION_BLOCKED_INIT',
    socketBlockedRequestRelease: 'SOCKET_CONNECTION_BLOCKED_RELEASE',
    setViewOnly: 'setViewOnly',
    setAiModel: 'setAiModel',
    redirect: 'redirect',
});

export const SETTINGS_LOADING_ACTIONS = Object.freeze({
    NEXT_CASE: 'NEXT_CASE',
    updatePageNumberWORK: 'updatePageNumberWORK',
    updatePageOffsetWORK: 'updatePageOffsetWORK',
    updatePageLimitSizeWORK: 'updatePageLimitSizeWORK',
    patientNotificationWORK: 'patientNotificationWORK',
    updateGlobalSearchStringWORK: 'updateGlobalSearchStringWORK',
    updatePatientsCaseIdWORK: 'updatePatientsCaseIdWORK',
    updatePatientsIdWORK: 'updatePatientsIdWORK',
    updateCreatedAtValueWORK: 'updateCreatedAtValueWORK',
    updateCreatedAtValueInDateRangeWORK: 'updateCreatedAtValueInDateRangeWORK',
    updateAgeValueWORK: 'updateAgeValueWORK',
    updateDiagnosedByValueWORK: 'updateDiagnosedByValueWORK',
    updateDiagnosedAtValueWORK: 'updateDiagnosedAtValueWORK',
    resetAllFilterWORK: 'resetAllFilterWORK',
    updateCreatedAtOrderWORK: 'updateCreatedAtOrderWORK',
    getPatientsWORK: 'getPatientsWORK',
    viewCase: 'viewCase',
});

export const redirectAction = (history, url ) => {
    return {
        type: SETTINGS_ACTION_MAP.redirect,
        payload: { history, url },
    }
}

export const socketConnectionSuccess = (id) => {
    return {
        type: SETTINGS_ACTION_MAP.socketConnectionRequest,
        payload: { id },
    }
}

export const socketBlock = (id, listBlockedConnections) => {
    return {
        type: SETTINGS_ACTION_MAP.socketBlockedRequest,
        payload: { id, listBlockedConnections },
    }
}
