import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';


const LoginHeader = ({ title }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography component="h1" variant="h5" style={{ color: '#858585', fontSize: '28px', lineHeight: '41,13px', marginBottom: '40px' }}>
                {t(`${title}`)}
            </Typography>
        </>
    );
};

export default LoginHeader;