export const envSettingsTypes = Object.freeze({
    IDP_SERVICE: 'IDP_SERVICE',
    AI_SERVICES: 'AI_SERVICES',
    IMAGES_SERVICE: 'IMAGES_SERVICE',
    ANALYTICS_URL: 'ANALYTICS_URL',
    TENANT: 'TENANT',
    IMAGE_SERVICE: 'IMAGE_SERVICE',
    VERSION: 'VERSION',
})

export const TENANTS = Object.freeze({
    MYSKIN: 'MYSKIN',
    MACCABI: 'MACCABI',
})

export const imagesServicesTypes = Object.freeze({
    AZURE: 'azure',
    GCP: 'gcp',
})