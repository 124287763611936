import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getToastMessage, getIsToast, getToastStatus } from '../../selectors/notification.selector';
import { getLanguage } from '../../selectors/settings.selector';
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { NOTIFICATION_ACTIONS } from '../../actions/notification.actions';

const ToastNotification = () => {

    const open = useSelector(getIsToast);
    const message = useSelector(getToastMessage);
    const status = useSelector(getToastStatus);
    const language = useSelector(getLanguage);
    
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch({ type: NOTIFICATION_ACTIONS.toastClose });
    }

    return (
        <React.Fragment>
            <div className="text-center">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: (language === 'he') ? 'left' :  'right',
                    }}
                    style={{ minWidth: '650px' }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    children={
                        <Alert 
                            severity="error" 
                            style={{ minWidth: '650px' }}
                            className="alerts-alternate mb-4"
                            color={status ? status : 'error'}
                        >
                            <div className="d-flex align-items-center align-content-start">
                                <span>
                                    { message }
                                </span>
                            </div>
                        </Alert>
                    }
                />
            </div>
        </React.Fragment>
    )

}

export default ToastNotification;
