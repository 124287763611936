
const initialState = {
  isOpen: false,
  message: '',
  variant: 'info'
};

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_SNACKBAR': {
      return {
        ...state,
        isOpen: true,
        message: action.message,
        variant: action.variant
      };
    }
    case 'CLOSE_SNACKBAR': {
      return {
        ...state,
        isOpen: false,
        message: ''
      };
    }
    default: {
      return state;
    }
  }
}

