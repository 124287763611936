import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'stretch',
    padding: '0 64px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
    height: '112px',
  },
}));