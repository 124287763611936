import axios from 'axios';
import { axiosInstance } from '../core/axios';
import { envSettingsTypes } from '../helpers/auth.helper';

//axios.defaults.baseURL = 'http://localhost:3434';

export const setAxiosCSRFToken = (token) => {
    axiosInstance.defaults.headers['CSRF-Token'] = token;
}

const DefaultEnvSettings = {
    [envSettingsTypes.IDP_SERVICE]: 'local',
    [envSettingsTypes.AI_SERVICES]: false
}
let envSettings = null;

export const IDPServiceTypes = {
    LOCAL: 'local',
    DEFAULT: ''
}
IDPServiceTypes.DEFAULT = IDPServiceTypes.LOCAL;
Object.freeze(IDPServiceTypes);

export async function refreshActiveSocket() {
    const res = await axiosInstance.patch(`/api/auth/block-refresh`, {});
    return res;
}

export async function session() {
    try {
        const res = await axiosInstance.get(`/api/auth/session`, {}, {});
        return res.data;
    } catch (err) {
        return null;
    }
}

export async function getEnvSettings() {
    if (envSettings)
        return envSettings;
    
    try {
        const res = await axiosInstance.get(`/api/auth/env-settings`);
        if(!res.data) {
            return envSettings = DefaultEnvSettings;
        }

        envSettings = res.data;
        return res.data;
    } catch (err) {
        console.error('ERROR: Get env settings failed => ', err)
        envSettings = DefaultEnvSettings;
        return envSettings;
    }
}

export async function getCSRFToken() {
    try {
      const res = await axiosInstance.get(`/api/auth/csrf-token`);
      return res.data;
    } catch(err) {
      console.log(err);
      return null;
    }
  }

export async function login(data) {
    try {
        const responseBasic = await axiosInstance.post(`/api/auth/login`, data);
        return responseBasic.data;
                ;
    } catch (err) {
        console.log('login err:', err)
        return null;
    }
}

export async function logout() {
    try {
        const responseBasic = await axiosInstance.post(`/api/auth/logout`);
        return responseBasic.data;
    } catch (err) {
        return null;
    }
}

export async function isAuth(email) {
    try {
        const responseBasic = await axiosInstance.post(`/api/auth/is-auth`, {email});
        return responseBasic.data;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function sendInvitation(email, admin) {
      const res = await axiosInstance.post(`/api/auth/invitation?email=${email}&superuser=${admin}`);
    return res.data
}

export async function createNewPhysicianPassword({validation_code, new_password}) {
    const res = await axiosInstance.post(`/api/auth/create-password`, {validation_code, new_password});
    return res.data;
}

export async function resetPhysicianPassword(email) {
    const res = await axiosInstance.patch(`/api/auth/forgot-password`, {email});
    return res.data;
}

export async function validateCode(email, code) {
    try {
        const res = await axiosInstance.post(`/api/auth/registration-validation`, {
            email, code
        });
        return res.data
    } catch (err) {
        // console.log(err);
        return null;
    }
}

export async function savePhysicianPassword(email, password) {
    try {
        const res = await axiosInstance.post(`/api/auth/registration-password`, {
            email, password
        });
        return res.data
    } catch (err) {
        // console.log(err);
        return null;
    }
}



