import { DIAGNOSE_BLOCK_NAMES } from '../../../actions/disease.actions';
import { DIAGNOSIS_DETAILS_PARAMETERS } from '../../../reducers/diseasesReducer';

export const rebuildClearedThirdDiagnosisByPath = (cdpObject = {}) => {
    try {
        return {
            ...cdpObject,
            [DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE]: null,
        }
    } catch(e) {
        console.log(`rebuildClearedThirdDiagnosisByPath => failed.`);
        return cdpObject;
    }
}

export const rebuildClearedSecondDiagnoseByPath = (cdpObject = {}) => {
    try {
        let updatedDiagnosesObject = {};
        if (cdpObject[DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE] && 
            cdpObject[DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE][DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE]
        ) {
            updatedDiagnosesObject = {
                ...cdpObject,
                [DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE]: {
                    ...cdpObject[DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE],
                    [DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE]: cdpObject[DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE][DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE],
                },
                [DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE]: null,
            }
        } else {
            updatedDiagnosesObject = {
                ...cdpObject,
                [DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE]: null,
            }
        }
        return updatedDiagnosesObject;
    } catch(e) {
        console.log(`rebuildClearedSecondDiagnoseByPath => failed.`);
        return cdpObject;
    }
}

export const rebuildClearedFirstDiagnoseByPath = (cdpObject = {}) => {
    try {
        let updatedDiagnosesObject = {};
        if (cdpObject[DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE] && 
            cdpObject[DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE][DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE]
        ) {
            updatedDiagnosesObject = {
                ...cdpObject,
                [DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE]: {
                    ...cdpObject[DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE],
                    [DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE]: cdpObject[DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE][DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE],
                },
                [DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE]: {
                    ...cdpObject[DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE],
                    [DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE]: cdpObject[DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE][DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE],
                },
                [DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE]: null,
            };
        } else 
        if (cdpObject[DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE] && 
            cdpObject[DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE][DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE]) {
            updatedDiagnosesObject = {
                ...cdpObject,
                [DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE]: {
                    ...cdpObject[DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE],
                    [DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE]: cdpObject[DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE][DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE],
                },
                [DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE]: null,
            }
        } else {
            updatedDiagnosesObject = {
                ...cdpObject,
                [DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE]: null,
            }
        }
        return updatedDiagnosesObject;
    } catch(e) {
        console.log(`rebuildFirstDiagnoseByPath => failed.`);
        return cdpObject;
    }
}