export const SMS_NOTIFICATION_ACTIONS = Object.freeze({
    smsNotificationStart: 'SMS_NOTIFICATION_START',
    smsNotificationRequest: 'SMS_NOTIFICATION_REQUEST',
    smsNotificationFaild: 'SMS_NOTIFICATION_FAILED',
    smsNotificationSuccess: 'SMS_NOTIFICATION_SUCCESS',
});

export const startSMSNotifications = () => {
    return {
        type: SMS_NOTIFICATION_ACTIONS.smsNotificationStart,
    }
}

export const initSMSNotifications = (payload) => {
    return {
        type: SMS_NOTIFICATION_ACTIONS.smsNotificationRequest,
        payload,
    }
}

export const successSMSNotifications = () => {
    return {
        type: SMS_NOTIFICATION_ACTIONS.smsNotificationSuccess,
        payload: true,
    }
}

export const failedSMSNotifications = (error) => {
    return {
        type: SMS_NOTIFICATION_ACTIONS.smsNotificationFaild,
        error,
    }
}