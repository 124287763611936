export const CASES_BATCH_ACTIONS_MAP = Object.freeze({
    subscribeForNACAnalyticRequest: 'SUBSCRIBE_FOR_NAC_ANALYTIC_REQUEST',
    subscribeForNACAnalyticSuccess: 'SUBSCRIBE_FOR_NAC_ANALYTIC_SUCCESS',
    subscribeForNACAnalyticStart: 'SUBSCRIBE_FOR_NAC_ANALYTIC_START',
    subscribeForNACAnalyticFail: 'SUBSCRIBE_FOR_NAC_ANALYTIC_FAIL',
    unsubscribeForNACAnalyticRequest: 'UNSUBSCRIBE_FOR_NAC_ANALYTIC_REQUEST',
    unsubscribeForNACAnalyticStart: 'UNSUBSCRIBE_FOR_NAC_ANALYTIC_START',
    unsubscribeForNACAnalyticSuccess: 'UNSUBSCRIBE_FOR_NAC_ANALYTIC_SUCCESS',
    unsubscribeForNACAnalyticFail: 'UNSUBSCRIBE_FOR_NAC_ANALYTIC_FAIL',
});

export const subscribeNACAnalyticRequest = (d) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.subscribeForNACAnalyticRequest,
        payload: {
            // email: d.email,
            signature: true,
        },
    }
}

export const subscribeNACAnalyticSuccess = (d) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.subscribeForNACAnalyticSuccess,
        payload: d,
    }
}

export const unsubscribeNACAnalyticRequest = (d) => {
    return {
        type: CASES_BATCH_ACTIONS_MAP.unsubscribeForNACAnalyticRequest,
        payload: {
            signature: false,
        },
    }
}





