import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { generateUrlParams } from "../../../sagas/helper";
import userProfile from "../../../assets/svg/userProfile.svg";
import { isValidatedCode } from "./../../../selectors/settings.selector";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { logout as logoutService } from "../../../services/auth.service";
import {
  removeCSRFTokenAction,
} from "../../../actions/auth.actions";
import {
  SYSTEM_ALLOWED_NOTIFICATIONS,
  NOTIFICATION_ACTIONS,
} from "../../../actions/notification.actions";
import { getCurrentUser } from "../../../selectors/admin.selector";
import { getCurrentPhycisianPermission } from "../../../selectors/settings.selector";
import { resetAdminSettings } from "../../../actions/admin.actions";
import { Divider, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  isHistoryBlockedSelector,
  getHistoryMessage,
} from "../../../selectors/notification.selector";
import { useStyles } from "./styles";

export const SettingsButton = memo((props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isValidCode = useSelector(isValidatedCode);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector(getCurrentUser);
  const permission = useSelector(getCurrentPhycisianPermission);
  const isHistoryBlocked = useSelector(isHistoryBlockedSelector);
  const historyMessage = useSelector(getHistoryMessage);
  const { t } = useTranslation();
  const s = useStyles();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const reload = () => {
    history.push(generateUrlParams(`/patients`));
  };

  const logout = () => {
    return logoutService().then((data) => {
      dispatch(removeCSRFTokenAction());
      dispatch({
        type: NOTIFICATION_ACTIONS.addNotification,
        payload: {
          action: SYSTEM_ALLOWED_NOTIFICATIONS.LOGOUT_NOTIFICATION,
          props: {
            permission: permission,
            user: user,
            date: new Date(),
          },
        },
      });
      dispatch({
        type: "SET_USER",
        payload: {
          user: "",
          vendor: "",
          permission: "",
          admin_settings: {},
        },
      });
      dispatch(resetAdminSettings());
      localStorage.removeItem("access_token");

      history.push(generateUrlParams("/login"));
    });
  };

  if (isValidCode) return null;

  return (
    <div className={s.menuDivContainer}>
      <IconButton
        aria-label="notification-bell"
        color="primary"
        className={s.iconButton}
        onClick={handleMenu}
      >
        <img src={userProfile} alt="userProfile" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        className={s.menuAbsolute}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            history.push(generateUrlParams("/user-management"));
          }}
          className={s.menuItemPadding}
        >
          {t("sidebar.personalDetails")}
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            if (isHistoryBlocked) {
              dispatch({
                type: NOTIFICATION_ACTIONS.blockHistory,
                payload: {
                  isHistoryBlocked: true,
                  message: historyMessage,
                  title: historyMessage,
                  redirectTo: "/login",
                  historySuccessCallback: async () => await logout(),
                },
              });
              reload();
            } else {
              logout();
            }
          }}
          className={s.menuItemPadding}
        >
          {t("sidebar.logout")}
        </MenuItem>
        <Divider variant="middle" />
      </Menu>
    </div>
  );
});
