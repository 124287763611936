import { DIAGNOSE_BLOCK_NAMES, SISEASES_ACTIONS_MAP } from '../actions/disease.actions';
import diseases from '../assets/diseases.json';

export const DIAGNOSIS_DETAILS_PARAMETERS = Object.freeze({
  MODE_KEY: 'mode',
  CURRENT_ACTIVE: 'current_active',
  DIAGNOSE: 'diagnose',
  COMMENT: 'comment',
  CHAR1: 'characteristic1',
  CHAR2: 'characteristic2',
})

const initialState = {
  diseases: Array.isArray(diseases) ? diseases.sort((a, b) => {
    if ((!a.name || a.name === '') || (!b.name || b.name === '')) {
      return 0
    } else if (a.name >= b.name) {
      return 1;
    } else if (b.name > a.name) {
      return -1;
    } else {
      return 0;
    }
  }) : [],
  loaded_diseases: [],
  selectorsDetails: {
    globalComment: { text: '', isSending: true },
    default: {
      [DIAGNOSIS_DETAILS_PARAMETERS.CURRENT_ACTIVE] : DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE,
    }
  },
};

const mergeDiseaseDetails = (state = { selectorsDetails: {} }, host, name, key, value, isInner) => {
  const isPath = !!state.selectorsDetails[host];
  const pathObj = isPath ? state.selectorsDetails[host] : {};
  if (!isInner) {
    return {
      ...state,
      selectorsDetails: {
        ...state.selectorsDetails,
        [host]: {
          ...pathObj,
          [key]: value,
        }
      }
    }
  }
  const isPathName = isPath && !!state.selectorsDetails[host][name];
  const pathNameObj = (isPath && isPathName) ? state.selectorsDetails[host][name] : {};
  if(key === DIAGNOSIS_DETAILS_PARAMETERS.MODE_KEY) {
    return {
      ...state,
      selectorsDetails: {
        ...state.selectorsDetails,
        [host]: {
          ...pathObj,
          [name] : {
            ...pathNameObj,
            [key]: value,
            value: '',
            [DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE]: null,
          }
        }
      }
    }  
  }
  return {
    ...state,
    selectorsDetails: {
      ...state.selectorsDetails,
      [host]: {
        ...pathObj,
        [name] : {
          ...pathNameObj,
          [key]: value,
        }
      }
    }
  }
}

export const mergeDiagnoseValue = (details = {}, host, name, value) => {
  try {
    if (!details || !details[host]) {
      return {
        ...details,
        [host]: { 
          [name]: { value } }
      }
    } else if (!details[host][name]) {
      return {
        ...details,
        [host]: {
          ...details[host],
          [name]: { 
            value,
          }
        }
      }
    } else {
      return {
        ...details,
        [host]: {
          ...details[host],
          [name]: { 
            ...details[host][name],
            value
          }
        }
      }
    }
  } catch (e) {
    return details;
  }
}

export default function diseasesReducer(state = initialState, action) {
  switch (action.type) {
    case SISEASES_ACTIONS_MAP.SET_GLOBAL_COMMENT:
      return {
        ...state,
        selectorsDetails: {
          ...state.selectorsDetails,
          globalComment: action.payload,
        }
      }
    case SISEASES_ACTIONS_MAP.SET_INNER_CHAR2:
      return mergeDiseaseDetails(
        state,
        action.payload.host,
        action.payload.name,
        DIAGNOSIS_DETAILS_PARAMETERS.CHAR2,
        action.payload.value,
        true,
      );
    case SISEASES_ACTIONS_MAP.SET_INNER_CHAR1:
      return mergeDiseaseDetails(
        state,
        action.payload.host,
        action.payload.name,
        DIAGNOSIS_DETAILS_PARAMETERS.CHAR1,
        action.payload.value,
        true,
      );
    case SISEASES_ACTIONS_MAP.SET_INNER_COMMENT:
      return mergeDiseaseDetails(
        state,
        action.payload.host,
        action.payload.name,
        DIAGNOSIS_DETAILS_PARAMETERS.COMMENT,
        action.payload.value,
        true,
      );
    case SISEASES_ACTIONS_MAP.INIT_DIAGNOSIS:
      return {
        ...state,
        selectorsDetails: {
          ...initialState.selectorsDetails
        },
      }
    case SISEASES_ACTIONS_MAP.SET_LIST_MODE_BY_NAME:
      return mergeDiseaseDetails(
        state,
        action.payload.host,
        action.payload.name,
        DIAGNOSIS_DETAILS_PARAMETERS.MODE_KEY,
        action.payload.value,
        true,
      );
    case SISEASES_ACTIONS_MAP.SET_RUNTIME_VALUE_DIAGNOSE:
      return {
        ...state,
        selectorsDetails: {
          ...state.selectorsDetails,
          ...mergeDiagnoseValue(state.selectorsDetails, action.payload.host, action.payload.name, action.payload.value)
        }
      }
    case SISEASES_ACTIONS_MAP.RESET_DISEASES:
      return {
        ...state,
        selectorsDetails: {
          ...state.selectorsDetails,
          [action.payload.host]: {
            ...state.selectorsDetails[action.payload.host],
            ...action.payload.data,
          },
        }
      }
    case SISEASES_ACTIONS_MAP.SET_CURRENT_DISEASE:
      return mergeDiseaseDetails(
        state,
        action.payload.host,
        action.payload.name,
        DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE,
        action.payload.diagnose,
        true,
      );
    case SISEASES_ACTIONS_MAP.SET_CURRENT_DISEASE_FOCUSED:
      return mergeDiseaseDetails(
        state,
        action.payload.host,
        null,
        DIAGNOSIS_DETAILS_PARAMETERS.CURRENT_ACTIVE,
        action.payload.name,
        false,
      );
    case SISEASES_ACTIONS_MAP.LOAD_DISEASES_REQUEST_SUCESS: {
      return {
        ...state,
        loaded_diseases: action.payload.diseases
      };
    }
    default: {
      return state;
    }
  }
}
