import { all, fork } from 'redux-saga/effects';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history';
import settingReducer  from './reducers/settingReducer'
import snackbarReducer  from './reducers/snackbarReducer'
import diseasesReducer from './reducers/diseasesReducer'
import templatesReducer from './reducers/templatesReducer'
import patientHistoryReducer from './reducers/patientHistoryReducer'
import createSagaMiddleware from 'redux-saga'
import {physicianFlow} from './sagas/flow.saga'
import { generalSagas } from './sagas/sagas'
import patientDetailsReducer from './reducers/patientDetailsReducer';
import notificationReducer from './reducers/notificationReducer';
import analyticReducer from './reducers/analyticReducer';
import { notificationSagas } from './sagas/notifications';
import patientListReducer from './reducers/patientListReducer';
import patientListSagas from './sagas/patientList';
import casesBatchSagas from './sagas/cases-batch-process';
import settingsSagas from './sagas/settings';
import analyticSagas from './sagas/analytics';
import adminReducer from './reducers/adminReducer';
import adminSagas from './sagas/admin';
import authReducer from './reducers/authReducer';
import auditAnnotationsReducer from './reducers/auditAnnotationsReducer';
import authSagas from './sagas/auth/auth.saga';
import { diseaseSagas } from './sagas/diseases';
import { patientDetailsSagas } from './sagas/patientDetails';
import auditAnnotationsSagas from './sagas/auditAnnotations';


const sagaMiddleware = createSagaMiddleware()

export const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: settingReducer,
  snackbar: snackbarReducer,
  diseases: diseasesReducer,
  templates: templatesReducer,
  patientHistory: patientHistoryReducer,
  patientDetails:  patientDetailsReducer,
  notifications: notificationReducer,
  patientList: patientListReducer,
  analytic: analyticReducer,
  auth: authReducer,
  admin: adminReducer,
  auditAnnotations: auditAnnotationsReducer,
})

const initialState = {}
export const history = createBrowserHistory()

const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
const composeEnhancers = composeWithDevTools || compose

const routerMW = routerMiddleware(history)

export const store = createStore(
  rootReducer(history),
  initialState,
  composeEnhancers(applyMiddleware(routerMW, sagaMiddleware)),
)

function* allSaga() {
  yield all([
    ...adminSagas,
    ...auditAnnotationsSagas,
    ...authSagas,
    ...notificationSagas,
    ...generalSagas,
    ...patientListSagas,
    ...casesBatchSagas,
    ...settingsSagas,
    ...analyticSagas,
    ...diseaseSagas,
    ...patientDetailsSagas,
    fork(physicianFlow),
  ]);
}
sagaMiddleware.run(allSaga);

export default store
