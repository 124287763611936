import { CASES_BATCH_ACTIONS_MAP } from "../actions/analytic.actions";

const initialState = {
    awaitingTotal: 0,
    oldestCaseTime: '',
    privateAwaitingCount: 0,
    oldestPrivate: '',
}

export default function analyticReducer(state = initialState, action) {
    switch (action.type) {
        case CASES_BATCH_ACTIONS_MAP.subscribeForNACAnalyticStart:
            return state;
        case CASES_BATCH_ACTIONS_MAP.subscribeForNACAnalyticSuccess:
            return {
                awaitingTotal: action.payload.total,
                oldestCaseTime: action.payload.oldestTime,
                privateAwaitingCount: action.payload.privateCount,
                oldestPrivate: action.payload.oldestPrivate,
                otherCount: action.payload.otherCount,
                oldestOther: action.payload.oldestOther,
            } 
        default: return state;
    }
}