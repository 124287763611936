const initialState = {
  diseasesLoading: false,
  diseases: [],
  diseasesError: null,
};
export const PATIENT_HISTORY_ACTION_MAP = {
  GET_PATIENT_HISTORY_DISEASES_START: 'GET_PATIENT_HISTORY_DISEASES_START',
  GET_PATIENT_HISTORY_DISEASES_SUCCESS: 'GET_PATIENT_HISTORY_DISEASES_SUCCESS',
  GET_PATIENT_HISTORY_DISEASES_FAIL: 'GET_PATIENT_HISTORY_DISEASES_FAIL',
}

export default function templatesReducer(state = initialState, action) {
  switch (action.type) {
    case PATIENT_HISTORY_ACTION_MAP.GET_PATIENT_HISTORY_DISEASES_START: {
      return {
        ...state,
        diseasesLoading: true,
        diseases: initialState.diseases,
        diseasesError: initialState.error,
      };
    }
    case PATIENT_HISTORY_ACTION_MAP.GET_PATIENT_HISTORY_DISEASES_SUCCESS: {
      return {
        ...state,
        diseasesLoading: false,
        diseases: action.diseases,
        diseasesError: initialState.diseasesError,
      };
    }
    case PATIENT_HISTORY_ACTION_MAP.GET_PATIENT_HISTORY_DISEASES_FAIL: {
      return {
        ...state,
        diseasesLoading: false,
        diseases: initialState.diseases,
        diseasesError: action.error.message,
      };
    }
    default: {
      return state;
    }
  }
}