import { fork, put, takeLatest, select, delay } from 'redux-saga/effects';
import { NOTIFICATION_ACTIONS } from '../../actions/notification.actions';
import { getNotifications, getIsNotificationWindow } from '../../selectors/notification.selector';
import * as moment from 'moment';
import _ from 'lodash';
import { getCurrentPhycisianEmail } from '../../selectors/settings.selector';
import { getAwaitingCasesList } from '../../selectors/patient.details.selector';
import { casesArrayToString } from '../helper';

function* addNotificationWORK(action) {
    try {
        const notifications = yield select(getNotifications);
        const user = yield select(getCurrentPhycisianEmail);
        const newNotificationsArray = Array.isArray(notifications) ?  [...notifications] : [];
        newNotificationsArray.unshift({ action: action.payload.action, props: {...action.payload.props, viewed: false } });
        yield localStorage.setItem(`NOTIFICATIONS-${user}`, JSON.stringify(newNotificationsArray));
        const awaitingCases = yield select(getAwaitingCasesList);
        newNotificationsArray[0].props.caseId = casesArrayToString(awaitingCases);

        yield put({
            type: NOTIFICATION_ACTIONS.addNotificationSuccess,
            payload: { notifications:  newNotificationsArray}}
        );
    } catch (e) {
        console.log(`addNotificationWORK failed.`);
    }
}

function* initNotificationsWork(action) {
    try {
        const notifications = yield select(getNotifications);
        if (!Array.isArray(notifications) || !notifications.length ) {
            const user = yield select(getCurrentPhycisianEmail);
            const storedNotifications = yield localStorage.getItem(`NOTIFICATIONS-${user}`);
            const parsedNotifications = !!storedNotifications ?  JSON.parse(storedNotifications) : [];
            const today = moment();
            const weekBefore = moment().subtract(7,'d');
            const filteredArr = _.filter(parsedNotifications, (each) => moment(each.props.date).isBetween(weekBefore, today));
            yield put({
                type: NOTIFICATION_ACTIONS.initNotificationsSuccess,
                payload: { notifications:  filteredArr}}
            );
        }
    } catch (e) {
        console.log(`addNotificationWORK failed.`);
    }
}

function* triggerNotificationFlagWORK(action) {
    try {
        const isWindowopen = yield select(getIsNotificationWindow);
        if (!isWindowopen) {
            const notifications = yield select(getNotifications);
            const releasedNotifications = notifications.map( n => ({...n, viewed: true}));
            const awaitingCases = yield select(getAwaitingCasesList);
            if(releasedNotifications.length) {
                releasedNotifications[0].props.caseId = casesArrayToString(awaitingCases);
            }
            
            yield put({
                type: NOTIFICATION_ACTIONS.addNotificationSuccess,
                payload: { notifications:  releasedNotifications}}
            );
            const user = yield select(getCurrentPhycisianEmail);
            yield localStorage.setItem(`NOTIFICATIONS-${user}`, JSON.stringify(releasedNotifications));
        }
        yield put({ type: NOTIFICATION_ACTIONS.triggerNotificationSuccess, payload: action.payload});
    } catch (e) {
        console.log(`triggerNotificationFlagWORK => failed to init notifications.`);
    }
}

function* tempReleasePageChangeWORK() {
    try {
        yield put({ type: NOTIFICATION_ACTIONS.releaseTempBlockON });
        yield delay(1200);
        yield put({ type: NOTIFICATION_ACTIONS.releaseTempBlockOFF });
    } catch(e) {

    }
}

function* watchAddNotification() {
    yield takeLatest(NOTIFICATION_ACTIONS.addNotification, addNotificationWORK);
}

function* watchTriggerNotificationFlag() {
    yield takeLatest(NOTIFICATION_ACTIONS.triggerNotification, triggerNotificationFlagWORK);
}

function* watchInitNotifications() {
    yield takeLatest(NOTIFICATION_ACTIONS.initNotifications, initNotificationsWork);
}

function* watchTempReleasePageChange() {
    yield takeLatest(NOTIFICATION_ACTIONS.releaseTempBlockRequest, tempReleasePageChangeWORK)
}

export const notificationSagas = [
    fork(watchAddNotification),
    fork(watchInitNotifications),
    fork(watchTempReleasePageChange),
    fork(watchTriggerNotificationFlag),
];
