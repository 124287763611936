import { fork, put, takeLatest } from 'redux-saga/effects';
import { SETTINGS_ACTION_MAP } from '../../actions/settings.actions';
import { generateUrlParams } from '../../sagas/helper';


function* socketConnectionWORK(actions) {
    try {
        const { id } = actions.payload || {};
        if (id) {
            yield put({ type: SETTINGS_ACTION_MAP.socketConnectionRequestSuccess, payload: { id } });
        } else {
            yield put({ type: SETTINGS_ACTION_MAP.socketConnectionRequestFail });
        }
    } catch (e) {
        // error handler
    }
}

function socketBlockedWORK(action) {
    try {
        const { id, listBlockedConnections } = action.payload || {};
        if (Array.isArray(listBlockedConnections) && listBlockedConnections.length) {
            const foundIndex = listBlockedConnections.findIndex(c => c === id);
            if (foundIndex !== -1) {
                window.location.href = generateUrlParams('/blocked');
            }
        }
    } catch(e) {
        console.log(`socketBlockedWORK -> is failed.`);
    }
}

function redirectWORK(action) {
    action.payload.history.push(generateUrlParams(action.payload.url))
}

function* watchRedirect() {
    yield takeLatest(SETTINGS_ACTION_MAP.redirect, redirectWORK);
}

function* watchSocketConnection() {
    yield takeLatest(SETTINGS_ACTION_MAP.socketConnectionRequest, socketConnectionWORK);
}

function* watchBlockedSocketRequest() {
    yield takeLatest(SETTINGS_ACTION_MAP.socketBlockedRequest, socketBlockedWORK);
}

const settingsSagas = [
    fork(watchSocketConnection),
    fork(watchBlockedSocketRequest),
    fork(watchRedirect),
];

export default settingsSagas;

