import { DIAGNOSE_BLOCK_NAMES } from '../actions/disease.actions';
import { DIAGNOSIS_DETAILS_PARAMETERS } from '../reducers/diseasesReducer';

export const defaultDiagnose = { text: '', code: '', disease_id: '' };

const isPath = (state, host) => {
    if (!state.diseases || 
        !state.diseases.selectorsDetails || 
        !state.diseases.selectorsDetails[host]
    ) {
        return false;
    }
    return true;
}

export const getSelectorsDetails = (state) => state.diseases ?  state.diseases.selectorsDetails : {};
export const getShortListDiagnosis = (state) => state.diseases ?  state.diseases.diseases : null;
export const getFullListDiagnosis = (state) => state.diseases ?  state.diseases.loaded_diseases : null;
export const getCurrentSelectorListMode = (state, name, host = `${window.location.pathname}`) => {
    if (!state.diseases) {
        return false;
    }
    if (!name) {
        return false;
    }
    const details = state.diseases.selectorsDetails;
    if (!details || !details[host] || !details[host][name]) {
        return false;
    }
    return details[host][name].mode;
}

export const getCurrentActiveSelector = (state, host = `${window.location.pathname}`) => {
    if (!isPath(state, host)) {
        return DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE;
    }
    const currentActive = state.diseases.selectorsDetails[host][DIAGNOSIS_DETAILS_PARAMETERS.CURRENT_ACTIVE];
    return currentActive || state.diseases.selectorsDetails.default[DIAGNOSIS_DETAILS_PARAMETERS.CURRENT_ACTIVE];
}

export const isSiblingDiagnose = (state, host = `${window.location.pathname}`, name) => {
    switch (name) {
        case DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE: 
            if (!!isPath(state, host)) {
                const detail1 = state.diseases.selectorsDetails[host][DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE];
                return !!detail1 ? detail1[DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE] : false;
            } else {
                return false;
            }
        case DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE:
            if (!!isPath(state, host)) {
                const detail2 = state.diseases.selectorsDetails[host][DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE];
                return !!detail2 ? detail2[DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE] : false;
            } else {
                return false;
            }
        default: return true;
    }
}

export const getGlogalComment = (state) => {
    return (state.diseases && state.diseases.selectorsDetails 
        && state.diseases.selectorsDetails.globalComment) ?
         state.diseases.selectorsDetails.globalComment : { text: '', isSending: false };
}

export const getRuntimeDiadnoseValue = (state, host = `${window.location.pathname}`, name) => {
    try {
        const obj = (state.diseases.selectorsDetails[host] && state.diseases.selectorsDetails[host][name]) || {};
        return obj.value || '';
    } catch (e) {
        return '';
    }
}

export const getInnterChr2 = (state, host = `${window.location.pathname}`, name) => {
    if (!isPath(state, host) || 
        !state.diseases.selectorsDetails[host][name]
    ) {
        return '';
    }
    return state.diseases.selectorsDetails[host][name][DIAGNOSIS_DETAILS_PARAMETERS.CHAR2] || '';
}

export const getInnterChr1 = (state, host = `${window.location.pathname}`, name) => {
    if (!isPath(state, host) || 
        !state.diseases.selectorsDetails[host][name]
    ) {
        return '';
    }
    return state.diseases.selectorsDetails[host][name][DIAGNOSIS_DETAILS_PARAMETERS.CHAR1] || '';
}

export const getInnterComment = (state, host = `${window.location.pathname}`, name) => {
    if (!isPath(state, host) || 
        !state.diseases.selectorsDetails[host][name]
    ) {
        return '';
    }
    return state.diseases.selectorsDetails[host][name][DIAGNOSIS_DETAILS_PARAMETERS.COMMENT] || '';
}

export const getDiagnose = (state, host = `${window.location.pathname}`, name) => {
    if (!isPath(state, host) || 
        !state.diseases.selectorsDetails[host][name]
    ) {
        return {...defaultDiagnose};
    }
    return state.diseases.selectorsDetails[host][name][DIAGNOSIS_DETAILS_PARAMETERS.DIAGNOSE] || {...defaultDiagnose};
}